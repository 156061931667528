import React, { useEffect } from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import { backBtnArrow } from "../../icons";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import Input from "../../component/Common/Input";
import Button from "../../component/Common/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserList,
  getUserListForDropDown,
} from "../../store/slice/userSlice";
import { useForm } from "../../hooks/useForm";
import { createCoinHistory } from "../../store/slice/coinSlice";

const CreateCoinHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state?.user?.userList);

  const userDropdownList = useSelector(
    (state) => state?.user?.dropDownUserList
  );

  const userOptions = userDropdownList.map((item) => ({
    value: item.id,
    label: item.name,
    // label: `${item.name} ${` ${item.email}`}`,
  }));

  const initialFValues = {
    coins: "",
    userId: "",
  };

  const validate = (fieldValues = values) => {
    const temp = { ...errors };
    if ("userId" in fieldValues) {
      temp.userId = fieldValues.userId ? "" : "Please select User";
    }
    if ("coins" in fieldValues) {
      temp.coins = fieldValues.coins ? "" : "Please enter No of coins";
    }
    setErrors({ ...temp });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  useEffect(() => {
    dispatch(getUserList({ start: 0, limits: 1000000, add_coin: true }));
    dispatch(getUserListForDropDown({ dropDown: true, limits: 200000 }));
  }, []);

  //USE FORM HOOK
  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  //CREATE COIN HISTORY HANDLER
  const createCoinHistoryHandler = () => {
    if (validate()) {
      const data = {
        coins: values.coins,
        user_id: values.userId,
      };

      dispatch(
        createCoinHistory({
          data,
          navigate,
          toast: "Coin History created successfully",
        })
      );
    }
  };

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading="CREATE COIN HISTORY" />
      <>
        <div className="project_edit_main_link">
          <div className="comman_center_btn_container">
            <button className="go_back_btn" onClick={() => navigate(-1)}>
              {backBtnArrow} Back
            </button>
          </div>
        </div>

        <div className="create_from_row">
          <div className="common_filter_input_box first_filter_container">
            <div className="common_select_box">
              <h4 className="project_text_area_label required">Select User </h4>
              <Form.Select
                onChange={(e) => handleInputChange(e, "userId")}
                id="userId"
                name="userId"
                className="select_form"
                defaultValue={values.userId ? values.userId : "DEFAULT"}
              >
                <option value={"DEFAULT"} disabled>
                  Select User
                </option>
                {userOptions.map((item, i) => (
                  <option value={item.value} key={i}>
                    {item.label}
                  </option>
                ))}
              </Form.Select>
              {errors.userId && (
                <span className="err_text">{errors.userId}</span>
              )}
            </div>
          </div>
          <div className="common_filter_input_box first_filter_container">
            <div className="common_select_box">
              <h4 className="project_text_area_label required">Enter Coins</h4>
              <Input
                // className="project_edit_feature_input"
                className="select_form"
                errorClassName="err_text"
                type="text"
                placeholder="Enter no of coins..."
                label=""
                id="coins"
                name="coins"
                value={values.coins}
                onChange={handleInputChange}
                error={errors.coins || ""}
              />
            </div>
          </div>
        </div>

        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={createCoinHistoryHandler}
          text="Create"
        />
      </>
    </div>
  );
};

export default CreateCoinHistory;
