import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

export const getLevelList = createAsyncThunk("getLevelList", async (data) => {
  try {
    const response = await Axios.get(
      `/level-reward?dropdown=${data.dropdown || ""}&start=${
        data.start || 0
      }&limits=${data.limits || 10}`,
      authHeaders()
    );
    return response.data.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
});

export const getLevelListForDropDown = createAsyncThunk(
  "getLevelListForDropDown",
  async (data) => {
    try {
      const response = await Axios.get(
        `/level-reward?start=${data.start || 0}&limits=${
          data.limits || 10
        }&dropdown=${data.dropdown}`,
        authHeaders()
      );
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

export const createLevel = createAsyncThunk("createLevel ", async (data) => {
  try {
    const response = await Axios.post(
      `/level-reward`,
      data.data,
      authHeaders()
    );
    if (response.status === 201) {
      toast.success(data.toast, { autoClose: 3000 });
      data.navigate(`${routes.level}/page/1`);
    }
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
});

export const updateLevel = createAsyncThunk("updateLevel", async (data) => {
  try {
    const response = await Axios.put(
      `/level-reward/${data.id}`,
      data.data,
      authHeaders()
    );
    if (response.status === 201 && !data.cb) {
      toast.success(data.toast, { autoClose: 3000 });
      data.navigate(`${routes.level}/page/1`);
      return response.data;
    }
    if (data.cb) {
      return data.cb(null, response.data);
    }
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
});

const levelRewardSlice = createSlice({
  name: "level",
  initialState: {
    levelList: [],
    levelDropdownList: [],
    addLevel: "",
    updateLevel: "",
    totalCount: 0,
    loader: false,
    error: "",
  },
  reducers: {},
  //GET DAILY REWARD LIST
  extraReducers: (builder) => {
    builder.addCase(getLevelList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getLevelList.fulfilled, (state, action) => {
      state.loader = false;
      state.levelList = action.payload.rows;
      state.totalCount = action.payload.count;
    });
    builder.addCase(getLevelList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //GET DAILY REWARD LIST FOR DROPDOWN
    builder.addCase(getLevelListForDropDown.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getLevelListForDropDown.fulfilled, (state, action) => {
      state.loader = false;
      state.levelDropdownList = action.payload.rows;
    });
    builder.addCase(getLevelListForDropDown.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //CREATE DAILY REWARD
    builder.addCase(createLevel.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createLevel.fulfilled, (state, action) => {
      state.loader = false;
      state.addLevel = action.payload;
    });
    builder.addCase(createLevel.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //UPDATE DAILY REWARD
    builder.addCase(updateLevel.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateLevel.fulfilled, (state, action) => {
      state.loader = false;
      state.updateLevel = action.payload;
    });
    builder.addCase(updateLevel.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default levelRewardSlice.reducer;
