import React, { useEffect } from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import {
  getDashboardCount,
  getTopTenTopicList,
} from "../../store/slice/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../component/Common/Loader";
import {
  complaintIcon,
  dashboardIcon,
  dashboardUser,
  questionMark,
  quizIcon,
  topicIcon,
} from "../../icons";
import { routes } from "../../constants";
// import { tableHeadItemsForTopTenTopicList } from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
// import winnerBGImg from "../../images/winnerBGImg.gif";
// import winnerOneImg from "../../images/WinnerOneImg.svg";
// import winnerTwoImg from "../../images/WinnerTwoImg.svg";
// import winnerThreeImg from "../../images/WinnerThreeImg.svg";

const Dashboard = () => {
  const dispatch = useDispatch();

  const {
    topicCount,
    QuizQuestionsCount,
    userCount,
    quizCount,
    SupportRequestCount,
  } = useSelector((state) => state?.dashboard?.dashboardCount);

  const isLoading = useSelector((state) => state?.dashboard?.loader);

  const topTenTopic = useSelector(
    (state) => state?.dashboard?.topTenTopicList?.rows
  );

  useEffect(() => {
    dispatch(getDashboardCount({}));
    // dispatch(getTopTenTopicList({ popular: true }));
    dispatch(getTopTenTopicList({}));
  }, []);

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading={"DASHBOARD"} />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="dashboard_body">
            <div className="dashboard_top_column_row">
              <Link
                to={`${routes.user}/page/1`}
                style={{ textDecoration: "none" }}
              >
                <div className="dashboard_top_column">
                  <div className="dashboard_top_column_main_icon">
                    {dashboardUser}
                  </div>
                  <div className="dashboard_top_column_left_content">
                    <h2 className="dashboard_top_column_left_count_text">
                      {userCount}
                    </h2>
                    <p className="dashboard_top_column_left_peragraph_text">
                      Users
                    </p>
                  </div>
                </div>
              </Link>
              <Link
                to={`${routes.topic}/page/1`}
                style={{ textDecoration: "none" }}
              >
                <div className="dashboard_top_column">
                  <div className="dashboard_top_column_main_icon">
                    {topicIcon}
                  </div>
                  <div className="dashboard_top_column_left_content">
                    <h2 className="dashboard_top_column_left_count_text">
                      {topicCount}
                    </h2>
                    <p className="dashboard_top_column_left_peragraph_text">
                      Topics
                    </p>
                  </div>
                </div>
              </Link>
              <Link
                to={`${routes.question}/page/1`}
                style={{ textDecoration: "none" }}
              >
                <div className="dashboard_top_column">
                  <div className="dashboard_top_column_main_icon">
                    {questionMark}
                  </div>
                  <div className="dashboard_top_column_left_content">
                    <h2 className="dashboard_top_column_left_count_text">
                      {/* {questionCount} */}
                      {QuizQuestionsCount}
                    </h2>
                    <p className="dashboard_top_column_left_peragraph_text">
                      Questions
                    </p>
                  </div>
                </div>
              </Link>
              <Link
                to={`${routes.quiz}/page/1`}
                style={{ textDecoration: "none" }}
              >
                <div className="dashboard_top_column">
                  <div className="dashboard_top_column_main_icon">
                    {quizIcon}
                  </div>
                  <div className="dashboard_top_column_left_content">
                    <h2 className="dashboard_top_column_left_count_text">
                      {quizCount}
                    </h2>
                    <p className="dashboard_top_column_left_peragraph_text">
                      Games
                    </p>
                  </div>
                </div>
              </Link>
              <Link
                to={`${routes.service}/page/1`}
                style={{ textDecoration: "none" }}
              >
                <div className="dashboard_top_column">
                  <div className="dashboard_top_column_main_icon">
                    {complaintIcon}
                  </div>
                  <div className="dashboard_top_column_left_content">
                    <h2 className="dashboard_top_column_left_count_text">
                      {/* {serviceCount} */}
                      {SupportRequestCount}
                    </h2>
                    <p className="dashboard_top_column_left_peragraph_text">
                      Service
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="modal-content">
            <div className="quiz-winner-modal-body">
              {/* <img
                src={winnerBGImg}
                alt="winner background"
                className="quiz-winner-modal-bg-img"
              /> */}
              <p
                style={{
                  fontSize: "30px",
                  fontWeight: "700",
                  color: "#13426c",
                  marginBottom: "10px",
                  // animation: "mymove 0.5s infinite alternate-reverse";
                  textAlign: "center",
                  lineHeight: "1.1",
                  textDecoration: "underline",
                }}
              >
                Top 10 Most Played Quiz Topics
              </p>
              <div className="quiz-winner-modal-content">
                <div className="quiz-winner-modal-top3-winner-row">
                  <div className="quiz-winner-modal-top3-winner-col">
                    <p className="quiz-winner-modal-user-name">
                      {/* {topTenTopic ? topTenTopic[0].name : "-"} */}
                      {topTenTopic
                        ? topTenTopic[0] && topTenTopic[0].name
                          ? topTenTopic[0].name
                          : ""
                        : ""}
                    </p>
                    <div className="quiz-winner-modal-user-column">
                      <img
                        src={
                          topTenTopic
                            ? topTenTopic[0] &&
                              process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                topTenTopic[0].topic_image
                            : "-"
                        }
                        alt="winner img"
                        className="quiz-winner-modal-user-column-img"
                      />
                      <h3 className="quiz-winner-modal-user-column-score">
                        {/* {topTenTopic ? topTenTopic[0].quiz_count : "-"} */}
                        {topTenTopic
                          ? topTenTopic[0] && topTenTopic[0].quiz_count
                            ? topTenTopic[0].quiz_count
                            : ""
                          : ""}
                      </h3>
                    </div>
                  </div>
                  {/*  */}
                  <div className="quiz-winner-modal-top3-winner-col">
                    <p className="quiz-winner-modal-user-name">
                      {/* {topTenTopic ? topTenTopic[1].name : "-"} */}
                      {topTenTopic
                        ? topTenTopic[1] && topTenTopic[1].name
                          ? topTenTopic[1].name
                          : ""
                        : ""}
                    </p>
                    <div className="quiz-winner-modal-user-column">
                      <img
                        src={
                          topTenTopic
                            ? topTenTopic[1] &&
                              process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                topTenTopic[1].topic_image
                            : "-"
                        }
                        alt="winner img"
                        className="quiz-winner-modal-user-column-img"
                      />
                      <h3 className="quiz-winner-modal-user-column-score">
                        {/* {topTenTopic ? topTenTopic[1].quiz_count : "-"} */}
                        {topTenTopic
                          ? topTenTopic[1] && topTenTopic[1].quiz_count
                            ? topTenTopic[1].quiz_count
                            : ""
                          : ""}
                      </h3>
                    </div>
                  </div>
                  {/*  */}
                  <div className="quiz-winner-modal-top3-winner-col">
                    <p className="quiz-winner-modal-user-name">
                      {/* {topTenTopic ? topTenTopic[2].name : "-"} */}
                      {topTenTopic
                        ? topTenTopic[2] && topTenTopic[2].name
                          ? topTenTopic[2].name
                          : ""
                        : ""}
                    </p>
                    <div className="quiz-winner-modal-user-column">
                      <img
                        src={
                          topTenTopic
                            ? topTenTopic[2] &&
                              process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                topTenTopic[2].topic_image
                            : "-"
                        }
                        alt="winner img"
                        className="quiz-winner-modal-user-column-img"
                      />
                      <h3 className="quiz-winner-modal-user-column-score">
                        {/* {topTenTopic ? topTenTopic[2].quiz_count : "-"} */}
                        {topTenTopic
                          ? topTenTopic[2] && topTenTopic[2].quiz_count
                            ? topTenTopic[2].quiz_count
                            : ""
                          : ""}
                      </h3>
                    </div>
                  </div>
                  {/*  */}
                  <div className="quiz-winner-modal-top3-winner-col">
                    <p className="quiz-winner-modal-user-name">
                      {/* {topTenTopic ? topTenTopic[3].name : "-"} */}
                      {topTenTopic
                        ? topTenTopic[3] && topTenTopic[3].name
                          ? topTenTopic[3].name
                          : ""
                        : ""}
                    </p>
                    <div className="quiz-winner-modal-user-column">
                      <img
                        src={
                          topTenTopic
                            ? topTenTopic[3] &&
                              process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                topTenTopic[3].topic_image
                            : "-"
                        }
                        alt="winner img"
                        className="quiz-winner-modal-user-column-img"
                      />
                      <h3 className="quiz-winner-modal-user-column-score">
                        {/* {topTenTopic ? topTenTopic[3].quiz_count : "-"} */}
                        {topTenTopic
                          ? topTenTopic[3] && topTenTopic[3].quiz_count
                            ? topTenTopic[3].quiz_count
                            : ""
                          : ""}
                      </h3>
                    </div>
                  </div>
                  {/*  */}
                  <div className="quiz-winner-modal-top3-winner-col">
                    <p className="quiz-winner-modal-user-name">
                      {/* {topTenTopic ? topTenTopic[4].name : "-"} */}
                      {topTenTopic
                        ? topTenTopic[4] && topTenTopic[4].name
                          ? topTenTopic[4].name
                          : ""
                        : ""}
                    </p>
                    <div className="quiz-winner-modal-user-column">
                      <img
                        src={
                          topTenTopic
                            ? topTenTopic[4] &&
                              process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                topTenTopic[4].topic_image
                            : "-"
                        }
                        alt="winner img"
                        className="quiz-winner-modal-user-column-img"
                      />
                      <h3 className="quiz-winner-modal-user-column-score">
                        {/* {topTenTopic ? topTenTopic[4].quiz_count : "-"} */}
                        {topTenTopic
                          ? topTenTopic[4] && topTenTopic[4].quiz_count
                            ? topTenTopic[4].quiz_count
                            : ""
                          : ""}
                      </h3>
                    </div>
                  </div>
                  {/*  */}
                  <div className="quiz-winner-modal-top3-winner-col">
                    <p className="quiz-winner-modal-user-name">
                      {/* {topTenTopic ? topTenTopic[5].name : "-"} */}
                      {topTenTopic
                        ? topTenTopic[5] && topTenTopic[5].name
                          ? topTenTopic[5].name
                          : ""
                        : ""}
                    </p>
                    <div className="quiz-winner-modal-user-column">
                      <img
                        src={
                          topTenTopic
                            ? topTenTopic[5] &&
                              process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                topTenTopic[5].topic_image
                            : "-"
                        }
                        alt="winner img"
                        className="quiz-winner-modal-user-column-img"
                      />
                      <h3 className="quiz-winner-modal-user-column-score">
                        {/* {topTenTopic ? topTenTopic[5].quiz_count : "-"} */}
                        {topTenTopic
                          ? topTenTopic[5] && topTenTopic[5].quiz_count
                            ? topTenTopic[5].quiz_count
                            : ""
                          : ""}
                      </h3>
                    </div>
                  </div>
                  {/*  */}
                  <div className="quiz-winner-modal-top3-winner-col">
                    <p className="quiz-winner-modal-user-name">
                      {/* {topTenTopic ? topTenTopic[6].name : "-"} */}
                      {topTenTopic
                        ? topTenTopic[6] && topTenTopic[6].name
                          ? topTenTopic[6].name
                          : ""
                        : ""}
                    </p>
                    <div className="quiz-winner-modal-user-column">
                      <img
                        src={
                          topTenTopic
                            ? topTenTopic[6] &&
                              process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                topTenTopic[6].topic_image
                            : "-"
                        }
                        alt="winner img"
                        className="quiz-winner-modal-user-column-img"
                      />
                      <h3 className="quiz-winner-modal-user-column-score">
                        {/* {topTenTopic ? topTenTopic[6].quiz_count : "-"} */}
                        {topTenTopic
                          ? topTenTopic[6] && topTenTopic[6].quiz_count
                            ? topTenTopic[6].quiz_count
                            : ""
                          : ""}
                      </h3>
                    </div>
                  </div>
                  {/*  */}
                  <div className="quiz-winner-modal-top3-winner-col">
                    <p className="quiz-winner-modal-user-name">
                      {/* {topTenTopic ? topTenTopic[7].name : "-"} */}
                      {topTenTopic
                        ? topTenTopic[7] && topTenTopic[7].name
                          ? topTenTopic[7].name
                          : ""
                        : ""}
                    </p>
                    <div className="quiz-winner-modal-user-column">
                      <img
                        src={
                          topTenTopic
                            ? topTenTopic[7] &&
                              process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                topTenTopic[7].topic_image
                            : "-"
                        }
                        alt="winner img"
                        className="quiz-winner-modal-user-column-img"
                      />
                      <h3 className="quiz-winner-modal-user-column-score">
                        {/* {topTenTopic ? topTenTopic[7].quiz_count : "-"} */}
                        {topTenTopic
                          ? topTenTopic[7] && topTenTopic[7].quiz_count
                            ? topTenTopic[7].quiz_count
                            : ""
                          : ""}
                      </h3>
                    </div>
                  </div>
                  {/*  */}
                  <div className="quiz-winner-modal-top3-winner-col">
                    <p className="quiz-winner-modal-user-name">
                      {/* {topTenTopic ? topTenTopic[8].name : "-"} */}
                      {topTenTopic
                        ? topTenTopic[8] && topTenTopic[8].name
                          ? topTenTopic[8].name
                          : ""
                        : ""}
                    </p>
                    <div className="quiz-winner-modal-user-column">
                      <img
                        src={
                          topTenTopic
                            ? topTenTopic[8] &&
                              process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                topTenTopic[8].topic_image
                            : "-"
                        }
                        alt="winner img"
                        className="quiz-winner-modal-user-column-img"
                      />
                      <h3 className="quiz-winner-modal-user-column-score">
                        {/* {topTenTopic ? topTenTopic[8].quiz_count : "-"} */}
                        {topTenTopic
                          ? topTenTopic[8] && topTenTopic[8].quiz_count
                            ? topTenTopic[8].quiz_count
                            : ""
                          : ""}
                      </h3>
                    </div>
                  </div>
                  {/*  */}
                  <div className="quiz-winner-modal-top3-winner-col">
                    <p className="quiz-winner-modal-user-name">
                      {/* {topTenTopic ? topTenTopic[9].name : "-"} */}
                      {topTenTopic
                        ? topTenTopic[9] && topTenTopic[9].name
                          ? topTenTopic[9].name
                          : ""
                        : ""}
                    </p>
                    <div className="quiz-winner-modal-user-column">
                      <img
                        src={
                          topTenTopic
                            ? topTenTopic[9] &&
                              process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                topTenTopic[9].topic_image
                            : "-"
                        }
                        alt="winner img"
                        className="quiz-winner-modal-user-column-img"
                      />
                      <h3 className="quiz-winner-modal-user-column-score">
                        {/* {topTenTopic ? topTenTopic[9].quiz_count : "-"} */}
                        {topTenTopic
                          ? topTenTopic[9] && topTenTopic[9].quiz_count
                            ? topTenTopic[9].quiz_count
                            : ""
                          : ""}
                      </h3>
                    </div>
                  </div>
                  {/*  */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
