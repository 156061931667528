import React, { useEffect } from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import { useDispatch, useSelector } from "react-redux";
import { mobileAppStatus } from "../../store/slice/userSlice";
import { tableHeadItemsForAppStatus } from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";
import { Link } from "react-router-dom";
import { editIcon } from "../../icons";
import { routes } from "../../constants";

const MobileConfig = () => {
  const dispatch = useDispatch();
  const mobileAppData = useSelector((state) => state?.user?.appStatus);

  useEffect(() => {
    dispatch(mobileAppStatus());
  }, []);

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading="MOBILE CONFIGURATION" />
      <div className="custom_data_table_content">
        {!mobileAppData ? (
          <p className="no_data_found">No data Found</p>
        ) : (
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                {tableHeadItemsForAppStatus.map((app, i) => {
                  return (
                    <th
                      className="custom_data_table_heading text-center"
                      key={i}
                    >
                      {app.name}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              <tr className="custom_data_table_row">
                <td className="custom_data_table_item table_item">
                  Mobile App Version Control
                </td>
                <td className="custom_data_table_item table_item">
                  {mobileAppData.created_at
                    ? moment
                        .utc(mobileAppData.created_at, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </td>
                <td className="custom_data_table_item table_item">
                  {mobileAppData.updated_at
                    ? moment
                        .utc(mobileAppData.updated_at, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </td>
                <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                  <div className="custom_data_table_view_edit_btn_item_row">
                    <Link
                      className="custom_data_table_view_edit_item_btn "
                      aria-label="view"
                      state={{ appUpdateData: mobileAppData }}
                      to={routes.editMobile}
                    >
                      {editIcon}
                    </Link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default MobileConfig;
