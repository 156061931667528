import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios, { authHeaders } from "../../helpers/axios";
import { routes } from "../../constants";

export const getQuizSettingList = createAsyncThunk(
  "getQuizSettingList",
  async () => {
    try {
      const response = await Axios.get(`/setting`, authHeaders());
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const updateQuizSettingList = createAsyncThunk(
  "updateQuizSettingList",
  async (data) => {
    try {
      const response = await Axios.put(
        `/setting/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 201 && !data.cb) {
        toast.success(data.toast, { autoClose: 3000 });
        data.navigate(`${routes.setting}/page/1`);
        return response.data;
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

const quizSettingSlice = createSlice({
  name: "quizSetting",
  initialState: {
    quizSettingList: [],
    updateQuizSetting: "",
    totalCount: 0,
    loader: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    //GET QUIZ SETTINGS LIST
    builder.addCase(getQuizSettingList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getQuizSettingList.fulfilled, (state, action) => {
      state.loader = false;
      state.quizSettingList = action.payload;
      state.totalCount = action.payload.count;
    });
    builder.addCase(getQuizSettingList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //UPDATE QUIZ SETTING LIST
    builder.addCase(updateQuizSettingList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateQuizSettingList.fulfilled, (state, action) => {
      state.loader = false;
      state.updateQuizSetting = action.payload;
    });
    builder.addCase(updateQuizSettingList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default quizSettingSlice.reducer;
