import React, { useEffect } from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import { getQuizSettingList } from "../../store/slice/quizSettingSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../component/Common/Loader";
import Pagination from "../../component/Pagination/Pagination";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";
import { Link } from "react-router-dom";
import { coinIcon, editIcon, eyeIcon } from "../../icons";
import { tableHeadItemsForQuizSettings } from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import { routes } from "../../constants";

const QuizSetting = () => {
  const dispatch = useDispatch();

  const list = useSelector((state) => state?.quizSetting?.quizSettingList);
  const isLoading = useSelector((state) => state?.quizSetting?.loader);
  // const totalCount = useSelector((state) => state?.quizSetting?.totalCount);

  useEffect(() => {
    dispatch(getQuizSettingList({}));
  }, []);

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading="QUIZ SETTING" />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {/* <div className="creat_edit_project_btn_row_flex">
            <div></div>

            <div className="common_all_filter_row">
              <div className="common_filter_input_box first_filter_container">
                <div className="common_select_box">
                  <Form.Select
                    id="level"
                    name="level"
                    className="select_form"
                    defaultValue={"DEFAULT"}
                    onChange={(e) => setFilterData(e.target.value)}
                  >
                    <option value="DEFAULT" disabled>
                      Filter by Level
                    </option>
                    <option value="">All</option>
                    <option value="EASY">Easy</option>
                    <option value="MEDIUM">Medium</option>
                    <option value="HARD">Hard</option>
                  </Form.Select>
                </div>
              </div>
            </div>
          </div> */}

          <div className="custom_data_table_content">
            {list?.length === 0 || !list ? (
              <p className="no_data_found">No data Found</p>
            ) : (
              <table className="custom_data_table">
                <thead className="custom_data_table_head">
                  <tr>
                    {tableHeadItemsForQuizSettings.map((ques, i) => {
                      return (
                        <th
                          className="custom_data_table_heading text-center"
                          key={i}
                        >
                          {ques.name}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="custom_data_table_body">
                  {list?.map((quiz, i) => {
                    return (
                      <tr className="custom_data_table_row" key={i}>
                        <td className="custom_data_table_item table_item">
                          {quiz.quiz_count ? (
                            <span className="question_table_level_text_correct_answer resolved">
                              {quiz.quiz_count}
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>
                        {/* <td className="custom_data_table_item table_item">
                          {quiz.coins ? (
                            <>
                              {quiz.coins} {coinIcon}
                            </>
                          ) : (
                            "-"
                          )}
                        </td> */}
                        <td className="custom_data_table_item table_item">
                          {quiz.created_at
                            ? moment
                                .utc(quiz.created_at, "YYYYMMDD HH:mm:ss")
                                .clone()
                                .tz(defaultTimeZone)
                                .format("DD/MM/YYYY, hh:mm A")
                            : "-"}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {quiz.updated_at
                            ? moment
                                .utc(quiz.updated_at, "YYYYMMDD HH:mm:ss")
                                .clone()
                                .tz(defaultTimeZone)
                                .format("DD/MM/YYYY, hh:mm A")
                            : "-"}
                        </td>
                        <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                          <div className="custom_data_table_view_edit_btn_item_row">
                            {/* <button
                              className="custom_data_table_view_edit_item_btn "
                              aria-label="view"
                              // state={{ quizId: quiz.id }}
                            >
                              {eyeIcon}
                            </button> */}
                            <Link
                              className="custom_data_table_view_edit_item_btn "
                              aria-label="view"
                              to={`${routes.setting}/edit/${quiz.id}`}
                              state={{ quizSettingDetails: quiz }}
                            >
                              {editIcon}
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>

          {/* <Pagination totalRecords={totalCount} /> */}
        </>
      )}
    </div>
  );
};

export default QuizSetting;
