import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

export const getRewardList = createAsyncThunk("getRewardList", async (data) => {
  try {
    const response = await Axios.get(
      `/reward?start=${data.start || 0}&limits=${data.limits || 10}`,
      authHeaders()
    );
    return response.data.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
});

export const createReward = createAsyncThunk("createReward", async (data) => {
  try {
    const response = await Axios.post(`/reward`, data.data, authHeaders());
    if (response.status === 201) {
      toast.success(data.toast, { autoClose: 3000 });
      data.navigate(`${routes.reward}/page/1`);
    }
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
});

export const updateReward = createAsyncThunk("updateReward", async (data) => {
  try {
    const response = await Axios.put(
      `/reward/${data.id}`,
      data.data,
      authHeaders()
    );
    if (response.status === 201 && !data.cb) {
      toast.success(data.toast, { autoClose: 3000 });
      data.navigate(`${routes.reward}/page/1`);
      return response.data;
    }
    if (data.cb) {
      return data.cb(null, response.data);
    }
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
});

const dailyRewardSlice = createSlice({
  name: "reward",
  initialState: {
    rewardList: [],
    addReward: "",
    updateRewards: "",
    totalCount: 0,
    loader: false,
    error: "",
  },
  reducers: {},
  //GET DAILY REWARD LIST
  extraReducers: (builder) => {
    builder.addCase(getRewardList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getRewardList.fulfilled, (state, action) => {
      state.loader = false;
      state.rewardList = action.payload.rows;
      state.totalCount = action.payload.count;
    });
    builder.addCase(getRewardList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //CREATE DAILY REWARD
    builder.addCase(createReward.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createReward.fulfilled, (state, action) => {
      state.loader = false;
      state.addReward = action.payload;
    });
    builder.addCase(createReward.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //UPDATE DAILY REWARD
    builder.addCase(updateReward.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateReward.fulfilled, (state, action) => {
      state.loader = false;
      state.updateRewards = action.payload;
    });
    builder.addCase(updateReward.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default dailyRewardSlice.reducer;
