import React from "react";

const DeleteQuestionInputFields = ({
  closeDeleteModal,
  setShowDeleteModal,
  inputFields,
  setInputFields,
  passQuestionIndexToRemove,
}) => {
  //REMOVE FILLED QUESTION INPUT FIELD
  const removeInputQuestionField = () => {
    const list = [...inputFields];
    const questionToRemove = list[passQuestionIndexToRemove];
    const indexToRemove = list.findIndex(
      (question) => question === questionToRemove
    );
    if (indexToRemove !== -1) {
      list.splice(indexToRemove, 1);
      setInputFields(list);
    }
    setShowDeleteModal(false);
  };

  return (
    <div className="modal_container">
      <div className="modal_heading_2">
        <h1 className="modal_main_heading_with_desc">Are you sure?</h1>
        <p>You want to remove this filled Input fields!"</p>
      </div>
      <div className="modal_btn_row">
        <button
          onClick={() => closeDeleteModal()}
          className="modal_btn modal_cancel_btn"
        >
          Cancel
        </button>
        <button
          className="modal_btn modal_delete_btn"
          onClick={removeInputQuestionField}
        >
          Remove
        </button>
      </div>
    </div>
  );
};

export default DeleteQuestionInputFields;
