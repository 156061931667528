import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCoinsHistoryList,
  getCoinsHistoryListByUserId,
} from "../../store/slice/coinSlice";
import { useLocation, useNavigate } from "react-router-dom";
import CommonHeading from "../../component/Common/CommonHeading";
import { backBtnArrow, coinIcon } from "../../icons";
import { tableHeadItemsForUserCoinData } from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";

const ViewUserCoinDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const userId = location?.state?.userId;

  const coinsList = useSelector(
    (state) => state?.coins?.coinListByUserId?.rows
  );

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  useEffect(() => {
    dispatch(getCoinsHistoryListByUserId({ user_id: userId }));
  }, []);

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="COINS" />
        <div className="creat_edit_project_btn_row_flex">
          <div>
            <button
              className="go_back_btn table_back_btn"
              onClick={() => navigate(-1)}
            >
              {backBtnArrow} Back
            </button>
          </div>

          {/* <div className="common_all_filter_row">
          <div className="common_filter_input_box first_filter_container">
            <div className="common_select_box">
              <Form.Select
                id="level"
                name="level"
                className="select_form"
                defaultValue={"DEFAULT"}
                onChange={(e) => handleInputChange(e, "level")}
              >
                <option value="DEFAULT" disabled>
                  Filter by Level
                </option>
                <option value="">All</option>
                <option value="EASY">Easy</option>
                <option value="MEDIUM">Medium</option>
                <option value="HARD">Hard</option>
              </Form.Select>
            </div>
          </div>
        </div> */}
        </div>

        <div className="custom_data_table_content">
          {coinsList?.length === 0 || !coinsList ? (
            <p className="no_data_found">No data Found</p>
          ) : (
            <table className="custom_data_table">
              <thead className="custom_data_table_head">
                <tr>
                  {tableHeadItemsForUserCoinData?.map((coin, i) => {
                    return (
                      <th
                        className="custom_data_table_heading text-center"
                        key={i}
                      >
                        {coin?.name}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="custom_data_table_body">
                {coinsList?.map((coin, i) => {
                  return (
                    <tr className="custom_data_table_row" key={i}>
                      <td className="custom_data_table_item table_item">
                        <img
                          src={
                            coin.user && coin.user.profile_pic !== null
                              ? process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                coin.user.profile_pic
                              : coin.user.avtar !== null
                              ? process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                coin.user.avtar.image
                              : process.env.REACT_APP_DEFAULT_IMG
                          }
                          alt="dailyRewardImage"
                          className={`custom_data_table_img cursor_pointer_image_view`}
                          onClick={() => {
                            setImgZoom(true);
                            setImgSrc(
                              coin.user && coin.user.profile_pic !== null
                                ? process.env.REACT_APP_FILE_BASE_URL +
                                    "/" +
                                    coin.user.profile_pic
                                : coin.user.avtar !== null
                                ? process.env.REACT_APP_FILE_BASE_URL +
                                  "/" +
                                  coin.user.avtar.image
                                : process.env.REACT_APP_DEFAULT_IMG
                            );
                          }}
                        />
                      </td>
                      <td className="custom_data_table_item table_item">
                        {coin.user ? coin?.user?.name : "-"}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {coin?.coins ? (
                          <>
                            {coin?.coins} {coinIcon}
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="custom_data_table_item table_item">
                        <span
                          className={`question_table_level_text ${
                            coin.coin_status === "CREDITED"
                              ? "credited"
                              : "debited"
                          }`}
                        >
                          {coin?.coin_status ? coin?.coin_status : "-"}
                        </span>
                      </td>
                      <td className="custom_data_table_item table_item">
                        {coin?.lifeline === true
                          ? "✅"
                          : coin?.lifeline === false
                          ? "❌"
                          : "-"}
                      </td>
                      <td className="custom_data_table_item table_item">
                        <span
                          className={`question_table_level_text ${
                            coin.watching_ad === true ? "credited" : "debited"
                          }`}
                        >
                          {coin.watching_ad === true
                            ? "Yes"
                            : coin.watching_ad === false
                            ? "No"
                            : "-"}
                        </span>
                      </td>
                      <td className="custom_data_table_item table_item">
                        {coin.created_at
                          ? moment
                              .utc(coin.created_at, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div
        onClick={() => setImgZoom(false)}
        className={`oc-table-img-modal-bg ${
          imgZoom && "oc-table-img-modal-open"
        }`}
      ></div>
      <div
        className={`oc-table-img-modal ${imgZoom && "oc-table-img-modal-open"}`}
      >
        <img src={imgSrc} alt="profile" />
      </div>
    </>
  );
};

export default ViewUserCoinDetails;
