import React from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import { backBtnArrow } from "../../icons";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../../component/Common/Input";
import { useForm } from "../../hooks/useForm";
import Button from "../../component/Common/Button";
import { updateQuizSettingList } from "../../store/slice/quizSettingSlice";
import { useDispatch, useSelector } from "react-redux";
import TimeField from "react-simple-timefield";
import { Form } from "react-bootstrap";

const EditQuizSetting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const editQuizSettings = location?.state?.quizSettingDetails;
  const isLoading = useSelector((state) => state?.quizSetting?.loader);

  //ALL STATES VALUE
  const initialFValues = {
    quiz_count: editQuizSettings ? editQuizSettings?.quiz_count : "",
    // coins: editQuizSettings ? editQuizSettings?.coins : "",
    startupCoins: editQuizSettings ? editQuizSettings?.startupCoins : "",
    points_per_ans: editQuizSettings ? editQuizSettings?.points_per_ans : "",
    coins_per_ans: editQuizSettings ? editQuizSettings?.coins_per_ans : "",
    bonus_coins_per_ans: editQuizSettings
      ? editQuizSettings?.bonus_coins_per_ans
      : "",
    entryFeePerGame: editQuizSettings ? editQuizSettings?.entryFeePerGame : "",
    bonus_points_per_ans: editQuizSettings
      ? editQuizSettings?.bonus_points_per_ans
      : "",
    coins_by_watching_ad: editQuizSettings
      ? editQuizSettings?.coins_by_watching_ad
      : "",
    bonus_eligible_period_per_ans: editQuizSettings
      ? editQuizSettings?.bonus_eligible_period_per_ans
      : "",
    questionsPerQuiz: editQuizSettings
      ? editQuizSettings?.questionsPerQuiz
      : "",
    quiz_type: editQuizSettings ? editQuizSettings?.quiz_type : "",
    lifeline1Price: editQuizSettings ? editQuizSettings?.lifeline1Price : "",
    lifeline2Price: editQuizSettings ? editQuizSettings?.lifeline2Price : "",
    lifeline3Price: editQuizSettings ? editQuizSettings?.lifeline3Price : "",
    lifeline4Price: editQuizSettings ? editQuizSettings?.lifeline4Price : "",
  };

  //ERROR HANDLING VALIDATION
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    // if ("coins" in fieldValues) {
    //   temp.coins = fieldValues.coins ? "" : "Please Enter no of coins!";
    // }
    if ("quiz_count" in fieldValues) {
      temp.quiz_count = fieldValues.quiz_count
        ? ""
        : "Please Enter Quiz count!";
    }
    if ("quiz_type" in fieldValues) {
      temp.quiz_type = fieldValues.quiz_type ? "" : "Please Select Quiz type!";
    }
    if ("startupCoins" in fieldValues) {
      temp.startupCoins = fieldValues.startupCoins
        ? ""
        : "Please Enter start up Coins";
    }
    if ("points_per_ans" in fieldValues) {
      temp.points_per_ans = fieldValues.points_per_ans
        ? ""
        : "Please Enter points for per Answer";
    }
    if ("coins_per_ans" in fieldValues) {
      temp.coins_per_ans = fieldValues.coins_per_ans
        ? ""
        : "Please Enter coins for per Answer";
    }
    if ("bonus_coins_per_ans" in fieldValues) {
      temp.bonus_coins_per_ans = fieldValues.bonus_coins_per_ans
        ? ""
        : "Please Enter Bonus Coins per Answer";
    }
    if ("entryFeePerGame" in fieldValues) {
      temp.entryFeePerGame = fieldValues.entryFeePerGame
        ? ""
        : "Entry Fee of per game required";
    }
    if ("coins_by_watching_ad" in fieldValues) {
      temp.coins_by_watching_ad = fieldValues.coins_by_watching_ad
        ? ""
        : "Enter coins you will get after watching an Ads";
    }

    if ("bonus_eligible_period_per_ans" in fieldValues) {
      if (
        !fieldValues.bonus_eligible_period_per_ans ||
        fieldValues.bonus_eligible_period_per_ans === "00:00:00"
      ) {
        temp.bonus_eligible_period_per_ans =
          "Please enter a valid time for per Answer";
      } else {
        temp.bonus_eligible_period_per_ans = "";
      }
    }

    if ("bonus_points_per_ans" in fieldValues) {
      temp.bonus_points_per_ans = fieldValues.bonus_points_per_ans
        ? ""
        : "Enter Bonus points for per Answer";
    }
    if ("questionsPerQuiz" in fieldValues) {
      temp.questionsPerQuiz = fieldValues.questionsPerQuiz
        ? ""
        : "Enter Number of questions for per quiz";
    }
    if ("lifeline1Price" in fieldValues) {
      temp.lifeline1Price = fieldValues.lifeline1Price
        ? ""
        : "Enter Price of Lifeline 1!";
    }
    if ("lifeline2Price" in fieldValues) {
      temp.lifeline2Price = fieldValues.lifeline2Price
        ? ""
        : "Enter Price of Lifeline 2!";
    }
    if ("lifeline3Price" in fieldValues) {
      temp.lifeline3Price = fieldValues.lifeline3Price
        ? ""
        : "Enter Price of Lifeline 3!";
    }
    if ("lifeline4Price" in fieldValues) {
      temp.lifeline4Price = fieldValues.lifeline4Price
        ? ""
        : "Enter Price of Lifeline 4!";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  //UPDATE QUIZ SETTING MAIN HANDLER
  const updateQuizSettingHandler = () => {
    if (validate()) {
      const data = {
        quiz_count: values.quiz_count,
        // coins: values.coins,
        startupCoins: values.startupCoins,
        points_per_ans: values.points_per_ans,
        coins_per_ans: values.coins_per_ans,
        bonus_coins_per_ans: values.bonus_coins_per_ans,
        entryFeePerGame: values.entryFeePerGame,
        bonus_eligible_period_per_ans: values.bonus_eligible_period_per_ans,
        quiz_type: values.quiz_type,
        bonus_points_per_ans: values.bonus_points_per_ans,
        coins_by_watching_ad: values.coins_by_watching_ad,
        questionsPerQuiz: values.questionsPerQuiz,
        lifeline1Price: values.lifeline1Price,
        lifeline2Price: values.lifeline2Price,
        lifeline3Price: values.lifeline3Price,
        lifeline4Price: values.lifeline4Price,
      };

      dispatch(
        updateQuizSettingList({
          data,
          id: editQuizSettings.id,
          navigate,
          toast: "Quiz setting updated successfully",
        })
      );
    }
  };

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading="EDIT QUIZ SETTING" />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="project_edit_main_link">
            <div className="comman_center_btn_container">
              <button className="go_back_btn" onClick={() => navigate(-1)}>
                {backBtnArrow} Back
              </button>
            </div>
          </div>

          <div className="create_from_row">
            <Input
              className="project_edit_feature_input"
              labelClassName="required"
              errorClassName="err_text"
              type="text"
              placeholder="Enter quiz count..."
              label="Total Quiz count"
              // label="Free quiz per day"
              id="quiz_count"
              name="quiz_count"
              value={values.quiz_count}
              onChange={handleInputChange}
              error={errors?.quiz_count || ""}
            />
            <Input
              className="project_edit_feature_input"
              labelClassName="required"
              errorClassName="err_text"
              type="text"
              placeholder="Entry fee of per game..."
              label="Entry fees of game"
              id="entryFeePerGame"
              name="entryFeePerGame"
              value={values.entryFeePerGame}
              onChange={handleInputChange}
              error={errors?.entryFeePerGame || ""}
            />
            {/* <Input
              className="project_edit_feature_input"
              labelClassName="required"
              errorClassName="err_text"
              type="text"
              placeholder="Enter no of coins..."
              label="Total Coins"
              id="coins"
              name="coins"
              value={values.coins}
              onChange={handleInputChange}
              error={errors?.coins || ""}
            /> */}
            <Input
              className="project_edit_feature_input"
              labelClassName="required"
              errorClassName="err_text"
              type="text"
              placeholder="Enter start up coins..."
              label="Start Up Coins"
              id="startupCoins"
              name="startupCoins"
              value={values.startupCoins}
              onChange={handleInputChange}
              error={errors?.startupCoins || ""}
            />

            <Input
              className="project_edit_feature_input"
              labelClassName="required"
              errorClassName="err_text"
              type="text"
              placeholder="Enter coins you will get After Watching Ads..."
              label="Coins (After Watching Ads)"
              id="coins_by_watching_ad"
              name="coins_by_watching_ad"
              value={values.coins_by_watching_ad}
              onChange={handleInputChange}
              error={errors?.coins_by_watching_ad || ""}
            />

            <Input
              className="project_edit_feature_input"
              labelClassName="required"
              errorClassName="err_text"
              type="text"
              placeholder="Enter Points for per Answer..."
              label="Points per Answer"
              id="points_per_ans"
              name="points_per_ans"
              value={values.points_per_ans}
              onChange={handleInputChange}
              error={errors?.points_per_ans || ""}
            />

            <Input
              className="project_edit_feature_input"
              labelClassName="required"
              errorClassName="err_text"
              type="text"
              placeholder="Enter Coins for per Answer..."
              label="Coins per Answer"
              id="coins_per_ans"
              name="coins_per_ans"
              value={values.coins_per_ans}
              onChange={handleInputChange}
              error={errors?.coins_per_ans || ""}
            />

            {/* <Input
              className="project_edit_feature_input"
              labelClassName="required"
              errorClassName="err_text"
              type="text"
              placeholder="Enter Bonus points for per Answer..."
              label="Bonus points per Answer"
              id="bonus_points_per_ans"
              name="bonus_points_per_ans"
              value={values.bonus_points_per_ans}
              onChange={handleInputChange}
              error={errors?.bonus_points_per_ans || ""}
            /> */}

            {/* <Input
              className="project_edit_feature_input"
              labelClassName="required"
              errorClassName="err_text"
              type="text"
              placeholder="Enter Bonus Coins per Answer..."
              label="Time period (Enter time in this format)"
              id="bonus_eligible_period_per_ans"
              name="bonus_eligible_period_per_ans"
              value={values.bonus_eligible_period_per_ans}
              onChange={handleInputChange}
              error={errors?.bonus_eligible_period_per_ans || ""}
            /> */}

            <div className="project_edit_feature_input">
              <label
                htmlFor="bonus_eligible_period_per_ans"
                className="required undefined"
              >
                Time period for per question
              </label>
              <TimeField
                id="bonus_eligible_period_per_ans"
                name="bonus_eligible_period_per_ans"
                value={values.bonus_eligible_period_per_ans}
                onChange={handleInputChange}
                input={<input type="text" />}
                colon=":"
                showSeconds
              />
              {errors.bonus_eligible_period_per_ans && (
                <span className="err_text">
                  {errors.bonus_eligible_period_per_ans}
                </span>
              )}
            </div>

            <Input
              className="project_edit_feature_input"
              labelClassName="required"
              errorClassName="err_text"
              type="text"
              placeholder="Enter Number of questions for per quiz..."
              label="Questions for per quiz"
              id="questionsPerQuiz"
              name="questionsPerQuiz"
              value={values.questionsPerQuiz}
              onChange={handleInputChange}
              error={errors?.questionsPerQuiz || ""}
            />

            <Input
              className="project_edit_feature_input"
              labelClassName="required"
              errorClassName="err_text"
              type="text"
              placeholder="Enter Bonus Coins for per Answer..."
              label="Bonus Coins per Answer"
              id="bonus_coins_per_ans"
              name="bonus_coins_per_ans"
              value={values.bonus_coins_per_ans}
              onChange={handleInputChange}
              error={errors?.bonus_coins_per_ans || ""}
            />
            <div className="common_filter_input_box first_filter_container select_filter_container">
              <div className="common_select_box">
                <h4 className="project_text_area_label required">
                  Question Type
                </h4>
                <Form.Select
                  onChange={(e) => handleInputChange(e, "quiz_type")}
                  id="quiz_type"
                  name="quiz_type"
                  className="select_form"
                  defaultValue={values.quiz_type ? values.quiz_type : "Default"}
                >
                  <option value="DEFAULT" disabled>
                    Select question Type
                  </option>
                  <option value="FREE">Free</option>
                  <option value="PAID">Paid</option>
                </Form.Select>
                {errors.quiz_type && (
                  <span className="err_text">{errors.quiz_type}</span>
                )}
              </div>
            </div>

            <Input
              className="project_edit_feature_input col_span_2"
              labelClassName="required"
              errorClassName="err_text"
              type="text"
              placeholder="Enter bonus points you will get if you complete an answer within a given time..."
              label="Bonus Points per Answer (You will get an extra bonus points only when you complete an answer within a given time)"
              id="bonus_points_per_ans"
              name="bonus_points_per_ans"
              value={values.bonus_points_per_ans}
              onChange={handleInputChange}
              error={errors?.bonus_points_per_ans || ""}
            />

            <div className="four_inputs_on_same_line col_span_2">
              <Input
                className="project_edit_feature_input"
                labelClassName="required"
                errorClassName="err_text"
                type="text"
                placeholder="Enter Price of Lifeline 1..."
                label="Lifeline 1 (Price)"
                id="lifeline1Price"
                name="lifeline1Price"
                value={values.lifeline1Price}
                onChange={handleInputChange}
                error={errors?.lifeline1Price || ""}
              />
              <Input
                className="project_edit_feature_input"
                labelClassName="required"
                errorClassName="err_text"
                type="text"
                placeholder="Enter Price of Lifeline 2..."
                label="Lifeline 2 (Price)"
                id="lifeline2Price"
                name="lifeline2Price"
                value={values.lifeline2Price}
                onChange={handleInputChange}
                error={errors?.lifeline2Price || ""}
              />
              <Input
                className="project_edit_feature_input"
                labelClassName="required"
                errorClassName="err_text"
                type="text"
                placeholder="Enter Price of Lifeline 3..."
                label="Lifeline 3 (Price)"
                id="lifeline3Price"
                name="lifeline3Price"
                value={values.lifeline3Price}
                onChange={handleInputChange}
                error={errors?.lifeline3Price || ""}
              />
              <Input
                className="project_edit_feature_input"
                labelClassName="required"
                errorClassName="err_text"
                type="text"
                placeholder="Enter Price of Lifeline 4..."
                label="Lifeline 4 (Price)"
                id="lifeline4Price"
                name="lifeline4Price"
                value={values.lifeline4Price}
                onChange={handleInputChange}
                error={errors?.lifeline4Price || ""}
              />
            </div>
          </div>

          <Button
            className="project_submit_bottom_btn center_back_btn"
            buttonClassName="comman_btn"
            onClick={updateQuizSettingHandler}
            text="Update"
          />
        </>
      )}
    </div>
  );
};

export default EditQuizSetting;
