export const routes = {
  homepage: "/",
  all: "*",
  login: "/login",

  dashboard: "/dashboard",

  topic: "/topic",
  createTopic: "/create-topic",
  editTopic: "/topic/edit/:id",
  topicByPage: "/topic/page/:page_number",

  avatar: "/avatar",
  editAvatar: "/avatar/edit/:id",

  frame: "/frames",

  question: "/question",
  createQuestion: "/create-question",
  createCsvQuestion: "/create-csv-question",
  addQuestion: "/add-question",
  editQuestion: "/question/edit/:id",
  viewQuestion: "/view-question/:id",
  questionByPage: "/question/page/:page_number",

  corrupted: "/corrupted",
  editCorrupted: "/corrupted/edit/:id",
  corruptedByPage: "/corrupted/page/:page_number",

  payments: "payments",
  paymentsByPage: "payments/page/:page_number",

  user: "/user",
  viewUser: "/view-user/:id",
  viewUserDetails: "/view-user/details/:id",
  viewUserDetailQuestions: "/view-user/details/ques/:id",
  viewUserCoinDetails: "/view-user/coin/details/:id",
  userByPage: "/user/page/:page_number",

  quiz: "/quiz",
  createQuiz: "/create-quiz",
  editQuiz: "/quiz/edit/:id",
  viewQuiz: "/quiz/view/:id",
  quizByPage: "/quiz/page/:page_number",

  coins: "/coins",
  coinsByPage: "/coins/page/:page_number",
  createCoins: "/create-coins",

  setting: "/setting",
  editSetting: "/setting/edit/:id",
  settingByPage: "/setting/page/:page_number",

  service: "/service",
  serviceByPage: "/service/page/:page_number",

  reward: "/reward",
  createReward: "/create-reward",
  editReward: "/reward/edit/:id",
  rewardByPage: "/reward/page/:page_number",

  level: "/level",
  createLevelReward: "/create-level",
  editLevelReward: "/level/edit/:id",
  levelRewardByPage: "/level/page/:page_number",

  contact: "/contacts",

  practice: "/practice",

  mobile: "/mobile-configuration",
  editMobile: "/mobile-configuration/edit",
};
