import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Input from "../../component/Common/Input";
import { fileUpload } from "../../store/slice/fileUploadSlice";
import { createFrameIcons, getFrames } from "../../store/slice/frameSlice";

const AddFrameModal = ({
  closeFrameImgModal,
  imgErrors,
  setImgErrors,
  setOpenAddImgModal,
  setUploading,
}) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState([]);
  const [imagePrev, setImagePrev] = useState([]);

  ///////////// MULTI IMAGE CHANGE HANDLER ///////////////////
  const multipleHandleInputChange = (e) => {
    let imgErr = "";
    const { files } = e.target;

    if (files.length > 0) {
      let teImage = [];
      for (let key in files) {
        if (key !== "length" && key !== "item") {
          if (!files[key].name.match(/.(jpg|jpeg|png)$/i)) {
            imgErr = "Please add valid image (png/jpeg/jpg)";
            continue;
          }
          if (files[key].size > 50000000) {
            imgErr = "Image size should not be greater than 5Mb";
            continue;
          }
          teImage.push(files[key]);
        }
      }

      const tempPrev = [...imagePrev];
      for (let i = 0; i < teImage.length; i++) {
        tempPrev.push(URL.createObjectURL(teImage[i]));
      }

      setImage([...image, ...teImage]);
      setImagePrev([...tempPrev]);
      setImgErrors(imgErr);
    }
  };
  ///////////////////////////////////////////////////////////

  const validate = () => {
    let isValid = false;
    if (image.length === 0) {
      setImgErrors("You must have to select atleast 1 image");
    } else {
      isValid = true;
    }
    return isValid;
  };

  ///////////////// ADD FRAME HANDLER //////////////////////
  const addFrameHandler = async () => {
    if (validate()) {
      const formData = new FormData();
      let tempImage = [];
      setUploading(true);
      setOpenAddImgModal(false);
      for (let i = 0; i < image.length; i++) {
        if (typeof image[i] !== "string") {
          formData.append("file", image[i]);

          let temp = await dispatch(fileUpload(formData));

          if (temp) {
            tempImage.push(temp.payload);
          }
          formData.delete("file");
        } else {
          tempImage.push(image[i]);
        }
      }

      const data = {
        frame: tempImage,
      };

      dispatch(
        createFrameIcons({
          data,
          toast: "Frame added successfully",
          cb: (err, res) => {
            if (err) {
              setUploading(false);
              console.log("err", err);
            } else {
              setUploading(false);
              setOpenAddImgModal(false);
              setImagePrev([]);
              setImage([]);
              setImgErrors("");
              dispatch(getFrames({}));
            }
          },
        })
      );
    }
  };

  ///////////////// REMOVE FRAME //////////////////////
  const frameRemoveHandler = (i) => {
    const imgArr = [...image];
    const imgArrPrev = [...imagePrev];

    imgArr.splice(i, 1);
    imgArrPrev.splice(i, 1);

    setImage(imgArr);
    setImagePrev(imgArrPrev);
  };

  ///////////////////////////////////////////////////////

  return (
    <div className="modal_container">
      <div className="modal_header_container">
        <div className="modal_heading">
          <h1>Add Frame Icon</h1>
        </div>
        <div className="modal_corner_close_btn">
          <button onClick={() => closeFrameImgModal()}>X</button>
        </div>
      </div>
      <div className="project_edit_detail_column_content">
        {/* <h5> Add Icons </h5> */}
        <div className="project_edit_detail_divider">
          <div className="project_edit_feature_input_file">
            <Input
              className="create_from_input_content"
              labelClassName="create_from_label"
              errorClassName="err_text"
              type="file"
              multiple={true}
              id={`avatar_icon`}
              name="avatar_icon"
              accept=".jpg, .jpeg, .png"
              onChange={multipleHandleInputChange}
              onClick={(e) => (e.target.value = null)}
              imageProps={
                <div className="img_prev_container">
                  {imagePrev.length > 0 &&
                    imagePrev.map((img, i) => (
                      <div className="img_prev_inside_container" key={i}>
                        <img
                          src={img}
                          alt="img"
                          width="300"
                          height="300"
                          key={i}
                        />
                        <button
                          className="image_gallery_remove_btn"
                          onClick={() => frameRemoveHandler(i)}
                        >
                          <span></span>
                        </button>
                      </div>
                    ))}
                </div>
              }
            />
          </div>
        </div>
      </div>

      {imgErrors && <span className="err_text">{imgErrors}</span>}

      <div className="modal_btn_row">
        <button
          className="modal_btn modal_cancel_btn modal_add_img_btn"
          onClick={() => closeFrameImgModal()}
        >
          Cancel
        </button>
        <button
          className={`modal_btn modal_create_btn modal_add_img_btn`}
          disabled={imagePrev.length === 0}
          onClick={() => addFrameHandler()}
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddFrameModal;
