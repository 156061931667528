import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

export const createAvatarIcons = createAsyncThunk(
  "createAvatarIcons",
  async (data) => {
    try {
      const response = await Axios.post(`/avtar`, data.data, authHeaders());
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error", error.response);
    }
  }
);

export const updateAvatarIcons = createAsyncThunk(
  "updateAvatarIcons",
  async (data) => {
    try {
      const response = await Axios.put(`/avtar`, data.data, authHeaders());
      if ((response.status === 200 || response.status === 201) && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.avatar}`);
        return response.data;
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

export const getAvatarIcons = createAsyncThunk(
  "getAvatarIcons",
  async (data) => {
    try {
      const response = await Axios.get(
        `/avtar?premium=${data.premium || ""}&dropdown=${data.dropdown || ""}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

const avatarSlice = createSlice({
  name: "avatar",
  initialState: {
    avatarImgList: [],
    updateAvatar: "",
    createAvatar: "",
    totalCount: 0,
    loader: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    //GET AVATAR IMAGES
    builder.addCase(getAvatarIcons.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getAvatarIcons.fulfilled, (state, action) => {
      state.loader = false;
      state.avatarImgList = action.payload.data.rows;
      state.totalCount = action.payload.data.count;
    });
    builder.addCase(getAvatarIcons.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //UPDATE AVATAR IMAGES
    builder.addCase(updateAvatarIcons.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateAvatarIcons.fulfilled, (state, action) => {
      state.loader = false;
      state.updateAvatar = action.payload;
    });
    builder.addCase(updateAvatarIcons.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //CREATE AVATAR IMAGES
    builder.addCase(createAvatarIcons.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createAvatarIcons.fulfilled, (state, action) => {
      state.loader = false;
      state.createAvatar = action.payload;
    });
    builder.addCase(createAvatarIcons.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default avatarSlice.reducer;
