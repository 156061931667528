import React from "react";
import winnerBGImg from "../../images/winnerBGImg.gif";
import winnerOneImg from "../../images/WinnerOneImg.svg";
import winnerTwoImg from "../../images/WinnerTwoImg.svg";
import winnerThreeImg from "../../images/WinnerThreeImg.svg";
import { closeIcon } from "../../icons";

const LeaderBoard = ({ leaderBoard, closeLeaderModal }) => {
  return (
    <div className="quiz-winner-modal-body">
      <button
        type="button"
        onClick={closeLeaderModal}
        className="quiz-winner-modal-close-btn"
      >
        {closeIcon}
      </button>
      <img
        src={winnerBGImg}
        alt="winner background"
        className="quiz-winner-modal-bg-img"
      />
      <div className="quiz-winner-modal-content">
        <div className="quiz-winner-modal-top3-winner-row">
          <div className="quiz-winner-modal-top3-winner-col">
            <p className="quiz-winner-modal-user-name">
              {leaderBoard ? leaderBoard[1]?.user.name : "-"}
            </p>
            <div className="quiz-winner-modal-user-column">
              <img
                src={winnerTwoImg}
                alt="winner img"
                className="quiz-winner-modal-user-column-img"
              />
              <h3 className="quiz-winner-modal-user-column-score">
                {leaderBoard ? leaderBoard[1]?.total_score : "-"}
              </h3>
            </div>
          </div>
          <div className="quiz-winner-modal-top3-winner-col">
            <p className="quiz-winner-modal-user-name">
              {leaderBoard ? leaderBoard[0]?.user.name : "-"}
            </p>
            <div className="quiz-winner-modal-user-column">
              <img
                src={winnerOneImg}
                alt="winner img"
                className="quiz-winner-modal-user-column-img"
              />
              <h3 className="quiz-winner-modal-user-column-score">
                {leaderBoard ? leaderBoard[0]?.total_score : "-"}
              </h3>
            </div>
          </div>
          <div className="quiz-winner-modal-top3-winner-col">
            <p className="quiz-winner-modal-user-name">
              {leaderBoard ? leaderBoard[2]?.user.name : "-"}
            </p>
            <div className="quiz-winner-modal-user-column">
              <img
                src={winnerThreeImg}
                alt="winner img"
                className="quiz-winner-modal-user-column-img"
              />
              <h3 className="quiz-winner-modal-user-column-score">
                {leaderBoard ? leaderBoard[2]?.total_score : "-"}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoard;
