import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";

export const getDashboardCount = createAsyncThunk(
  "getDashboardCount",
  async () => {
    try {
      const response = await Axios.get(`/dashboard`, authHeaders());
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error:", error.response);
      return error.response.data.message;
    }
  }
);

export const getTopTenTopicList = createAsyncThunk(
  "getTopTenTopicList",
  async () => {
    try {
      const response = await Axios.get(`/topic`, authHeaders());
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error:", error.response);
      return error.response.data.message;
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboardCount: {},
    topTenTopicList: [],
    loader: false,
  },
  reducers: {},

  extraReducers: (builder) => {
    //GET DASHBOARD LIST
    builder.addCase(getDashboardCount.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getDashboardCount.fulfilled, (state, action) => {
      state.loader = false;
      state.dashboardCount = action.payload;
    });
    builder.addCase(getDashboardCount.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //GET TOP TEN TOPIC COUNT
    builder.addCase(getTopTenTopicList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getTopTenTopicList.fulfilled, (state, action) => {
      state.loader = false;
      state.topTenTopicList = action.payload;
    });
    builder.addCase(getTopTenTopicList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default dashboardSlice.reducer;
