import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "../../css/LoginPage.css";
import { useForm } from "../../hooks/useForm";
// import smartBeautyLogo from "../../images/smartBeautyLogo/onlylogo.png";
import Loader from "../../component/Common/Loader";
import { validateEmail } from "../../component/Common/PageComponent/LoginComponent/LoginPageComponents";
import LoginPageForm from "../../component/Common/PageComponent/LoginComponent/LoginPageForm";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants";
import { notify } from "../../helpers/commonFunction";
import { loginUser } from "../../store/slice/userSlice";
import { circleTriviaLogo, logoSvg, triviaLogo, zLuckLogo } from "../../icons";
import loginImage from "../../images/TriviaImages/cropImage.jpg";
import loginLogo from "../../images/TriviaImages/BlueQuizLogo.png";

// toast.configure();
const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userReducer = useSelector((state) => state.user);

  const initialFValues = {
    email: "",
    password: "",
    inputType: "password",
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };

    return validateEmail(temp, fieldValues, values, setErrors);
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const signInHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      const data = {
        email: values.email,
        password: values.password,
      };
      dispatch(loginUser(data));
    }
  };

  return (
    <section className="login_section">
      <div className="login_left_image">
        <img src={loginImage} alt="Quiz-Trivia LoginImg" draggable="false" />
      </div>
      <div className="login_content">
        <div className="login_top_content">
          <img
            src={loginLogo}
            alt="Quiz-Trivia-Logo"
            className="login_top_logo"
            draggable="false"
          />
          {/* {zLuckLogo} */}
          <h3>Welcome Back !</h3>
          <p>Sign in to Quiz Trivia</p>
        </div>
        <div className="login_input_logo_content">
          <form className="login_input_content">
            <LoginPageForm
              values={values}
              errors={errors}
              setValues={setValues}
              handleInputChange={handleInputChange}
              signInHandler={signInHandler}
              disabled={userReducer.loader}
            />
          </form>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
