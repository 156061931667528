import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

export const getFrames = createAsyncThunk("getFrames", async (data) => {
  try {
    const response = await Axios.get(
      `/frame?dropdown=${data.dropdown || ""}`,
      authHeaders()
    );
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
});

export const updateFrames = createAsyncThunk("updateFrames", async (data) => {
  try {
    const response = await Axios.put(`/frame`, data.data, authHeaders());
    if ((response.status === 200 || response.status === 201) && data.cb) {
      toast.success(data.toast, {
        autoClose: 3000,
      });
      return data.cb(null, response.data);
    }
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
});

export const createFrameIcons = createAsyncThunk(
  "createFrameIcons",
  async (data) => {
    try {
      const response = await Axios.post(`/frame`, data.data, authHeaders());
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

const frameSlice = createSlice({
  name: "frame",
  initialState: {
    frameImgList: [],
    updateFrame: "",
    createFrame: "",
    totalCount: 0,
    loader: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    //GET FRAMES LIST
    builder.addCase(getFrames.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getFrames.fulfilled, (state, action) => {
      state.loader = false;
      state.frameImgList = action.payload.data.rows;
      state.totalCount = action.payload.data.count;
    });
    builder.addCase(getFrames.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //UPDATE FRAMES IMAGES
    builder.addCase(updateFrames.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateFrames.fulfilled, (state, action) => {
      state.loader = false;
      state.updateFrame = action.payload;
    });
    builder.addCase(updateFrames.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //CREATE FRAME IMAGES
    builder.addCase(createFrameIcons.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createFrameIcons.fulfilled, (state, action) => {
      state.loader = false;
      state.createFrame = action.payload;
    });
    builder.addCase(createFrameIcons.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default frameSlice.reducer;
