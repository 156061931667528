import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios, { authHeaders } from "../../helpers/axios";
import { routes } from "../../constants";

export const getCoinsHistoryListByUserId = createAsyncThunk(
  "getCoinsHistoryListByUserId",
  async (data) => {
    try {
      const response = await Axios.get(
        `/coin-history?user_id=${data.user_id}&start=${
          data.start || 0
        }&limits=${data.limits || 10}`,
        authHeaders()
      );
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getCoinsHistoryList = createAsyncThunk(
  "getCoinsHistoryList",
  async (data) => {
    try {
      const response = await Axios.get(
        `/coin-history?coin_status=${data.coin_status || ""}&start=${
          data.start || 0
        }&limits=${data.limits || 10}&name=${data.name || ""}`,
        authHeaders()
      );

      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const createCoinHistory = createAsyncThunk(
  "createCoinHistory",
  async (data) => {
    try {
      const response = await Axios.post(
        `/coin-history`,
        data.data,
        authHeaders()
      );
      if (response.status === 201) {
        toast.success(data.toast, { autoClose: 3000 });
        data.navigate(`${routes.coins}/page/1`);
      }
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

const coinSlice = createSlice({
  name: "coins",
  initialState: {
    coinList: [],
    coinListByUserId: [],
    createCoin: "",
    loader: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    //GET COIN HISTORY LIST
    builder.addCase(getCoinsHistoryList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getCoinsHistoryList.fulfilled, (state, action) => {
      state.loader = false;
      state.coinList = action.payload;
    });
    builder.addCase(getCoinsHistoryList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //GET COIN HISTORY LIST BY USER ID
    builder.addCase(getCoinsHistoryListByUserId.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getCoinsHistoryListByUserId.fulfilled, (state, action) => {
      state.loader = false;
      state.coinListByUserId = action.payload;
    });
    builder.addCase(getCoinsHistoryListByUserId.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //CREATE COIN HISTORY
    builder.addCase(createCoinHistory.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createCoinHistory.fulfilled, (state, action) => {
      state.loader = false;
      state.createCoin = action.payload;
    });
    builder.addCase(createCoinHistory.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default coinSlice.reducer;
