import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios, { authHeaders } from "../../helpers/axios";

export const getServiceList = createAsyncThunk(
  "getServiceList",
  async (data) => {
    try {
      const response = await Axios.get(
        `/support-request?status=${data.status || ""}&request_date=${
          data.request_date || ""
        }&start=${data.start || 0}&limits=${data.limits || 10}`,
        authHeaders()
      );
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getServiceListById = createAsyncThunk(
  "getServiceListById",
  async (data) => {
    try {
      const response = await Axios.get(
        `/support-request/${data.id}`,
        authHeaders()
      );
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const updateServiceStatus = createAsyncThunk(
  "updateServiceStatus",
  async (data) => {
    try {
      const response = await Axios.put(
        `/support-request/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 201 && data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

export const addServiceRequestReply = createAsyncThunk(
  "addServiceRequestReply",
  async (data) => {
    try {
      const response = await Axios.post(
        `/support-request/reply`,
        data.data,
        authHeaders()
      );
      if (response.status === 201 && data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

export const updateServiceRequestReply = createAsyncThunk(
  "updateServiceRequestReply",
  async (data) => {
    try {
      const response = await Axios.put(
        `/support-request/reply/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 201 && data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

const serviceSlice = createSlice({
  name: "service",
  initialState: {
    serviceList: [],
    serviceListById: {},
    updateSerStatus: {},
    totalCount: 0,
    loader: false,
    error: "",
    msgLoader: false,
    msgComponent: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    //GET SERVICE LIST
    builder.addCase(getServiceList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getServiceList.fulfilled, (state, action) => {
      state.loader = false;
      state.serviceList = action.payload.rows;
      state.totalCount = action.payload.count;
    });
    builder.addCase(getServiceList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //GET SERVICE LIST BY ID
    builder.addCase(getServiceListById.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getServiceListById.fulfilled, (state, action) => {
      state.loader = false;
      state.serviceListById = action.payload;
    });
    builder.addCase(getServiceListById.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //UPDATE SERVICE STATUS
    builder.addCase(updateServiceStatus.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateServiceStatus.fulfilled, (state, action) => {
      state.loader = false;
      state.updateSerStatus = action.payload;
    });
    builder.addCase(updateServiceStatus.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //ADD MSG
    builder.addCase(addServiceRequestReply.pending, (state) => {
      state.msgLoader = true;
    });
    builder.addCase(addServiceRequestReply.fulfilled, (state, action) => {
      state.msgLoader = false;
      state.msgComponent = action.payload;
    });
    builder.addCase(addServiceRequestReply.rejected, (state, action) => {
      state.msgLoader = false;
      state.error = action.payload;
    });

    //REPLY MSG
    builder.addCase(updateServiceRequestReply.pending, (state) => {
      state.msgLoader = true;
    });
    builder.addCase(updateServiceRequestReply.fulfilled, (state, action) => {
      state.msgLoader = false;
      state.msgComponent = action.payload;
    });
    builder.addCase(updateServiceRequestReply.rejected, (state, action) => {
      state.msgLoader = false;
      state.error = action.payload;
    });
  },
});

export default serviceSlice.reducer;
