import { Form } from "react-bootstrap";

// SALON FORM VALIDATIIONS ////////////////////
export const SalonFormValidate = (
  fieldValues,
  temp,
  setValues,
  values,
  setErrors,
  { salonId }
) => {
  if ("salon_name" in fieldValues) {
    temp.salon_name = fieldValues.salon_name.trim()
      ? ""
      : "Salon Name is required!";
  }
  if ("salon_owner_name" in fieldValues) {
    temp.salon_owner_name = fieldValues.salon_owner_name.trim()
      ? ""
      : "Salon owner name is required!";
  }
  if ("address" in fieldValues) {
    temp.address = fieldValues.address.trim()
      ? ""
      : "Salon address is required!";
  }
  if ("phone_number" in fieldValues) {
    temp.phone_number = fieldValues.phone_number.trim()
      ? ""
      : "Phone Number is required!";
  }
  if ("email" in fieldValues) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mailReg = new RegExp(mailformat);
    if (!fieldValues.email) {
      temp.email = "Email is required!";
    } else {
      temp.email = fieldValues.email.match(mailReg)
        ? ""
        : "Please enter a valid email!";
    }
  }
  if (!salonId) {
    if ("password" in fieldValues) {
      temp.password = fieldValues.password.trim()
        ? ""
        : "Password is required!";
    }
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

// SALON PAGE FILTERS //////////////////////
export const salonPageFilter = ({
  handleFilterChange,
  onChangingSearchField,
}) => {
  return (
    <div className="common_all_filter_row">
      <div className="common_filter_input_box first_filter_container">
        <div className="common_select_box">
          <Form.Select
            onChange={handleFilterChange}
            id="user_status"
            name="job_status"
            className="select_form"
            defaultValue={"DEFAULT"}
          >
            <option value="DEFAULT" disabled>
              Salon Status
            </option>
            <option value="0">Active Salon</option>
            <option value="1">Deleted Salon</option>
          </Form.Select>
        </div>
      </div>
      <div className="common_filter_input_box secound_filter_container">
        <input
          type="text"
          placeholder="Search..."
          onChange={onChangingSearchField}
        />
      </div>
    </div>
  );
};

///////////// SALON TABLE HEAD LIST ///////////////////
export const tableHeadItemsForSalon = [
  {
    id: 1,
    name: "salon Name",
  },
  {
    id: 2,
    name: "salon Owner",
  },
  {
    id: 3,
    name: "salon Address",
  },
  {
    id: 4,
    name: "Salon Phone Numer",
  },
  {
    id: 5,
    name: "Salon Email",
  },
  {
    id: 6,
    name: "Edit / Delete",
  },
];

export const tableHeadItemsForTopic = [
  {
    id: 1,
    name: "Image",
  },
  {
    id: 2,
    name: "Topic",
  },
  {
    id: 3,
    name: "Total Questions",
  },
  {
    id: 4,
    name: "Created at",
  },
  {
    id: 5,
    name: "Status",
  },
  {
    id: 6,
    name: "Actions",
  },
];

export const tableHeadItemsForQuestions = [
  {
    id: 1,
    name: "",
  },
  {
    id: 2,
    name: "Level",
  },
  {
    id: 3,
    name: "Topic",
  },
  {
    id: 4,
    name: "Questions",
  },
  {
    id: 5,
    name: "Correct count",
  },
  {
    id: 6,
    name: "Incorrect count",
  },
  {
    id: 7,
    name: "Created At",
  },
  {
    id: 8,
    name: "Actions",
  },
];

export const tableHeadItemsForCorruptedQues = [
  {
    id: 1,
    name: "",
  },
  {
    id: 2,
    name: "Level",
  },
  {
    id: 3,
    name: "Topic",
  },
  {
    id: 4,
    name: "Questions",
  },
  // {
  //   id: 5,
  //   name: "Correct count",
  // },
  // {
  //   id: 6,
  //   name: "Incorrect count",
  // },
  {
    id: 7,
    name: "Created At",
  },
  // {
  //   id: 8,
  //   name: "Updated At",
  // },
  {
    id: 9,
    name: "Actions",
  },
];

export const tableHeadItemsForUserList = [
  {
    id: 1,
    name: "Profile",
  },
  {
    id: 2,
    name: "Name",
  },
  {
    id: 3,
    name: "Premium",
  },
  {
    id: 4,
    name: "Coins",
  },
  {
    id: 5,
    name: "Role",
  },
  {
    id: 6,
    name: "Scores",
  },
  {
    id: 7,
    name: "Created At",
  },
  {
    id: 8,
    name: "Updated At",
  },
  {
    id: 9,
    name: "Actions",
  },
];

export const tableHeadItemsForUserQuizData = [
  {
    id: 1,
    name: "Topic",
  },
  {
    id: 2,
    name: "Level",
  },
  {
    id: 3,
    name: "Start Time",
  },
  {
    id: 4,
    name: "End Time",
  },
  {
    id: 5,
    name: "Total Answers",
  },
  {
    id: 6,
    name: "Correct Answers",
  },
  {
    id: 7,
    name: "Created At",
  },
  {
    id: 8,
    name: "Updated At",
  },
  {
    id: 9,
    name: "Actions",
  },
];

export const tableHeadItemsForUserCoinData = [
  {
    id: 1,
    name: "Avatar",
  },
  {
    id: 2,
    name: "Name",
  },
  {
    id: 3,
    name: "Coins",
  },
  {
    id: 4,
    name: "Coin Status",
  },
  {
    id: 5,
    name: "Lifeline",
  },
  {
    id: 6,
    name: "Watching Ads",
  },
  {
    id: 7,
    name: "Created At",
  },
  // {
  //   id: 8,
  //   name: "Updated At",
  // },
  // {
  //   id: 9,
  //   name: "Actions",
  // },
];

export const tableHeadItemsForQuizList = [
  {
    id: 1,
    name: "Name",
  },
  {
    id: 2,
    name: "Topic",
  },
  {
    id: 3,
    name: "Level",
  },
  {
    id: 4,
    name: "Start Time",
  },
  {
    id: 5,
    name: "End Time",
  },
  {
    id: 6,
    name: "Total Answers",
  },
  {
    id: 7,
    name: "Correct Answers",
  },
  {
    id: 8,
    name: "Total Score",
  },
  {
    id: 9,
    name: "Created At",
  },
  {
    id: 10,
    name: "Updated At",
  },
  {
    id: 11,
    name: "Actions",
  },
];

export const tableHeadItemsForService = [
  {
    id: 1,
    name: "User Name",
  },
  {
    id: 2,
    name: "Subject",
  },

  {
    id: 3,
    name: "Email",
  },
  {
    id: 4,
    name: "Request Date",
  },
  {
    id: 5,
    name: "Status",
  },
  {
    id: 6,
    name: "Created At",
  },
  {
    id: 7,
    name: "Updated At",
  },
  {
    id: 8,
    name: "Actions",
  },
];

export const tableHeadItemsForDailyRewards = [
  {
    id: 1,
    name: "Image",
  },
  {
    id: 2,
    name: "Day",
  },
  {
    id: 3,
    name: "Coins",
  },
  {
    id: 4,
    name: "Created At",
  },
  {
    id: 5,
    name: "Updated At",
  },
  {
    id: 6,
    name: "Actions",
  },
];

export const tableHeadItemsForLevelRewards = [
  {
    id: 1,
    name: "Avatar",
  },
  {
    id: 2,
    name: "Level",
  },
  {
    id: 3,
    name: "Frame",
  },
  {
    id: 4,
    name: "Coins",
  },
  {
    id: 4,
    name: "Created At",
  },
  {
    id: 5,
    name: "Updated At",
  },
];

export const tableHeadItemsForQuizSettings = [
  {
    id: 1,
    name: "Quiz Count",
  },
  // {
  //   id: 2,
  //   name: "Coins",
  // },
  {
    id: 3,
    name: "Created At",
  },
  {
    id: 4,
    name: "Updated At",
  },
  {
    id: 5,
    name: "Actions",
  },
];

export const tableHeadItemsForCoinHistory = [
  {
    id: 1,
    name: "Profile Image",
  },
  {
    id: 2,
    name: "User Name",
  },
  {
    id: 3,
    name: "Coins",
  },
  {
    id: 4,
    name: "Coin Status",
  },
  {
    id: 5,
    name: "Quiz Id",
  },
  {
    id: 9,
    name: "By Level",
  },
  {
    id: 6,
    name: "Lifeline",
  },
  {
    id: 7,
    name: "Ads Watched",
  },
  {
    id: 8,
    name: "Created At",
  },
  // {
  //   id: 8,
  //   name: "Updated At",
  // },
  {
    id: 9,
    name: "Actions",
  },
];

export const questionLevelOptions = [
  {
    value: "EASY",
    label: "Easy",
  },
  {
    value: "MEDIUM",
    label: "Medium",
  },
  {
    value: "HARD",
    label: "Hard",
  },
];

export const dailyRewardDayOptions = [
  {
    id: "Day 1",
    name: "Monday",
  },
  {
    id: "Day 2",
    name: "Tuesday",
  },
  {
    id: "Day 3",
    name: "Wednesday",
  },
  {
    id: "Day 4",
    name: "Thursday",
  },
  {
    id: "Day 5",
    name: "Friday",
  },
  {
    id: "Day 6",
    name: "Saturday",
  },
  {
    id: "Day 7",
    name: "Sunday",
  },
];

export const tableHeadItemsForAppStatus = [
  {
    id: 1,
    name: "Name",
  },
  {
    id: 3,
    name: "Created At",
  },
  {
    id: 4,
    name: "Updated At",
  },
  {
    id: 5,
    name: "Actions",
  },
];

export const tableHeadItemsForPaymentHistory = [
  {
    id: 1,
    name: "Image",
  },
  {
    id: 2,
    name: "User Name",
  },
  {
    id: 3,
    name: "User Id",
  },
  {
    id: 4,
    name: "Receipt Number",
  },
  {
    id: 5,
    name: "Amount",
  },
  {
    id: 6,
    name: "Payment Date",
  },
  // {
  //   id: 4,
  //   name: "Updated At",
  // },
  {
    id: 7,
    name: "Actions",
  },
];

export const tableHeadItemsForTopTenTopicList = [
  {
    id: 1,
    name: "Topic",
  },
  {
    id: 2,
    name: "Count",
  },
];

export const staticCsvData = [
  {
    question: "What is the purpose of a tester in software development",
    optionA: "To find bugs and issues",
    optionB: "To write code",
    optionC: "To design user interfaces",
    optionD: "Unit testing",
    answer: "To find bugs and issues",
  },
  {
    question: "What is the purpose of a tester in software development",
    optionA: "To find bugs and issues",
    optionB: "To write code",
    optionC: "To design user interfaces",
    optionD: "Unit testing",
    answer: "To find bugs and issues",
  },
  {
    question: "What is the purpose of a tester in software development",
    optionA: "To find bugs and issues",
    optionB: "To write code",
    optionC: "To design user interfaces",
    optionD: "Unit testing",
    answer: "To find bugs and issues",
  },
  {
    question: "What is the purpose of a tester in software development",
    optionA: "To find bugs and issues",
    optionB: "To write code",
    optionC: "To design user interfaces",
    optionD: "Unit testing",
    answer: "To find bugs and issues",
  },
  {
    question: "What is the purpose of a tester in software development",
    optionA: "To find bugs and issues",
    optionB: "To write code",
    optionC: "To design user interfaces",
    optionD: "Unit testing",
    answer: "To find bugs and issues",
  },
  {
    question: "What is the purpose of a tester in software development",
    optionA: "To find bugs and issues",
    optionB: "To write code",
    optionC: "To design user interfaces",
    optionD: "Unit testing",
    answer: "To find bugs and issues",
  },
  {
    question: "What is the purpose of a tester in software development",
    optionA: "To find bugs and issues",
    optionB: "To write code",
    optionC: "To design user interfaces",
    optionD: "Unit testing",
    answer: "To find bugs and issues",
  },
  {
    question: "What is the purpose of a tester in software development",
    optionA: "To find bugs and issues",
    optionB: "To write code",
    optionC: "To design user interfaces",
    optionD: "Unit testing",
    answer: "To find bugs and issues",
  },
  {
    question: "What is the purpose of a tester in software development",
    optionA: "To find bugs and issues",
    optionB: "To write code",
    optionC: "To design user interfaces",
    optionD: "Unit testing",
    answer: "To find bugs and issues",
  },
  {
    question: "What is the purpose of a tester in software development",
    optionA: "To find bugs and issues",
    optionB: "To write code",
    optionC: "To design user interfaces",
    optionD: "Unit testing",
    answer: "To find bugs and issues",
  },
];
