import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CommonHeading from "../../component/Common/CommonHeading";
import { backBtnArrow } from "../../icons";
import Input from "../../component/Common/Input";
import { useForm } from "../../hooks/useForm";
import Button from "../../component/Common/Button";
import {
  createLevel,
  getLevelList,
  getLevelListForDropDown,
  updateLevel,
} from "../../store/slice/levelSlice";
import { Col, Form, Row } from "react-bootstrap";
import { getAvatarIcons } from "../../store/slice/avatarSlice";
import { getFrames } from "../../store/slice/frameSlice";
import coinImg from "../../images/Coin-icon.png";
import Select from "react-select";
import selectImage from "../../images/TriviaImages/selectImage.jpg";

const CreateLevelReward = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const editLevelReward = location?.state?.level;

  let counts = useSelector((state) => state?.level?.levelDropdownList);
  let lastObject = counts[counts.length - 1]?.level;

  useEffect(() => {
    dispatch(getAvatarIcons({ premium: "PREMIUM", start: 0, limits: 100000 }));
    dispatch(getFrames({ dropdown: true, start: 0, limits: 100000 }));
    dispatch(
      getLevelListForDropDown({ dropdown: true, start: 0, limits: 100000 })
    );
  }, []);

  const list = useSelector((state) => state?.level?.levelDropdownList);
  const premiumAvatarId = useSelector((state) => state?.avatar?.avatarImgList);
  const frameId = useSelector((state) => state?.frame?.frameImgList);

  // console.log("premiumAvatarId", premiumAvatarId);

  // console.log("counts", counts);
  // console.log("lastObject", lastObject);

  //ALL STATES VALUES
  const initialFValues = {
    // level: editLevelReward ? editLevelReward?.level : lastObject || "",
    level: editLevelReward
      ? editLevelReward?.level
      : lastObject
      ? lastObject
      : 1,
    coins: editLevelReward ? editLevelReward?.coins : "",
    // avtar_id: editLevelReward ? editLevelReward?.avtar_id : "",
    avtar_id: editLevelReward
      ? {
          value: editLevelReward.avtar_id,
          label:
            editLevelReward.avtar !== null
              ? editLevelReward.avtar_id
              : "Select Avatar",
          // "",
          image: editLevelReward.avtar !== null && editLevelReward.avtar.image,
          type: "avatar",
        }
      : "",
    // frame_id: editLevelReward ? editLevelReward?.frame_id : "",
    frame_id: editLevelReward
      ? {
          value: editLevelReward.frame_id,
          label:
            editLevelReward.frames !== null
              ? editLevelReward.frame_id
              : "Select Frame",
          // "",
          image:
            editLevelReward.frames !== null && editLevelReward.frames.frame,
          type: "frame",
        }
      : "",
  };

  //VALIDATION OF LEVEL REWARD FORM
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("level" in fieldValues) {
      temp.level = fieldValues.level ? "" : "Please add level!";
    }

    if (
      !fieldValues.avtar_id?.value &&
      !fieldValues.coins &&
      !fieldValues.frame_id?.value
    ) {
      temp.multiFieldError = "Please select at least one field!";
    } else if (
      fieldValues.avtar_id?.value &&
      fieldValues.coins &&
      fieldValues.frame_id?.value
    ) {
      temp.multiFieldError = "Please select only one field!";
    } else if (
      !fieldValues.avtar_id?.value &&
      fieldValues.coins &&
      fieldValues.frame_id?.value
    ) {
      temp.multiFieldError = "Please select only one field!";
    } else if (
      !fieldValues.coins &&
      fieldValues.avtar_id?.value &&
      fieldValues.frame_id?.value
    ) {
      temp.multiFieldError = "Please select only one field!";
    } else if (
      !fieldValues.frame_id?.value &&
      fieldValues.avtar_id?.value &&
      fieldValues.coins
    ) {
      temp.multiFieldError = "Please select only one field!";
    } else {
      temp.multiFieldError = "";
    }

    // console.log("temp", temp);

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  console.log(
    "avtar",
    values.avtar_id,
    "frame",
    values.frame_id,
    "coins",
    values.coins
  );

  const CustomOption = ({ innerProps, label, data }) => {
    return (
      <div
        {...innerProps}
        className={`custom-option ${
          data.type === "avatar"
            ? values.avtar_id
              ? values.avtar_id.value === label
                ? "custom_background"
                : ""
              : ""
            : values.frame_id
            ? values.frame_id.value === label
              ? "custom_background"
              : ""
            : ""
        }`}
      >
        <img
          src={
            data.image === ""
              ? "https://cdn.pixabay.com/photo/2017/04/20/07/08/select-2244784_1280.png"
              : process.env.REACT_APP_FILE_BASE_URL + "/" + data.image
          }
          // src={process.env.REACT_APP_FILE_BASE_URL + "/" + data.image}
          alt={label}
          style={{ marginRight: "20px", width: "80px", height: "50px" }}
        />
        {/* {label} */}
      </div>
    );
  };

  useEffect(() => {
    if (counts && counts.length > 0) {
      let lastObject = counts[counts.length - 1]?.level + 1;
      setValues({
        ...values,
        level: editLevelReward ? editLevelReward?.level : lastObject || "",
      });
    } else {
      setValues({
        ...values,
        level: editLevelReward ? editLevelReward?.level : 1,
      });
    }
  }, [counts, values.level]);

  let avatarArray = premiumAvatarId?.map((item) => ({
    value: item.id,
    label: item.id,
    image: item.image,
    type: "avatar",
  }));

  let avatarOptions = [
    {
      value: "",
      label: "Select Avatar...",
      image: "",
      type: "avatar",
    },
    ...avatarArray,
  ];

  let frameArray = frameId?.map((item) => ({
    value: item.id,
    label: item.id,
    image: item.frame,
    type: "frame",
  }));

  let frameOptions = [
    {
      value: "",
      label: "Select Frame...",
      image: "",
      type: "frame",
    },
    ...frameArray,
  ];

  //CREATE-EDIT MAIN HANDLER
  const createEditLevelHandler = async () => {
    if (validate()) {
      const data = {
        level: values?.level,
        coins: values?.coins ? values?.coins : null,
        avtar_id: values?.avtar_id
          ? values?.avtar_id.value
            ? values?.avtar_id.value
            : null
          : null,
        frame_id: values?.frame_id
          ? values?.frame_id.value
            ? values?.frame_id.value
            : null
          : null,
      };
      console.log("data", data);

      editLevelReward
        ? dispatch(
            updateLevel({
              data,
              id: editLevelReward?.id,
              navigate,
              toast: "Level reward updated successfully",
            })
          )
        : dispatch(
            createLevel({
              data,
              navigate,
              toast: "Level reward created successfully",
            })
          );
    }
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading
          heading={
            editLevelReward ? "UPDATE LEVEL REWARD" : "CREATE LEVEL REWARD"
          }
        />

        <div className="project_edit_main_link">
          <div className="comman_center_btn_container">
            <button className="go_back_btn" onClick={() => navigate(-1)}>
              {backBtnArrow} Back
            </button>
          </div>
        </div>

        <div className="create_from_row">
          <div className="project_edit_feature_input mt-0">
            <Input
              className="project_edit_feature_input"
              // inputClassName={`create_from_input`}
              inputClassName={`create_from_input ${
                editLevelReward
                  ? "level_input_disabled"
                  : "disabled_input_create"
              }`}
              labelClassName="required"
              errorClassName="err_text"
              type="text"
              placeholder="Enter Level"
              label="Level"
              id="level"
              name="level"
              value={values.level}
              onChange={handleInputChange}
              error={errors.level || ""}
              disabled={true}
            />
          </div>
          <div></div>
          <div className="four_inputs_on_same_line col_span_2">
            <h4 className="project_text_area_label required">
              Select any one field for giving reward
            </h4>
            <Row className="w-100">
              <Col sm={4}>
                <div className="common_select_box select_filter_container">
                  <h4 className="project_text_area_label col_span_2">
                    Avatar Id
                  </h4>
                  {/* <Form.Select
                    onChange={(e) => handleInputChange(e, "avtar_id")}
                    id="avtar_id"
                    name="avtar_id"
                    className="select_form"
                    defaultValue={values.avtar_id ? values.avtar_id : "Default"}
                  >
                    <option value="">Select Avatar Id</option>
                    {premiumAvatarId?.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item.id}
                      </option>
                    ))}
                  </Form.Select> */}
                  <Select
                    className="cm-select"
                    defaultValue={avatarOptions[0]}
                    options={avatarOptions}
                    id="avtar_id"
                    name="avtar_id"
                    components={{ Option: CustomOption }}
                    value={values.avtar_id}
                    onChange={(e) => handleInputChange(e, "avtar_id")}
                    // onChange={(e) => handleAvatarChange(e)}
                    placeholder="Select Avatar"
                  />
                </div>
              </Col>
              <Col sm={4}>
                <Input
                  className="project_edit_feature_input"
                  errorClassName="err_text"
                  type="text"
                  placeholder="Enter Coins..."
                  label="Coins"
                  id="coins"
                  name="coins"
                  value={values.coins}
                  onChange={handleInputChange}
                />
              </Col>
              <Col sm={4}>
                <div className="common_select_box select_filter_container">
                  <h4 className="project_text_area_label">Frame Id</h4>
                  {/* <Form.Select
                    onChange={(e) => handleInputChange(e, "frame_id")}
                    id="frame_id"
                    name="frame_id"
                    className="select_form"
                    defaultValue={values.frame_id ? values.frame_id : "Default"}
                  >
                    <option value="">Select Frame</option>
                    {frameId?.map((item, i) => {
                      return (
                        <option value={item.id} key={i}>
                          {item.id}
                        </option>
                      );
                    })}
                  </Form.Select> */}

                  <Select
                    defaultValue={frameOptions[0]}
                    className="cm-select"
                    options={frameOptions}
                    id="frame_id"
                    name="frame_id"
                    components={{ Option: CustomOption }}
                    value={values.frame_id && values.frame_id}
                    onChange={(e) => handleInputChange(e, "frame_id")}
                    placeholder="Select Frame"
                  />
                </div>
              </Col>
            </Row>
          </div>
          {errors.multiFieldError && (
            <span className="err_text">{errors.multiFieldError}</span>
          )}
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn save_and_submit_form_btn"
          onClick={createEditLevelHandler}
          text={editLevelReward ? "Update" : "Add"}
        />
      </div>
    </>
  );
};

export default CreateLevelReward;
