import React from "react";
import { useDispatch } from "react-redux";
import { getUserList, updateUserList } from "../../store/slice/userSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const DeleteUser = ({
  closeDeleteModal,
  deleteId,
  setOpenDeleteModal,
  setDeleteId,
  totalCount,
  pageNumber,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //DELETE USER HANDLER
  const deleteUserHandler = (deleteId) => {
    const data = {
      delete_status: true,
    };

    dispatch(
      updateUserList({
        data,
        id: deleteId,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            setOpenDeleteModal(false);
            setDeleteId("");
            toast.error("User deleted successfully", { autoClose: 3000 });
            dispatch(getUserList({}));
            if (
              totalCount &&
              totalCount[totalCount.toString().length - 1] === 1 &&
              +pageNumber !== 1
            ) {
              navigate(`/user/page/${pageNumber - 1}`);
            } else {
              dispatch(
                getUserList({ start: (pageNumber - 1) * 10, limits: 10 })
              );
            }
          }
        },
      })
    );
  };

  return (
    <div className="modal_container">
      <div className="modal_heading_2">
        <h1 className="modal_main_heading_with_desc">Are you sure?</h1>
        <p>you want to delete this User!</p>
      </div>
      <div className="modal_btn_row">
        <button
          onClick={() => closeDeleteModal()}
          className="modal_btn modal_cancel_btn"
        >
          Cancel
        </button>
        <button
          className="modal_btn modal_delete_btn"
          onClick={() => deleteUserHandler(deleteId)}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteUser;
