import React, { useState } from "react";
import { deleteIcon, editIcon } from "../../icons";
import { Modal } from "react-bootstrap";
import DeleteSelectedImg from "./DeleteSelectedImg";
import CrownImg from "../../images/crown.png";
import { Link } from "react-router-dom";
import { routes } from "../../constants";

const AvatarImgList = ({ avatarList, deleteIconHandler, showImgModal }) => {
  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  const [openSelectedImgModal, setOpenSelectedImgModal] = useState(false);
  const [selectedDeleteIds, setSelectedDeleteIds] = useState([]);

  const [selectAllImg, setSelectAllImg] = useState([]);
  const [clickSelectAll, setClickSelectAll] = useState(false);

  //SELECT-ALL BUTTON HANDLER
  const selectAllHandler = () => {
    setClickSelectAll(!clickSelectAll);
    if (!clickSelectAll) {
      setSelectAllImg(avatarList.map((i) => i.id));
    } else {
      setSelectAllImg([]);
    }
  };

  //ON-CHANGE HANDLER OF CHECKBOX INPUT
  const handleInputCheckboxChange = (e, avatarImg) => {
    let tempCheckedValue = [...selectAllImg];

    if (tempCheckedValue.includes(avatarImg.id)) {
      let index = tempCheckedValue.findIndex((obj) => obj === avatarImg.id);

      if (index > -1) {
        tempCheckedValue.splice(index, 1);
      }
      setSelectAllImg(tempCheckedValue);
    } else {
      setSelectAllImg([...selectAllImg, avatarImg.id]);
    }
  };

  //OPEN DELETE MODAL FOR DELETING SELECTED IMAGES
  const deleteBtnHandlerForSelectedImg = () => {
    setOpenSelectedImgModal(true);
    setSelectedDeleteIds(selectAllImg);
  };

  //CLOSE DELETE MODAL FOR DELETING SELECTED IMAGES
  const closeSelectedImgDeleteModal = () => {
    setOpenSelectedImgModal(false);
    setSelectedDeleteIds([]);
  };

  return (
    <>
      <div className="creat_edit_project_btn_row_flex">
        <div>
          <button className="comman_btn ml-auto" onClick={showImgModal}>
            Add Avatar
          </button>
        </div>
        <div>
          {avatarList?.length > 0 && (
            <button className="comman_btn ml-auto" onClick={selectAllHandler}>
              {clickSelectAll ? "DeSelect All" : "Select All"}
            </button>
          )}

          {selectAllImg?.length > 0 && (
            <button
              className="comman_btn ml-auto"
              onClick={deleteBtnHandlerForSelectedImg}
            >
              Delete
            </button>
          )}
        </div>
      </div>
      <div className="project_service_main_div avatar_individual_page">
        {avatarList?.length === 0 || !avatarList ? (
          <p className="no_data_found no_image_msg">No data Found</p>
        ) : (
          <>
            {avatarList?.map((avatarImg, index) => {
              return (
                <div
                  className="project_service_main_div_row"
                  key={avatarImg?.id}
                >
                  <div className="service_icon_card">
                    {/* <div> */}
                    {avatarImg?.premium === true && (
                      <div className="crown_img">
                        <img src={CrownImg} alt="premium" />
                      </div>
                    )}
                    <div className="all_icons_wrapper">
                      <div>
                        {selectAllImg?.length > 0 && (
                          <>
                            <input
                              type="checkbox"
                              name="checkboxOnImg"
                              onChange={(e) =>
                                handleInputCheckboxChange(e, avatarImg)
                              }
                              checked={selectAllImg.includes(avatarImg.id)}
                              id={avatarImg.id}
                              className="service_icon_card_input"
                            />
                            <label
                              htmlFor={avatarImg.id}
                              className="service_icon_card_label"
                            ></label>
                          </>
                        )}
                      </div>

                      <div className="all_avatar_icons_container">
                        <span
                          className="avatar_delete_btn"
                          role="button"
                          onClick={() => deleteIconHandler(avatarImg?.id)}
                        >
                          {deleteIcon}
                        </span>

                        <Link
                          aria-label="edit"
                          className="avatar_premium_edit_btn"
                          to={`${routes?.avatar}/edit/${avatarImg?.id}`}
                          state={{ avatarDetails: avatarImg }}
                        >
                          {editIcon}
                        </Link>
                      </div>
                    </div>
                    <img
                      src={`${process.env.REACT_APP_FILE_BASE_URL}/${avatarImg?.image}`}
                      alt="avatar"
                      onClick={() => {
                        setImgZoom(true);
                        setImgSrc(
                          avatarImg?.image
                            ? process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                avatarImg?.image
                            : process.env.REACT_APP_DEFAULT_IMG
                        );
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      <div
        onClick={() => setImgZoom(false)}
        className={`oc-table-img-modal-bg ${
          imgZoom && "oc-table-img-modal-open"
        }`}
      ></div>
      <div
        className={`oc-table-img-modal ${imgZoom && "oc-table-img-modal-open"}`}
      >
        <img src={imgSrc} alt="avatar" />
      </div>

      <Modal
        className="modal_main_component modal_background_content"
        show={openSelectedImgModal}
        onHide={closeSelectedImgDeleteModal}
        centered
      >
        <DeleteSelectedImg
          selectedDeleteIds={selectedDeleteIds}
          setSelectedDeleteIds={setSelectedDeleteIds}
          setOpenSelectedImgModal={setOpenSelectedImgModal}
          closeSelectedImgDeleteModal={closeSelectedImgDeleteModal}
        />
      </Modal>
    </>
  );
};

export default AvatarImgList;
