import React, { useEffect, useRef, useState } from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserList,
  getUserListForDropDown,
} from "../../store/slice/userSlice";

import { Link, useNavigate, useParams } from "react-router-dom";

import Loader from "../../component/Common/Loader";
import Pagination from "../../component/Pagination/Pagination";
import { Form, Modal } from "react-bootstrap";
import DeleteUser from "./DeleteUser";
import UserList from "./UserList";

const User = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(false);
  const params = useParams();
  const { page_number } = params;

  const list = useSelector((state) => state.user?.userList);
  const isLoading = useSelector((state) => state.user?.loader);
  const totalCount = useSelector((state) => state.user?.totalCount);
  const dropDownUserList = useSelector((state) => state.user?.dropDownUserList);

  const [pageN, setPageN] = useState(page_number - 1);
  const [deleteId, setDeleteId] = useState("");

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const nameOption = dropDownUserList.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const [filterName, setFilterName] = useState("");
  const [premium, setPremium] = useState("");

  useEffect(() => {
    if (ref.current) {
      if (page_number !== 1) {
        navigate(`/user/page/1`);
      }
      dispatch(
        getUserList({
          start: (page_number - 1) * 10,
          limits: 10,
          name: filterName === "" ? "" : filterName,
          premium: premium === "" ? "" : premium,
        })
      );
    }
  }, [filterName, premium]);

  useEffect(() => {
    if (ref.current) {
      dispatch(
        getUserList({
          start: (page_number - 1) * 10,
          limits: 10,
          name: filterName === "" ? "" : filterName,
          premium: premium === "" ? "" : premium,
        })
      );
    }
  }, [pageN]);

  useEffect(() => {
    dispatch(
      getUserList({
        start: (page_number - 1) * 10,
        limits: 10,
        name: filterName === "" ? "" : filterName,
        premium: premium === "" ? "" : premium,
      })
    );
    dispatch(getUserListForDropDown({ dropDown: true, limits: 2000 }));
    ref.current = true;
  }, []);

  //on-Page Change
  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  //DELETE ICON HANDLER
  const deleteIconHandler = (id) => {
    setOpenDeleteModal(true);
    setDeleteId(id);
  };

  //CLOSE DELETE MODAL
  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setDeleteId("");
  };

  //On-Change USER FILTER
  const handleInputChange = (e) => {
    setFilterName(e.target.value);
  };

  //On-Change PREMIUM FILTER
  const handleInputChangeOfPremium = (e) => {
    setPremium(e.target.value);
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="USERS" />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="creat_edit_project_btn_row_flex">
              <div>
                {/* <Link to={routes.createReward} className="comman_btn ml-auto">
                  Create
                </Link> */}
              </div>

              {/* FILTER PART */}
              <div className="common_all_filter_row justify-content-end">
                <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      id="premium"
                      name="premium"
                      className="select_form filter_box"
                      value={premium}
                      onChange={handleInputChangeOfPremium}
                    >
                      <option value="" disabled>
                        Filter by Premium...
                      </option>
                      <option value="">All</option>
                      <option value="PREMIUM">premium</option>
                      <option value="NON_PREMIUM">Non premium</option>
                    </Form.Select>
                  </div>
                </div>

                <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      id="name"
                      name="name"
                      className="select_form filter_box"
                      value={filterName}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Filter by Name...
                      </option>
                      <option value="">All</option>
                      {nameOption.map((item, i) => (
                        <option value={item.label} key={i}>
                          {item.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
              </div>
            </div>

            <div className="custom_data_table_content">
              <UserList list={list} deleteIconHandler={deleteIconHandler} />
            </div>
            {totalCount > 0 && (
              <Pagination
                totalRecords={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </>
        )}
      </div>
      <Modal
        className="modal_main_component modal_background_content"
        show={openDeleteModal}
        onHide={closeDeleteModal}
        centered
      >
        <DeleteUser
          closeDeleteModal={closeDeleteModal}
          deleteId={deleteId}
          setOpenDeleteModal={setOpenDeleteModal}
          setDeleteId={setDeleteId}
          totalCount={totalCount?.toString()}
          pageNumber={page_number}
        />
      </Modal>
    </>
  );
};

export default User;

// user page
