import React, { useEffect, useRef, useState } from "react";
import { closeIcon, editIcon } from "../../icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addServiceRequestReply,
  getServiceListById,
  updateServiceRequestReply,
} from "../../store/slice/serviceSlice";
import Loader from "../../component/Common/Loader";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";

const ViewService = ({ closeModal, reqId, prevReqId }) => {
  const dispatch = useDispatch();

  const serviceData = useSelector((state) => state?.service?.serviceListById);
  const isLoading = useSelector((state) => state?.service?.loader);
  const msgLoader = useSelector((state) => state?.service?.msgLoader);

  const [chatTab, setChatTab] = useState(false);
  const [replyMsg, setReplyMsg] = useState("");
  const [onEditMsgId, setOnEditMsgId] = useState("");
  const bottomRef = useRef(null);

  useEffect(() => {
    dispatch(getServiceListById({ id: reqId }));
  }, []);

  //open-chat button handler
  const openChatTab = () => {
    setChatTab(chatTab ? false : true);
    setOnEditMsgId("");
    setReplyMsg("");
  };

  //Edit button Msg Handler
  const editMsgHandler = (msg) => {
    setOnEditMsgId(msg.id);
    setReplyMsg(msg.message);
  };

  //onChange Msg reply
  const msgReplyChange = (e) => {
    setReplyMsg(e.target.value);
  };

  //close Edit msg input
  const closeEditMsg = () => {
    setOnEditMsgId("");
    setReplyMsg("");
  };

  //MSG Btn Handler
  const sendReplyHandler = () => {
    const data = {
      support_request_id: serviceData.id,
      message: replyMsg,
      sender: 1,
    };

    if (onEditMsgId) {
      dispatch(
        updateServiceRequestReply({
          id: onEditMsgId,
          data,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              dispatch(getServiceListById({ id: reqId }));
              setOnEditMsgId("");
              setReplyMsg("");
            }
          },
        })
      );
    } else {
      dispatch(
        addServiceRequestReply({
          data,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              dispatch(getServiceListById({ id: reqId }));
              setReplyMsg("");
            }
          },
        })
      );
    }
  };

  return (
    <>
      {isLoading && !chatTab ? (
        <Loader />
      ) : (
        <div className="modal_container">
          <div className="modal_heading">
            <h1 className="modal_main_heading">Request Detail</h1>
            <button
              className={`comman_btn open_chat_btn ${chatTab && "close_chat"}`}
              onClick={openChatTab}
            >
              {!chatTab ? "Open Chat" : "Close Chat"}
            </button>
            <button className="modal_close_btn" onClick={() => closeModal()}>
              {closeIcon}
            </button>
          </div>

          {!chatTab ? (
            <div className="modal_detail_container">
              <div className="modal_detail_top_content">
                <div className="modal_detail_top_first_content">
                  <img
                    src={`${process.env.REACT_APP_FILE_BASE_URL}/${serviceData.attachment}`}
                    alt="service-detail"
                  />
                  <p
                    className={
                      serviceData.status === "Reopen"
                        ? "credited"
                        : serviceData.status === "Pending"
                        ? "pending"
                        : serviceData.status === "Resolved"
                        ? "resolved"
                        : serviceData.status === "Closed"
                        ? "debited"
                        : "-"
                    }
                  >
                    {serviceData.status
                      ? serviceData.status === "Reopen"
                        ? "REOPEN"
                        : serviceData.status === "Pending"
                        ? "PENDING"
                        : serviceData.status === "Closed"
                        ? "CLOSED"
                        : serviceData.status === "Resolved"
                        ? "RESOLVED"
                        : "-"
                      : "-"}
                  </p>
                </div>
              </div>

              <div className="modal_detail_container_grid only_detail_modal">
                <div className="modal_detail_content">
                  <h2 className="modal_detail_content_heading">
                    Request Subject
                  </h2>
                  <p>{serviceData.subject}</p>
                </div>

                <div className="modal_detail_content">
                  <h2 className="modal_detail_content_heading">Status</h2>
                  <p
                    className={`question_table_level_text ${
                      serviceData.status === "Reopen"
                        ? "credited"
                        : serviceData.status === "Pending"
                        ? "pending"
                        : serviceData.status === "Resolved"
                        ? "resolved"
                        : serviceData.status === "Closed"
                        ? "debited"
                        : "-"
                    }`}
                  >
                    {serviceData.status
                      ? serviceData.status === "Reopen"
                        ? "REOPEN"
                        : serviceData.status === "Pending"
                        ? "PENDING"
                        : serviceData.status === "Closed"
                        ? "CLOSED"
                        : serviceData.status === "Resolved"
                        ? "RESOLVED"
                        : "-"
                      : "-"}
                  </p>
                </div>

                <div className="modal_detail_content">
                  <h2 className="modal_detail_content_heading">User Name</h2>
                  <p>
                    {serviceData.user && serviceData.user.name
                      ? serviceData.user.name
                      : "-"}
                  </p>
                </div>

                <div className="modal_detail_content">
                  <h2 className="modal_detail_content_heading">User Email</h2>
                  <p>
                    {serviceData.user && serviceData.user.email
                      ? serviceData.user.email
                      : "-"}
                  </p>
                </div>

                <div className="modal_detail_content">
                  <h2 className="modal_detail_content_heading">Created At</h2>
                  <p>
                    {serviceData.created_at
                      ? moment
                          .utc(serviceData.created_at, "YYYYMMDD HH:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("DD/MM/YYYY, hh:mm A")
                      : "-"}
                  </p>
                </div>

                <div className="modal_detail_content">
                  <h2 className="modal_detail_content_heading">Updated At</h2>
                  <p>
                    {serviceData.updated_at
                      ? moment
                          .utc(serviceData.updated_at, "YYYYMMDD HH:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("DD/MM/YYYY, hh:mm A")
                      : "-"}
                  </p>
                </div>

                {/* <div className="modal_detail_content">
                  <h2 className="modal_detail_content_heading">
                    Request Date & Time
                  </h2>
                  <p>
                    {moment
                      .utc(serviceData.request_date, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY, hh:mm A")}
                  </p>
                </div> */}

                <div className="modal_detail_content span_2">
                  <h2 className="modal_detail_content_heading">Description</h2>
                  <p>{serviceData.description}</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="modal_detail_container">
              <div className="zu_chat_msg_content">
                <div className="zu_chat_msg_header">
                  <div className="zu_chat_msg_header_name_content">
                    <div className="zu_chat_msg_header_name_text">
                      <h4>
                        {serviceData.user && serviceData.user.name
                          ? serviceData.user.name
                          : "-"}
                      </h4>
                      <p>
                        {serviceData.subject} ({serviceData.status})
                      </p>
                    </div>
                  </div>
                </div>

                <div className="zu_chat_msg_container d-block">
                  {serviceData.replies?.length === 0 ? (
                    <div className="zu_chat_msg_box_content">
                      <div className="zu_chat_msg_box_time">
                        <span>Start a Conversation</span>
                      </div>
                    </div>
                  ) : (
                    serviceData.replies.map((msg, i) => {
                      return (
                        <div key={msg.id}>
                          <div className="zu_chat_msg_box_content">
                            {i === 0 ? (
                              <div className="zu_chat_msg_box_time">
                                <span>
                                  {moment
                                    .utc(
                                      serviceData.replies[0].created_at,
                                      "YYYYMMDD HH:mm:ss"
                                    )
                                    .clone()
                                    .tz(defaultTimeZone)
                                    .format("DD/MM/YYYY")}
                                </span>
                              </div>
                            ) : moment
                                .utc(
                                  serviceData.replies[i].created_at,
                                  "YYYYMMDD HH:mm:ss"
                                )
                                .clone()
                                .tz(defaultTimeZone)
                                .format("DD/MM/YYYY") !==
                              moment
                                .utc(
                                  serviceData.replies[i - 1].created_at,
                                  "YYYYMMDD HH:mm:ss"
                                )
                                .clone()
                                .tz(defaultTimeZone)
                                .format("DD/MM/YYYY") ? (
                              <div className="zu_chat_msg_box_time">
                                <span>
                                  {moment
                                    .utc(
                                      serviceData.replies[i].created_at,
                                      "YYYYMMDD HH:mm:ss"
                                    )
                                    .clone()
                                    .tz(defaultTimeZone)
                                    .format("DD/MM/YYYY")}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {msg.sender === 2 ? (
                            <div className="zu_chat_msg_recive">
                              <div className="zu_chat_msg_recive_text">
                                <span className="zu_chat_msg_recive_text_name">
                                  {serviceData.user?.name} |{" "}
                                  {moment
                                    .utc(msg.created_at, "YYYYMMDD HH:mm:ss")
                                    .clone()
                                    .tz(defaultTimeZone)
                                    .format("hh:mm A")}
                                </span>
                                <div className="zu_chat_msg_recive_text_msg_box_row">
                                  <div className="zu_chat_msg_recive_text_msg_box">
                                    <pre className="zu_chat_msg_recive_text_msg">
                                      <p>{msg.message}</p>
                                    </pre>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="zu_chat_msg_send">
                                <div className="zu_chat_msg_send_text">
                                  <span className="zu_chat_msg_send_text_name">
                                    {moment
                                      .utc(msg.created_at, "YYYYMMDD HH:mm:ss")
                                      .clone()
                                      .tz(defaultTimeZone)
                                      .format("hh:mm A")}
                                  </span>
                                  <div className="zu_chat_msg_send_text_msg_box_row">
                                    <div className="zu_chat_msg_send_text_msg_box">
                                      <pre className="zu_chat_msg_send_text_msg">
                                        <p>{msg.message}</p>
                                      </pre>
                                    </div>
                                    <div className="chat_edit_btn_container">
                                      <button
                                        className="chat_edit_btn"
                                        onClick={() => editMsgHandler(msg)}
                                      >
                                        {editIcon}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {((isLoading && chatTab) || msgLoader) && (
                                <div className="zu_chat_msg_send">
                                  <div className="zu_chat_msg_send_text">
                                    <div className="zu_chat_msg_send_text_msg_box_row">
                                      <div className="zu_chat_msg_send_text_msg_box">
                                        <pre className="zu_chat_msg_send_text_msg">
                                          <p>Loading...</p>
                                        </pre>
                                      </div>
                                      <div className="chat_edit_btn_container"></div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      );
                    })
                  )}
                </div>
                <div className="zu_chat_msg_write_content">
                  {onEditMsgId && (
                    <span className="edit_mode_enable">edit mode</span>
                  )}
                  <input
                    className="zu_chat_input"
                    value={replyMsg}
                    onChange={(e) => msgReplyChange(e)}
                  />

                  {onEditMsgId && (
                    <button
                      className="edit_mode_close_btn"
                      onClick={() => closeEditMsg()}
                    >
                      {closeIcon}
                    </button>
                  )}
                  <button
                    className="send_msg_btn"
                    onClick={() => sendReplyHandler()}
                    disabled={
                      replyMsg.trim() == "" || isLoading || msgLoader
                        ? true
                        : false
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ViewService;
