import React, { useState } from "react";
import { tableHeadItemsForLevelRewards } from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";
import { Link } from "react-router-dom";
import { coinIcon, deleteIcon, editIcon } from "../../icons";
import { routes } from "../../constants";

const LevelRewardList = ({
  list,
  // viewIconHandler,
  editIconHandler,
  deleteIconHandler,
}) => {
  // console.log("list", list);
  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  return (
    <>
      <>
        {list?.length === 0 || !list ? (
          <p className="no_data_found">No Data Found</p>
        ) : (
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                {tableHeadItemsForLevelRewards.map((item, i) => {
                  return (
                    <th
                      className="custom_data_table_heading text-center"
                      key={i}
                    >
                      {item.name}
                    </th>
                  );
                })}
                <th></th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {list?.map((level, i) => {
                return (
                  <tr className="custom_data_table_row" key={i}>
                    <td className="custom_data_table_item table_item">
                      <img
                        alt="avatarImg"
                        className={`custom_data_table_img cursor_pointer_image_view`}
                        src={
                          level?.avtar !== null
                            ? process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              level?.avtar?.image
                            : process.env.REACT_APP_DEFAULT_IMG
                        }
                        onClick={() => {
                          setImgZoom(true);
                          setImgSrc(
                            level?.avtar?.image
                              ? process.env.REACT_APP_FILE_BASE_URL +
                                  "/" +
                                  level?.avtar?.image
                              : process.env.REACT_APP_DEFAULT_IMG
                          );
                        }}
                      />
                    </td>
                    <td className="custom_data_table_item table_item">
                      {level?.level ? level?.level : "-"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      <img
                        alt="frameImg"
                        className={`custom_data_table_img cursor_pointer_image_view`}
                        src={
                          level?.frames !== null
                            ? process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              level?.frames?.frame
                            : process.env.REACT_APP_DEFAULT_IMG
                        }
                        onClick={() => {
                          setImgZoom(true);
                          setImgSrc(
                            level?.frames?.frame
                              ? process.env.REACT_APP_FILE_BASE_URL +
                                  "/" +
                                  level?.frames?.frame
                              : process.env.REACT_APP_DEFAULT_IMG
                          );
                        }}
                      />
                    </td>
                    <td className="custom_data_table_item table_item">
                      {level?.coins ? (
                        <>
                          {level?.coins} {coinIcon}
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {level?.created_at
                        ? moment
                            .utc(level?.created_at, "YYYYMMDD HH:mm:ss")
                            .clone()
                            .tz(defaultTimeZone)
                            .format("DD/MM/YYYY, hh:mm A")
                        : "-"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {level?.updated_at
                        ? moment
                            .utc(level?.updated_at, "YYYYMMDD HH:mm:ss")
                            .clone()
                            .tz(defaultTimeZone)
                            .format("DD/MM/YYYY, hh:mm A")
                        : "-"}
                    </td>
                    {/* <td className="custom_data_table_item custom_data_table_view_edit_item table_item"> */}
                    {/* <div className="custom_data_table_view_edit_btn_item_row">
                        {/* <Link
                          aria-label="edit"
                          className="custom_data_table_view_edit_item_btn"
                          // onClick={() => editIconHandler(level)}
                          to={`${routes?.level}/edit/${level?.id}`}
                          state={{ level: level }}
                        >
                          {editIcon}
                        </Link> */}

                    {/* <button
                          aria-label="delete"
                          className="custom_data_table_view_edit_item_btn  delete_btn"
                          onClick={() => deleteIconHandler(level?.id)}
                        >
                          {deleteIcon}
                        </button> */}
                    {/* </div> */}
                    {/* </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </>
      <div
        onClick={() => setImgZoom(false)}
        className={`oc-table-img-modal-bg ${
          imgZoom && "oc-table-img-modal-open"
        }`}
      ></div>
      <div
        className={`oc-table-img-modal ${imgZoom && "oc-table-img-modal-open"}`}
      >
        <img src={imgSrc} alt="dailyRewardImage" />
      </div>
    </>
  );
};

export default LevelRewardList;
