import { Navigate } from "react-router-dom";
import AuthPage from "./component/Layout/AuthPage";
import Layout from "./component/Layout/MainLayout";
import Dashboard from "./pages/Dashboard/Dashboard";
import LoginPage from "./pages/Login/LoginPage";
import Page404 from "./pages/Page404/Page404";
import { routes } from "../src/constants";
import Topic from "./pages/Topic/Topic";
import CreateTopic from "./pages/Topic/CreateTopic";
import Quiz from "./pages/Quiz/Quiz";
import CreateQuiz from "./pages/Quiz/CreateQuiz";
import Question from "./pages/Question/Question";
import CreateQuestion from "./pages/Question/CreateQuestion";
import ViewQuestion from "./pages/Question/ViewQuestion";
import User from "./pages/User/User";
import ViewUser from "./pages/User/ViewUser";
import ViewUserQuizDetail from "./pages/User/ViewUserQuizDetail";
import ViewUserQuizDetailQuestion from "./pages/User/ViewUserQuizDetailQuestion";
import Service from "./pages/Service/Service";
import ViewQuiz from "./pages/Quiz/ViewQuiz";
import Reward from "./pages/DailyReward/Reward";
import CreateReward from "./pages/DailyReward/CreateReward";
import QuizSetting from "./pages/QuizSettings/QuizSetting";
import EditQuizSetting from "./pages/QuizSettings/EditQuizSetting";
import CoinHistory from "./pages/CoinsHistory/CoinHistory";
import CreateCoinHistory from "./pages/CoinsHistory/CreateCoinHistory";
import Avatar from "./pages/Avatar/Avatar";
import ViewUserCoinDetails from "./pages/User/ViewUserCoinDetails";
import CreateCSVQuestion from "./pages/Question/CreateCSVQuestion";
import MobileConfiguration from "./pages/MobileConfiguration/MobileConfiguration";
import MobileConfig from "./pages/MobileConfiguration/MobileConfig";
import Practice from "./pages/Practice/Practice";
import AddQuestion from "./pages/Question/AddQuestion";
import CorruptedQuestion from "./pages/CorruptedQuestion/CorruptedQuestion";
import EditCorruptedQuestion from "./pages/CorruptedQuestion/EditCorruptedQuestion";
import PaymentHistory from "./pages/PremiumUsers/PaymentHistory";
import Frame from "./pages/Frame/Frame";
import LevelReward from "./pages/LevelReward/LevelReward";
import CreateLevelReward from "./pages/LevelReward/CreateLevelReward";
import EditAvatar from "./pages/Avatar/EditAvatar";

const getRoutes = (user) => [
  {
    path: routes.homepage,
    element: !user ? <AuthPage /> : <Navigate to={routes.dashboard} />,
    children: [
      { path: routes.login, element: <LoginPage /> },
      { path: routes.homepage, element: <Navigate to={routes.login} /> },
    ],
  },
  {
    path: routes.homepage,
    element: user ? <Layout /> : <Navigate to={routes.login} />,
    children: [
      { path: routes.dashboard, element: <Dashboard /> },

      { path: routes.topic, element: <Topic /> },
      { path: routes.topicByPage, element: <Topic /> },
      { path: routes.createTopic, element: <CreateTopic /> },
      { path: routes.editTopic, element: <CreateTopic /> },

      { path: routes.avatar, element: <Avatar /> },
      { path: routes.editAvatar, element: <EditAvatar /> },

      { path: routes.frame, element: <Frame /> },

      { path: routes.question, element: <Question /> },
      { path: routes.questionByPage, element: <Question /> },
      { path: routes.createQuestion, element: <CreateQuestion /> },
      { path: routes.createCsvQuestion, element: <CreateCSVQuestion /> },
      { path: routes.addQuestion, element: <AddQuestion /> },
      { path: routes.editQuestion, element: <CreateQuestion /> },
      { path: routes.viewQuestion, element: <ViewQuestion /> },

      { path: routes.corrupted, element: <CorruptedQuestion /> },
      { path: routes.corruptedByPage, element: <CorruptedQuestion /> },
      { path: routes.editCorrupted, element: <EditCorruptedQuestion /> },

      { path: routes.user, element: <User /> },
      { path: routes.userByPage, element: <User /> },
      { path: routes.viewUser, element: <ViewUser /> },
      { path: routes.viewUserDetails, element: <ViewUserQuizDetail /> },
      { path: routes.viewUserCoinDetails, element: <ViewUserCoinDetails /> },
      {
        path: routes.viewUserDetailQuestions,
        element: <ViewUserQuizDetailQuestion />,
      },

      { path: routes.quiz, element: <Quiz /> },
      { path: routes.quizByPage, element: <Quiz /> },
      { path: routes.viewQuiz, element: <ViewQuiz /> },
      { path: routes.createQuiz, element: <CreateQuiz /> },
      { path: routes.editQuiz, element: <CreateQuiz /> },

      { path: routes.setting, element: <QuizSetting /> },
      { path: routes.settingByPage, element: <QuizSetting /> },
      { path: routes.editSetting, element: <EditQuizSetting /> },

      { path: routes.coins, element: <CoinHistory /> },
      { path: routes.coinsByPage, element: <CoinHistory /> },
      { path: routes.createCoins, element: <CreateCoinHistory /> },

      { path: routes.reward, element: <Reward /> },
      { path: routes.rewardByPage, element: <Reward /> },
      { path: routes.createReward, element: <CreateReward /> },
      { path: routes.editReward, element: <CreateReward /> },

      { path: routes.level, element: <LevelReward /> },
      { path: routes.levelRewardByPage, element: <LevelReward /> },
      { path: routes.createLevelReward, element: <CreateLevelReward /> },
      { path: routes.editLevelReward, element: <CreateLevelReward /> },

      { path: routes.service, element: <Service /> },
      { path: routes.serviceByPage, element: <Service /> },

      { path: routes.payments, element: <PaymentHistory /> },
      { path: routes.paymentsByPage, element: <PaymentHistory /> },

      { path: routes.mobile, element: <MobileConfig /> },
      { path: routes.editMobile, element: <MobileConfiguration /> },

      { path: routes.practice, element: <Practice /> },

      { path: routes.homepage, element: <Navigate to={routes.dashboard} /> },
    ],
  },
  {
    path: "",
    element: !user ? <Navigate to={routes.login} /> : <Page404 />,
    children: [
      { path: routes.login, element: <LoginPage /> },
      { path: "*", element: <LoginPage /> },
    ],
  },
];

export default getRoutes;
