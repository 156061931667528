import React, { useEffect, useRef, useState } from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../constants";
import { tableHeadItemsForQuestions } from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import { useDispatch, useSelector } from "react-redux";
import { getQuestions } from "../../store/slice/questionSlice";
import Loader from "../../component/Common/Loader";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";
import { deleteIcon, eyeIcon } from "../../icons";
import Pagination from "../../component/Pagination/Pagination";
import Select from "react-select";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "../../hooks/useForm";
import DeleteQuestion from "./DeleteQuestion";
import { getTopicList } from "../../store/slice/topicSlice";
import ViewQuestion from "./ViewQuestion";

const Question = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const ref = useRef(false);
  const { page_number } = params;
  const topicId = location?.state?.topicId;

  const questionsList = useSelector((state) => state.question?.getQues);
  const isLoading = useSelector((state) => state.question?.loader);
  const listCount = useSelector((state) => state.question?.totalCount);
  const topicList = useSelector((state) => state.topic?.topicList);

  const value = location?.state?.value;
  const pageValue = location?.state?.value_page;

  const [pageN, setPageN] = useState(page_number - 1);
  const [newQuestionsList, setNewQuestionsList] = useState(questionsList);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [selectedQuestionIds, setSelectedQuestionIds] = useState([]);
  const [viewModalData, setViewModalData] = useState("");
  const [openViewModal, setOpenViewModal] = useState(false);

  const initialFValues = {
    // level: value?.level ? value?.level : "",
    // topic: value?.topic ? value?.topic : "",
    level: "",
    order: "",
    topic: topicId ? topicId : "",
    select_all: false,
  };

  //USE FORM HOOK
  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  useEffect(() => {
    setNewQuestionsList(questionsList);
    setValues({ ...values, select_all: false });
  }, [questionsList]);

  //USE-EFFECT FOR FILTER
  useEffect(() => {
    if (ref.current) {
      if (page_number !== 1) {
        navigate("/question/page/1");
      }
      dispatch(
        getQuestions({
          level: values.level === "" ? "" : values.level,
          order: values.order === "" ? "" : values.order,
          start: (page_number - 1) * 50,
          limits: 50,
          topic: values.topic === "" ? "" : Number(values.topic),
        })
      );
    }
  }, [values.level, values.topic, values.order]);

  let starts = (page_number - 1) * 50;

  //USE-EFFECT FOR PAGINATION
  useEffect(() => {
    if (ref.current) {
      dispatch(
        getQuestions({
          start: (page_number - 1) * 50,
          limits: 50,
          level: values.level === "" ? "" : values.level,
          order: values.order === "" ? "" : values.order,
          topic: values.topic === "" ? "" : Number(values.topic),
        })
      );
    }
  }, [pageN]);

  //USE-EFFECT FOR INITIAL RENDERING
  useEffect(() => {
    dispatch(
      getQuestions({
        level: values.level === "" ? "" : values.level,
        order: values.order === "" ? "" : values.order,
        start: (page_number - 1) * 50,
        limits: 50,
        topic:
          topicId !== ""
            ? Number(topicId)
            : values.topic === ""
            ? ""
            : Number(values.topic),
      })
    );
    dispatch(getTopicList({ dropdown: true }));
    ref.current = true;
  }, []);

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  //VIEW ICON HANDLER
  const viewIconHandler = (item) => {
    setOpenViewModal(true);
    setViewModalData(item);
  };

  //CLOSE VIEW MODAL
  const closeViewModal = () => {
    setOpenViewModal(false);
    setViewModalData("");
  };

  // DELETE ICON HANDLER
  const deleteIconHandler = (id) => {
    setOpenDeleteModal(true);
    setDeleteId(id);
    setSelectedQuestionIds([]);
  };

  // CLOSE DELETE MODAL
  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setDeleteId("");
    setNewQuestionsList((prevState) => {
      const newState = prevState.map((prev) => {
        return {
          ...prev,
          isChecked: false,
        };
      });
      return newState;
    });
    setSelectedQuestionIds([]);
    setValues({ ...values, select_all: false });
  };

  // Handle select question for delete
  const onChangeSelectedQuesHandler = (event, index, item) => {
    const list = [...newQuestionsList];
    if (typeof index === "string") {
      let selectList = [];
      setValues({ ...values, select_all: event.target.checked });
      const newSelectQuestion = list.map((item) => {
        return {
          ...item,
          isChecked: event.target.checked,
        };
      });
      newSelectQuestion &&
        newSelectQuestion.forEach((item) => {
          if (item.isChecked === true) {
            selectList.push(item.id);
          }
        });
      setNewQuestionsList(newSelectQuestion);
      setSelectedQuestionIds(selectList);
    } else {
      let selectList = [...selectedQuestionIds];
      const updatedItem = { ...list[index], isChecked: event.target.checked };
      list[index] = updatedItem;
      setNewQuestionsList(list);
      if (event.target.checked) {
        selectList.push(list[index]?.id);
      } else {
        const questionRemove = selectList.findIndex(
          (question) => question === item.id
        );
        if (questionRemove !== -1) {
          selectList.splice(questionRemove, 1);
        }
      }
      const checkIsCheck =
        list && list.find((item) => item.isChecked === false);
      if (checkIsCheck) {
        setValues({ ...values, select_all: false });
      } else {
        setValues({ ...values, select_all: true });
      }
      setSelectedQuestionIds(selectList);
    }
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="QUESTIONS" />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="creat_edit_project_btn_row_flex">
              <div>
                <Link to={routes.createQuestion} className="comman_btn ml-auto">
                  Create
                </Link>
                <Link
                  to={routes.createCsvQuestion}
                  className="comman_btn ml-auto"
                >
                  Import
                </Link>
                <Link to={routes.addQuestion} className="comman_btn ml-auto">
                  Add
                </Link>
                {selectedQuestionIds && selectedQuestionIds?.length > 0 && (
                  <button
                    onClick={() => deleteIconHandler(selectedQuestionIds)}
                    className="comman_btn ml-auto"
                  >
                    Delete
                  </button>
                )}
              </div>

              <div className="common_all_filter_row justify-content-end">
                <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      id="order"
                      name="order"
                      className="select_form"
                      // defaultValue={values.level ? values.level : "DEFAULT"}
                      value={values.order}
                      onChange={(e) => handleInputChange(e, "order")}
                    >
                      <option value="" disabled>
                        Filter by Order...
                      </option>
                      <option value="">All</option>
                      <option value="LATEST">Latest</option>
                      <option value="OLDEST">Oldest</option>
                    </Form.Select>
                  </div>
                </div>

                <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      id="level"
                      name="level"
                      className="select_form"
                      // defaultValue={values.level ? values.level : "DEFAULT"}
                      value={values.level}
                      onChange={(e) => handleInputChange(e, "level")}
                    >
                      <option value="" disabled>
                        Filter by Level...
                      </option>
                      <option value="">All</option>
                      <option value="EASY">Easy</option>
                      <option value="MEDIUM">Medium</option>
                      <option value="HARD">Hard</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      id="topic"
                      name="topic"
                      className="select_form"
                      value={values.topic}
                      onChange={(e) => handleInputChange(e, "topic")}
                    >
                      <option value="" disabled>
                        Filter by Topic...
                      </option>
                      <option value="">All</option>
                      {topicList &&
                        topicList?.length > 0 &&
                        topicList?.map((topic, i) => {
                          return (
                            <option key={i} value={topic.id}>
                              {topic?.name}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </div>
                </div>
              </div>
            </div>

            <div className="custom_data_table_content">
              {newQuestionsList?.length === 0 || !newQuestionsList ? (
                <p className="no_data_found">No data Found</p>
              ) : (
                <table className="custom_data_table">
                  <thead className="custom_data_table_head">
                    <tr>
                      {tableHeadItemsForQuestions.map((ques, i) => {
                        return (
                          <th
                            className="custom_data_table_heading text-center question_table_heading_checkbox_content"
                            key={i}
                          >
                            {i === 0 ? (
                              <div className="create_question_list_checkbox question_checkbox_content">
                                <input
                                  type="checkbox"
                                  id={`question_with_checkbox`}
                                  name={"question_with_checkbox"}
                                  value="question_with_checkbox"
                                  onChange={(e) =>
                                    onChangeSelectedQuesHandler(e, "all", "all")
                                  }
                                  checked={values.select_all}
                                  className="create_question_list_checkbox_input"
                                />
                                <label
                                  htmlFor={`question_with_checkbox`}
                                  className="create_question_list_checkbox_label"
                                >
                                  {/* Select all */}
                                </label>
                              </div>
                            ) : (
                              ques.name
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="custom_data_table_body">
                    {newQuestionsList?.map((item, i) => {
                      return (
                        <tr className="custom_data_table_row" key={item.id}>
                          <td className="custom_data_table_item">
                            <div className="create_question_list_checkbox question_checkbox_content">
                              <input
                                type="checkbox"
                                className="create_question_list_checkbox_input"
                                id={`question_with_checkbox${i}`}
                                name={"question_with_checkbox" + i}
                                value="question_with_checkbox"
                                onChange={(e) =>
                                  onChangeSelectedQuesHandler(e, i, item)
                                }
                                checked={item.isChecked}
                              />
                              <label
                                htmlFor={`question_with_checkbox${i}`}
                                className="create_question_list_checkbox_label"
                              ></label>
                            </div>
                          </td>
                          <td className="custom_data_table_item table_item">
                            <span
                              className={`question_table_level_text ${
                                item.level === "EASY"
                                  ? "credited"
                                  : item.level === "HARD"
                                  ? "debited"
                                  : item.level === "MEDIUM"
                                  ? "pending"
                                  : "-"
                              }`}
                            >
                              {item.level ? item.level : "-"}
                            </span>
                          </td>
                          <td className="custom_data_table_item table_item">
                            {item.topic?.name ? item.topic.name : "-"}
                          </td>
                          <td className="custom_data_table_item table_item">
                            {item.question ? item.question : "-"}
                          </td>
                          <td className="custom_data_table_item table_item">
                            {item.correct_answer_count ? (
                              <span className="question_table_level_text_correct_answer start-time">
                                {item.correct_answer_count}
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="custom_data_table_item table_item">
                            {item.wrong_answer_count ? (
                              <span className="question_table_level_text_correct_answer end-time">
                                {item.wrong_answer_count}
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="custom_data_table_item table_item">
                            {item.created_at
                              ? moment
                                  .utc(item.created_at, "YYYYMMDD HH:mm:ss")
                                  .clone()
                                  .tz(defaultTimeZone)
                                  .format("DD/MM/YYYY, hh:mm A")
                              : "-"}
                          </td>
                          <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                            <div className="custom_data_table_view_edit_btn_item_row">
                              {/* <Link
                                className="custom_data_table_view_edit_item_btn "
                                aria-label="view"
                                to={`/view-question/${item.id}`}
                                state={{
                                  questionDetails: item,
                                  value: {
                                    level:
                                      values.level === "" ? "" : values.level,
                                    topic:
                                      values.topic === "" ? "" : values.topic,
                                  },
                                  page_number,
                                }}
                              >
                                {eyeIcon}
                              </Link> */}
                              <button
                                aria-label="delete"
                                className="custom_data_table_view_edit_item_btn"
                                onClick={() => viewIconHandler(item)}
                              >
                                {eyeIcon}
                              </button>
                              <button
                                aria-label="delete"
                                className="custom_data_table_view_edit_item_btn delete_btn"
                                onClick={() => deleteIconHandler([item.id])}
                              >
                                {deleteIcon}
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
            <>
              {listCount > 0 && (
                <Pagination
                  totalRecords={listCount}
                  onPageChange={onPageChange}
                  limits={50}
                />
              )}
            </>
          </>
        )}
      </div>

      <Modal
        className="modal_main_component modal_background_content"
        dialogClassName="custom-dialog"
        show={openViewModal}
        onHide={closeViewModal}
        centered
      >
        <ViewQuestion
          viewModalData={viewModalData}
          closeViewModal={closeViewModal}
        />
      </Modal>

      <Modal
        className="modal_main_component modal_background_content"
        show={openDeleteModal}
        onHide={closeDeleteModal}
        centered
      >
        <DeleteQuestion
          values={values}
          closeDeleteModal={closeDeleteModal}
          deleteId={deleteId}
          setOpenDeleteModal={setOpenDeleteModal}
          setDeleteId={setDeleteId}
          totalCount={listCount?.toString()}
          pageNumber={page_number}
        />
      </Modal>
    </>
  );
};

export default Question;
