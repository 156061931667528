import React, { useState, useEffect, useRef } from "react";
import Button from "../../component/Common/Button";
import CreateTopic from "./CreateTopic";
import CommonHeading from "../../component/Common/CommonHeading";
import { backBtnArrow, deleteIcon, editIcon, eyeIcon } from "../../icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { routes } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getTopicList, updateTopic } from "../../store/slice/topicSlice";
import { tableHeadItemsForTopic } from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import Loader from "../../component/Common/Loader";
import { Modal } from "react-bootstrap";
import DeleteTopic from "./DeleteTopic";
import { defaultTimeZone } from "../../helpers/commonFunction";
import Pagination from "../../component/Pagination/Pagination";
import ViewQuestion from "../Question/ViewQuestion";
import ViewTopic from "./ViewTopic";
import { getQuestions, updateQuestions } from "../../store/slice/questionSlice";
import { toast } from "react-toastify";

const Topic = () => {
  const dispatch = useDispatch();
  const ref = useRef(false);
  const params = useParams();
  const { page_number } = params;
  const navigate = useNavigate();

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [topicDeleteId, setTopicDeleteId] = useState("");
  const [pageN, setPageN] = useState(page_number - 1);
  // const [openViewModal, setOpenViewModal] = useState(false);
  // const [viewData, setViewData] = useState("");
  const [isActive, setIsActive] = useState(false);

  const list = useSelector((state) => state.topic.topicList);
  const isLoading = useSelector((state) => state.topic.loader);
  const totalCount = useSelector((state) => state.topic.topicCount);

  useEffect(() => {
    if (ref.current) {
      dispatch(getTopicList({ start: (page_number - 1) * 10, limits: 10 }));
    }
  }, [pageN]);

  useEffect(() => {
    dispatch(getTopicList({ start: (page_number - 1) * 10, limits: 10 }));
    ref.current = true;
  }, []);

  //OPEN DELETE MODAL
  const deleteModalHandler = (id) => {
    setShowDeleteModal(true);
    setTopicDeleteId(id);
  };

  //CLOSE DELETE MODAL
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  //VIEW ICON HANDLER
  // const viewIconHandler = (topic) => {
  //   console.log("==============");
  //   setOpenViewModal(true);
  //   setViewData(topic);
  // };

  //CLOSE VIEW MODAL
  // const closeViewModal = () => {
  //   setOpenViewModal(false);
  //   setViewData("");
  // };

  //page-change handler
  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  const topicViewIconHandler = (topicId) => {
    if (ref.current) {
      dispatch(getQuestions({ topic: Number(topicId) }));
    }
  };

  const activeInActiveTopic = (topic) => {
    const data = { active_status: topic.active_status == true ? false : true };
    dispatch(
      updateTopic({
        data,
        id: topic.id,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            topic.active_status == true
              ? toast.error("Topic InActivated Successfully", {
                  autoClose: 3000,
                })
              : toast.success("Topic Activated Successfully", {
                  autoClose: 3000,
                });
            dispatch(
              getTopicList({ start: (page_number - 1) * 10, limits: 10 })
            );
          }
        },
      })
    );
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="TOPIC LIST" />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="creat_edit_project_btn_row_flex">
              <Link to={routes.createTopic} className="comman_btn ml-auto">
                Create
              </Link>
            </div>

            <div className="custom_data_table_content">
              {list?.length === 0 || !list ? (
                <p className="no_data_found">No data Found</p>
              ) : (
                <table className="custom_data_table">
                  <thead className="custom_data_table_head">
                    <tr>
                      {tableHeadItemsForTopic?.map((item, index) => {
                        return (
                          <th
                            className="custom_data_table_heading text-center"
                            key={item.id}
                          >
                            {item.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="custom_data_table_body">
                    {list?.map((topic, i) => {
                      return (
                        <tr className="custom_data_table_row" key={topic.id}>
                          <td className="custom_data_table_item table_item">
                            <img
                              src={
                                topic.topic_image === null
                                  ? process.env.REACT_APP_DEFAULT_IMG
                                  : process.env.REACT_APP_FILE_BASE_URL +
                                    "/" +
                                    topic.topic_image
                              }
                              alt="topic-icon"
                              className={`custom_data_table_img`}
                              onClick={() => {
                                setImgZoom(true);
                                setImgSrc(
                                  topic.topic_image
                                    ? process.env.REACT_APP_FILE_BASE_URL +
                                        "/" +
                                        topic.topic_image
                                    : process.env.REACT_APP_DEFAULT_IMG
                                );
                              }}
                            />
                          </td>
                          <td className="custom_data_table_item table_item">
                            {topic.name && topic.name}
                          </td>
                          <td className="custom_data_table_item table_item">
                            {topic.question_count ? (
                              <span className="question_table_level_text_correct_answer_count resolved">
                                {topic.question_count}
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="custom_data_table_item table_item">
                            {topic.created_at &&
                              moment
                                .utc(topic.created_at, "YYYYMMDD HH:mm:ss")
                                .clone()
                                .tz(defaultTimeZone)
                                .format("DD/MM/YYYY, hh:mm A")}
                          </td>

                          {/* <td className="custom_data_table_item table_item">
                            {topic.updated_at
                              ? moment
                                  .utc(topic.updated_at, "YYYYMMDD HH:mm:ss")
                                  .clone()
                                  .tz(defaultTimeZone)
                                  .format("DD/MM/YYYY, hh:mm A")
                              : "-"}
                          </td> */}
                          <td className="custom_data_table_item table_item">
                            {/* <span
                              onClick={() => activeInActiveTopic(topic)}
                              className={`question_table_level_text cursor-pointer ${
                                topic.active_status === 0
                                  ? "debited"
                                  : "credited"
                              }`}
                            >
                              {topic.active_status === 0
                                ? "InActive"
                                : "Active"}
                            </span> */}
                            <div className="qt_table_switch_box">
                              <input
                                type="checkbox"
                                id="qt_table_switch1"
                                className="d-none"
                              />
                              <label
                                htmlFor="qt_table_switch1"
                                onClick={() => activeInActiveTopic(topic)}
                                className={`${
                                  topic.active_status === 0 ? "" : "active"
                                }`}
                              ></label>

                              {/* {topic.active_status === 0 ? (
                                <span className="inActives">InActive</span>
                              ) : (
                                <span className="actives">Active</span>
                              )} */}
                            </div>
                          </td>
                          <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                            <div className="custom_data_table_view_edit_btn_item_row">
                              <Link
                                state={{ topicId: topic.id }}
                                to={`${routes.question}/page/1`}
                              >
                                <button
                                  aria-label="delete"
                                  className="custom_data_table_view_edit_item_btn"
                                  onClick={() => topicViewIconHandler(topic.id)}
                                >
                                  {eyeIcon}
                                </button>
                              </Link>

                              <Link
                                aria-label="edit"
                                className="custom_data_table_view_edit_item_btn"
                                state={{ topic: topic }}
                                to={`${routes.topic}/edit/${topic.id}`}
                              >
                                {editIcon}
                              </Link>
                              <button
                                aria-label="delete"
                                className="custom_data_table_view_edit_item_btn delete_btn"
                                onClick={() => deleteModalHandler(topic.id)}
                              >
                                {deleteIcon}
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
            {totalCount > 0 && (
              <Pagination
                totalRecords={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </>
        )}
      </div>
      {/* <Modal
        className="modal_main_component modal_background_content"
        dialogClassName="custom-dialog"
        show={openViewModal}
        onHide={closeViewModal}
        centered
      >
        <ViewTopic closeViewModal={closeViewModal} viewData={viewData} />
      </Modal> */}
      <Modal
        className="modal_main_component modal_background_content"
        show={showDeleteModal}
        onHide={hideDeleteModal}
        centered
      >
        <DeleteTopic
          hideDeleteModal={hideDeleteModal}
          topicDeleteId={topicDeleteId}
          setShowDeleteModal={setShowDeleteModal}
          setTopicDeleteId={setTopicDeleteId}
          totalCount={totalCount?.toString()}
          pageNumber={page_number}
        />
      </Modal>
      <div
        onClick={() => setImgZoom(false)}
        className={`oc-table-img-modal-bg ${
          imgZoom && "oc-table-img-modal-open"
        }`}
      ></div>
      <div
        className={`oc-table-img-modal ${imgZoom && "oc-table-img-modal-open"}`}
      >
        <img src={imgSrc} alt="dailyRewardImage" />
      </div>
    </>
  );
};

export default Topic;
