import React, { useState } from "react";
import { deleteIcon } from "../../icons";
import { Modal } from "react-bootstrap";
import DeleteSelectedFrames from "./DeleteSelectedFrames";

const FrameList = ({ frameList, deleteIconHandler, showAddFrameModal }) => {
  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  const [openSelectedImgModal, setOpenSelectedImgModal] = useState(false);
  const [selectedDeleteIds, setSelectedDeleteIds] = useState([]);

  const [selectAllImg, setSelectAllImg] = useState([]);
  const [clickSelectAll, setClickSelectAll] = useState(false);

  let type = "single";

  //SELECT-ALL BUTTON HANDLER
  const selectAllHandler = () => {
    setClickSelectAll(!clickSelectAll);
    if (!clickSelectAll) {
      setSelectAllImg(frameList?.map((i) => i.id));
    } else {
      setSelectAllImg([]);
    }
  };

  //ON-CHANGE HANDLER OF CHECKBOX INPUT
  const handleInputCheckboxChange = (e, frameImg) => {
    let tempCheckedValue = [...selectAllImg];
    if (tempCheckedValue.includes(frameImg?.id)) {
      let index = tempCheckedValue.findIndex((obj) => obj === frameImg.id);
      if (index > -1) {
        tempCheckedValue.splice(index, 1);
      }
      setSelectAllImg(tempCheckedValue);
    } else {
      setSelectAllImg([...selectAllImg, frameImg?.id]);
    }
  };

  //OPEN DELETE MODAL FOR DELETING SELECTED IMAGES
  const deleteBtnHandlerForSelectedImg = () => {
    setOpenSelectedImgModal(true);
    setSelectedDeleteIds(selectAllImg);
  };

  //CLOSE DELETE MODAL FOR DELETING SELECTED IMAGES
  const closeSelectedImgDeleteModal = () => {
    setOpenSelectedImgModal(false);
    setSelectedDeleteIds([]);
  };

  return (
    <>
      <div className="creat_edit_project_btn_row_flex">
        <div>
          <button className="comman_btn ml-auto" onClick={showAddFrameModal}>
            Add Frames
          </button>
        </div>
        <div>
          {frameList?.length > 0 && (
            <button className="comman_btn ml-auto" onClick={selectAllHandler}>
              {clickSelectAll ? "DeSelect All" : "Select All"}
            </button>
          )}
          {selectAllImg?.length > 0 && (
            <button
              className="comman_btn ml-auto"
              onClick={deleteBtnHandlerForSelectedImg}
            >
              Delete
            </button>
          )}
        </div>
      </div>

      <div className="project_service_main_div">
        {frameList?.length === 0 || !frameList ? (
          <p>No data Found</p>
        ) : (
          <>
            {frameList?.map((frames, index) => {
              return (
                <div className="project_service_main_div_row" key={frames?.id}>
                  <div className="service_icon_card">
                    <span
                      className="img_delete_btn"
                      role="button"
                      onClick={() => deleteIconHandler(frames?.id, type)}
                    >
                      {deleteIcon}
                    </span>

                    {selectAllImg?.length > 0 && (
                      <>
                        <input
                          type="checkbox"
                          name="checkboxOnImg"
                          onChange={(e) => handleInputCheckboxChange(e, frames)}
                          checked={selectAllImg.includes(frames?.id)}
                          id={frames?.id}
                          className="service_icon_card_input"
                        />
                        <label
                          htmlFor={frames?.id}
                          className="service_icon_card_label"
                        ></label>
                      </>
                    )}

                    <img
                      src={`${process.env.REACT_APP_FILE_BASE_URL}/${frames?.frame}`}
                      alt="frame"
                      onClick={() => {
                        setImgZoom(true);
                        setImgSrc(
                          frames?.frame
                            ? process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                frames?.frame
                            : process.env.REACT_APP_DEFAULT_IMG
                        );
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      <div
        onClick={() => setImgZoom(false)}
        className={`oc-table-img-modal-bg ${
          imgZoom && "oc-table-img-modal-open"
        }`}
      ></div>
      <div
        className={`oc-table-img-modal ${imgZoom && "oc-table-img-modal-open"}`}
      >
        <img src={imgSrc} alt="avatar" />
      </div>

      <Modal
        className="modal_main_component modal_background_content"
        show={openSelectedImgModal}
        onHide={closeSelectedImgDeleteModal}
        centered
      >
        <DeleteSelectedFrames
          selectedDeleteIds={selectedDeleteIds}
          setSelectedDeleteIds={setSelectedDeleteIds}
          setOpenSelectedImgModal={setOpenSelectedImgModal}
          closeSelectedImgDeleteModal={closeSelectedImgDeleteModal}
        />
      </Modal>
    </>
  );
};

export default FrameList;
