import React from "react";
import { closeIcon } from "../../icons";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";
import { coinIcon } from "../../icons";

const ViewReward = ({ closeViewModal, viewModalData }) => {
  return (
    <div className="modal_container">
      <div className="modal_heading">
        <h1 className="modal_main_heading">Reward Details</h1>
        <button className="modal_close_btn" onClick={closeViewModal}>
          {closeIcon}
        </button>
      </div>
      <div className="modal_detail_container">
        <div className="modal_detail_top_content">
          <div className="modal_detail_top_first_content">
            <img
              src={`${process.env.REACT_APP_FILE_BASE_URL}/${viewModalData.image}`}
              alt="reward"
            />
          </div>
        </div>
        <div className="modal_detail_container_grid only_detail_modal">
          <div className="modal_detail_content">
            <h2 className="modal_detail_content_heading">Day</h2>
            <p>{viewModalData.day}</p>
          </div>

          <div className="modal_detail_content">
            <h2 className="modal_detail_content_heading">Coins</h2>
            <p>
              {viewModalData.coins ? (
                <>
                  {viewModalData.coins} {coinIcon}
                </>
              ) : (
                "-"
              )}
            </p>
          </div>

          <div className="modal_detail_content">
            <h2 className="modal_detail_content_heading">Created At</h2>
            <p>
              {viewModalData.created_at
                ? moment
                    .utc(viewModalData.created_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A")
                : "-"}
            </p>
          </div>

          <div className="modal_detail_content">
            <h2 className="modal_detail_content_heading">Updated At</h2>
            <p>
              {viewModalData.updated_at
                ? moment
                    .utc(viewModalData.updated_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A")
                : "-"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewReward;
