import React from "react";
import { tableHeadItemsForService } from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";
import { Link } from "react-router-dom";
import { deleteIcon, editIcon, eyeIcon, plusColouredIcon } from "../../icons";

const ServiceList = ({
  services,
  showViewModalHandler,
  showEditModalHandler,
  showDeleteModalHandler,
  values,
  filterDate,
  page_number,
}) => {
  return (
    <>
      {services?.length === 0 || !services ? (
        <p className="no_data_found">No data Found</p>
      ) : (
        <table className="custom_data_table">
          <thead className="custom_data_table_head">
            <tr>
              {tableHeadItemsForService.map((ques, i) => {
                return (
                  <th className="custom_data_table_heading text-center" key={i}>
                    {ques.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="custom_data_table_body">
            {services?.map((service, i) => {
              return (
                <tr className="custom_data_table_row" key={service.id}>
                  <td className="custom_data_table_item table_item">
                    {service.user.name ? service.user.name : "-"}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {service.subject ? service.subject : "-"}
                  </td>

                  <td className={`custom_data_table_item table_item`}>
                    {service.user.email ? service.user.email : "-"}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {service.request_date
                      ? moment
                          .utc(service.request_date, "YYYYMMDD hh:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("DD/MM/YYYY, hh:mm A")
                      : "-"}
                    {/* {service.request_date
                      ? moment(service.request_date).format("DD/MM/YYYY")
                      : "-"} */}
                  </td>
                  <td className="custom_data_table_item table_item">
                    <span
                      className={`question_table_level_text ${
                        service.status === "Reopen"
                          ? "credited"
                          : service.status === "Pending"
                          ? "pending"
                          : service.status === "Resolved"
                          ? "resolved-service"
                          : service.status === "Closed"
                          ? "debited"
                          : "-"
                      }`}
                    >
                      {service.status
                        ? service.status === "Reopen"
                          ? "REOPEN"
                          : service.status === "Pending"
                          ? "PENDING"
                          : service.status === "Closed"
                          ? "CLOSED"
                          : service.status === "Resolved"
                          ? "RESOLVED"
                          : "-"
                        : "-"}
                    </span>
                  </td>
                  <td className="custom_data_table_item table_item">
                    {service.created_at
                      ? moment
                          .utc(service.created_at, "YYYYMMDD hh:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("DD/MM/YYYY, hh:mm A")
                      : "-"}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {service.updated_at
                      ? moment
                          .utc(service.updated_at, "YYYYMMDD hh:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("DD/MM/YYYY, hh:mm A")
                      : "-"}
                  </td>
                  <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                    <div className="custom_data_table_view_edit_btn_item_row">
                      <Link
                        className="custom_data_table_view_edit_item_btn "
                        aria-label="view"
                        onClick={() => showViewModalHandler(service.id)}
                      >
                        {eyeIcon}
                      </Link>
                      {service.status === "Closed" ? (
                        <>
                          <div style={{ marginLeft: "10px" }}></div>
                          <div></div>
                        </>
                      ) : (
                        <Link
                          aria-label="edit"
                          className="custom_data_table_view_edit_item_btn"
                          onClick={() => showEditModalHandler(service)}
                          state={{
                            value: values,
                            date: moment(filterDate).format("YYYY-MM-DD"),
                            page_number,
                          }}
                        >
                          {editIcon}
                        </Link>
                      )}
                      <button
                        aria-label="delete"
                        className="custom_data_table_view_edit_item_btn delete_btn"
                        onClick={() => showDeleteModalHandler(service.id)}
                      >
                        {deleteIcon}
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default ServiceList;
