import React, { useEffect, useRef, useState } from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import { useDispatch, useSelector } from "react-redux";
import { getQuizList, leaderBoardData } from "../../store/slice/quizSlice";
import Loader from "../../component/Common/Loader";
import Pagination from "../../component/Pagination/Pagination";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Form, Modal } from "react-bootstrap";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";
import { eyeIcon } from "../../icons";
import { tableHeadItemsForQuizList } from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import { getTopicList } from "../../store/slice/topicSlice";
import { getUserListForDropDown } from "../../store/slice/userSlice";
import LeaderBoard from "./LeaderBoard";
import Button from "../../component/Common/Button";

const Quiz = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef(false);
  const { page_number } = params;

  const value = location?.state?.value;
  const pageValue = location?.state?.value_page;

  const [pageN, setPageN] = useState(page_number - 1);
  const [filterData, setFilterData] = useState({
    level: value?.level ? value?.level : "",
    user: value?.user ? value?.user : "",
    topic: value?.topic ? value?.topic : "",
  });

  const quizList = useSelector((state) => state?.quiz?.quizList);
  const isLoading = useSelector((state) => state?.quiz?.loader);
  const totalCount = useSelector((state) => state?.quiz?.totalCount);
  const topicList = useSelector((state) => state.topic?.topicList);
  const dropDownUserList = useSelector((state) => state.user?.dropDownUserList);

  const leaderBoard = useSelector((state) => state?.quiz?.leaderBoard);

  const [openLeaderBoardModal, setOpenLeaderBoardModal] = useState(false);

  //USE-EFFECT FOR FILTER
  useEffect(() => {
    if (ref.current) {
      if (page_number !== 1) {
        navigate(`/quiz/page/1`);
      }
      dispatch(
        getQuizList({
          level: filterData.level,
          start: (page_number - 1) * 50,
          limits: 50,
          topic: filterData.topic === "" ? "" : Number(filterData.topic),
          user: filterData.user,
        })
      );
    }
  }, [filterData]);

  //USE-EFFECT FOR PAGINATION
  useEffect(() => {
    if (ref.current) {
      dispatch(
        getQuizList({
          start: (page_number - 1) * 50,
          limits: 50,
          level: filterData.level,
          topic: filterData.topic === "" ? "" : Number(filterData.topic),
          user: filterData.user,
        })
      );
    }
  }, [pageN]);

  //USE-EFFECT FOR INITIAL RENDERING
  useEffect(() => {
    dispatch(
      getQuizList({
        start: (page_number - 1) * 50,
        limits: 50,
        level: filterData.level,
        topic: filterData.topic === "" ? "" : Number(filterData.topic),
        user: filterData.user,
      })
    );
    dispatch(
      getTopicList({
        limits: 2000,
      })
    );
    dispatch(leaderBoardData());
    dispatch(getUserListForDropDown({ dropDown: true, limits: 200000 }));
    ref.current = true;
  }, []);

  //on-page change
  const onPageChange = (page_number) => {
    setPageN(page_number);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });
  };

  //CLOSE LEADER BOARD MODAL
  const closeLeaderModal = () => {
    setOpenLeaderBoardModal(false);
  };

  //OPEN LEADER BOARD MODAL
  const leaderBoardBtnHandler = () => {
    setOpenLeaderBoardModal(true);
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="GAMES" />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="creat_edit_project_btn_row_flex align-items-center">
              {quizList?.length > 0 && (
                <button
                  className="question_table_level_text leaderBoardBtn border-0"
                  onClick={leaderBoardBtnHandler}
                >
                  Leader board
                </button>
              )}

              <div></div>
              <div></div>

              {/* {quizList?.length > 0 && ( */}
              <div className="common_all_filter_row justify-content-end">
                <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      id="level"
                      name="level"
                      className="select_form"
                      value={filterData.level}
                      onChange={(e) => handleInputChange(e)}
                    >
                      <option value="" disabled>
                        Filter by Level...
                      </option>

                      <option value="">All</option>
                      <option value="EASY">Easy</option>
                      <option value="MEDIUM">Medium</option>
                      <option value="HARD">Hard</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      id="topic"
                      name="topic"
                      className="select_form filter_box"
                      value={filterData.topic}
                      onChange={(e) => handleInputChange(e)}
                    >
                      <option value="" disabled>
                        Filter by Topic...
                      </option>
                      <option value="">All</option>
                      {topicList &&
                        topicList?.length > 0 &&
                        topicList?.map((topic, i) => {
                          return (
                            <option key={i} value={topic?.id}>
                              {topic?.name}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </div>
                </div>
                <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      id="user"
                      name="user"
                      className="select_form filter_box"
                      value={filterData.user}
                      onChange={(e) => handleInputChange(e)}
                    >
                      <option value="" disabled>
                        Filter by UserName...
                      </option>
                      <option value="">All</option>
                      {dropDownUserList &&
                        dropDownUserList?.length > 0 &&
                        dropDownUserList?.map((user, i) => {
                          return (
                            <option key={i} value={user?.name}>
                              {user?.name}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </div>
                </div>
              </div>
              {/* // )} */}
            </div>

            <div className="custom_data_table_content">
              {quizList?.length === 0 || !quizList ? (
                <p className="no_data_found">No data Found</p>
              ) : (
                <table className="custom_data_table">
                  <thead className="custom_data_table_head">
                    <tr>
                      {tableHeadItemsForQuizList.map((ques, i) => {
                        return (
                          <th
                            className="custom_data_table_heading text-center"
                            key={i}
                          >
                            {ques.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="custom_data_table_body">
                    {quizList?.map((quiz, i) => {
                      return (
                        <tr className="custom_data_table_row" key={quiz.id}>
                          <td className="custom_data_table_item table_item">
                            {quiz.user ? quiz.user.name : "-"}
                          </td>

                          <td className="custom_data_table_item table_item">
                            {quiz.topic ? quiz.topic.name : "-"}
                          </td>

                          <td className="custom_data_table_item table_item">
                            <span
                              className={`question_table_level_text ${
                                quiz.level === "EASY"
                                  ? "credited"
                                  : quiz.level === "MEDIUM"
                                  ? "resolved"
                                  : quiz.level === "HARD"
                                  ? "debited"
                                  : quiz.level
                              }`}
                            >
                              {quiz.level ? quiz.level : "-"}
                            </span>
                          </td>

                          <td className="custom_data_table_item table_item">
                            {quiz.start_time ? (
                              <span className="question_table_level_text start-time start-end-time-label-width">
                                {moment
                                  .utc(quiz.start_time, ["hh:mm:ss"])
                                  // .clone()
                                  // .tz(defaultTimeZone)
                                  .format("hh:mm A")}
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>

                          <td className="custom_data_table_item table_item">
                            {quiz.end_time ? (
                              <span className="question_table_level_text end-time start-end-time-label-width">
                                {moment
                                  .utc(quiz.end_time, ["hh:mm:ss"])
                                  // .clone()
                                  // .tz(defaultTimeZone)
                                  .format("hh:mm A")}
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="custom_data_table_item table_item">
                            {quiz.total_answers ? (
                              <span className="question_table_level_text total_answers">
                                {quiz.total_answers}
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="custom_data_table_item table_item">
                            {quiz.correct_answers ? (
                              <span className="question_table_level_text_correct_answer credited">
                                {quiz.correct_answers}
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="custom_data_table_item table_item">
                            {quiz.user ? (
                              <span className="question_table_level_text resolved">
                                {quiz.score}
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="custom_data_table_item table_item">
                            {quiz.created_at
                              ? moment
                                  .utc(quiz.created_at, "YYYYMMDD HH:mm:ss")
                                  .clone()
                                  .tz(defaultTimeZone)
                                  .format("DD/MM/YYYY, hh:mm A")
                              : "-"}
                          </td>
                          <td className="custom_data_table_item table_item">
                            {quiz.updated_at
                              ? moment
                                  .utc(quiz.updated_at, "YYYYMMDD HH:mm:ss")
                                  .clone()
                                  .tz(defaultTimeZone)
                                  .format("DD/MM/YYYY, hh:mm A")
                              : "-"}
                          </td>
                          <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                            <div className="custom_data_table_view_edit_btn_item_row">
                              <Link
                                className="custom_data_table_view_edit_item_btn "
                                aria-label="view"
                                to={`/quiz/view/${quiz.id}`}
                                state={{
                                  quizId: quiz.id,
                                  value: {
                                    level: filterData.level,
                                    user: filterData.user,
                                    topic: filterData.topic,
                                  },
                                  page_number,
                                }}
                              >
                                {eyeIcon}
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>

            <Pagination
              totalRecords={totalCount}
              onPageChange={onPageChange}
              limits={50}
            />
          </>
        )}
      </div>

      <Modal
        className="modal_main_component modal_background_content quiz-winner-modal"
        dialogClassName="custom-dialog"
        show={openLeaderBoardModal}
        onHide={closeLeaderModal}
        centered
      >
        <LeaderBoard
          leaderBoard={leaderBoard}
          closeLeaderModal={closeLeaderModal}
        />
      </Modal>
    </>
  );
};

export default Quiz;

// quiz page
