import { useSelector, useDispatch } from "react-redux";
import { useRoutes, useNavigate } from "react-router-dom";
import getRoutes from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./css/style.css";
import { toast } from "react-toastify";
import { checkAuth } from "./helpers/checkAuth";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

toast.configure();
function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user.user);
  // console.log("user", user);

  const [err, setErr] = useState(localStorage.getItem("error"));

  useEffect(() => {
    user && checkAuth(dispatch, setErr, navigate);
  }, [dispatch, err, navigate]);

  const routing = useRoutes(getRoutes(user));

  return <>{routing}</>;
}

export default App;
