import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRewardList } from "../../store/slice/rewardSlice";
import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import { Link, useParams } from "react-router-dom";
import { routes } from "../../constants";
import RewardList from "./RewardList";
import { Modal } from "react-bootstrap";
import ViewReward from "./ViewReward";
import DeleteReward from "./DeleteReward";
import Pagination from "../../component/Pagination/Pagination";

const Reward = () => {
  const dispatch = useDispatch();
  const ref = useRef(false);
  const params = useParams();
  const { page_number } = params;

  const [openViewModal, setOpenViewModal] = useState(false);
  const [viewModalData, setViewModalData] = useState("");

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState("");

  const [pageN, setPageN] = useState(page_number - 1);

  const list = useSelector((state) => state.reward.rewardList);
  const isLoading = useSelector((state) => state.reward.loader);
  const totalCount = useSelector((state) => state.reward.totalCount);

  useEffect(() => {
    if (ref.current) {
      dispatch(getRewardList({ start: (page_number - 1) * 10, limits: 10 }));
    }
  }, [pageN]);

  useEffect(() => {
    dispatch(getRewardList({ start: (page_number - 1) * 10, limits: 10 }));
    ref.current = true;
  }, []);

  //VIEW ICON HANDLER
  const showViewModalHandler = (item) => {
    setViewModalData(item);
    setOpenViewModal(true);
  };

  //VIEW MODAL CLOSE HANDLER
  const closeViewModal = () => {
    setOpenViewModal(false);
    setViewModalData("");
  };

  //EDIT ICON HANDLER
  const showEditModalHandler = (item) => {};

  //DELETE ICON HANDLER
  const showDeleteModalHandler = (id) => {
    setDeleteModalData(id);
    setOpenDeleteModal(true);
  };

  //CLOSE DELETE MODAL
  const closeDeleteModal = () => {
    setDeleteModalData("");
    setOpenDeleteModal(false);
  };

  //PAGE-CHANGE HANDLER
  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="DAILY REWARD" />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="creat_edit_project_btn_row_flex">
              <div>
                <Link to={routes.createReward} className="comman_btn ml-auto">
                  Create
                </Link>
              </div>

              {/* FILTER PART */}
              {/* <div className="common_filter_input_box first_filter_container">
                <div className="common_select_box">
                  <Form.Select
                    id="level"
                    name="level"
                    className="select_form"
                    defaultValue={"DEFAULT"}
                    onChange={(e) => handleInputChange(e, "level")}
                  >
                    <option value="DEFAULT" disabled>
                      Filter by Level
                    </option>
                    <option value="">All</option>
                    <option value="EASY">Easy</option>
                    <option value="MEDIUM">Medium</option>
                    <option value="HARD">Hard</option>
                  </Form.Select>
                </div>
              </div> */}
            </div>

            <div className="custom_data_table_content">
              <RewardList
                list={list}
                showViewModalHandler={showViewModalHandler}
                showEditModalHandler={showEditModalHandler}
                showDeleteModalHandler={showDeleteModalHandler}
              />
            </div>
            {totalCount > 0 && (
              <Pagination
                totalRecords={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </>
        )}
      </div>
      <Modal
        className="modal_main_component modal_background_content"
        dialogClassName="custom-dialog"
        show={openViewModal}
        onHide={closeViewModal}
        centered
      >
        <ViewReward
          closeViewModal={closeViewModal}
          viewModalData={viewModalData}
        />
      </Modal>

      <Modal
        className="modal_main_component modal_background_content modal_max_w_500"
        dialogClassName="custom-dialog"
        show={openDeleteModal}
        onHide={closeDeleteModal}
        centered
      >
        <DeleteReward
          closeDeleteModal={closeDeleteModal}
          deleteModalData={deleteModalData}
          setOpenDeleteModal={setOpenDeleteModal}
          setDeleteModalData={setDeleteModalData}
          totalCount={totalCount.toString()}
          pageNumber={page_number}
        />
      </Modal>
    </>
  );
};

export default Reward;
