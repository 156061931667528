import React from "react";
import { closeIcon } from "../../icons";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";

const ViewCorruptQuestion = (props) => {
  const { viewModalData, closeViewModal } = props;
  const details = viewModalData;

  return (
    <div className="modal_container">
      <div className="modal_heading">
        <h1 className="modal_main_heading">VIEW QUESTION</h1>
        <button className="modal_close_btn" onClick={() => closeViewModal()}>
          {closeIcon}
        </button>
      </div>
      <div className="project_edit_main_content px-0">
        <div className="create_from_row">
          {details?.topic && (
            <div>
              <div className="project_view_list_item">Topic</div>
              <p>{details.topic}</p>
            </div>
          )}
          {details?.level && (
            <div>
              <div className="project_view_list_item">Level</div>
              <p
                className={`question_table_level_text
                ${
                  details.level === "EASY"
                    ? "credited"
                    : details.level === "HARD"
                    ? "debited"
                    : details.level === "MEDIUM"
                    ? "resolved"
                    : "-"
                }`}
              >
                {details.level}
              </p>
            </div>
          )}
          {details?.created_at && (
            <div>
              <div className="project_view_list_item">Created At</div>
              <p>
                {moment
                  .utc(details.created_at, "YYYYMMDD, hh:mm:ss")
                  .clone()
                  .tz(defaultTimeZone)
                  .format("DD/MM/YYYY, hh:mm A")}
              </p>
            </div>
          )}
          {/* {details?.updated_at && (
            <div>
              <div className="project_view_list_item">Updated At</div>
              <p>
                {moment
                  .utc(details.updated_at, "YYYYMMDD, hh:mm:ss")
                  .clone()
                  .tz(defaultTimeZone)
                  .format("DD/MM/YYYY, hh:mm A")}
              </p>
            </div>
          )} */}
        </div>

        <div className="question_options_space">
          <div className="project_view_list_item">
            <div className="project_view_list_item_question">Question❓</div>
            <p>{details?.question}</p>
          </div>

          <div className="project_view_list_item">
            <div className="project_view_list_item_body">
              <div className="project_view_list_item_option_answer_row">
                {details?.options?.map((option, i) => {
                  return (
                    <div
                      className={`project_view_list_item_option_answer_col ${
                        option === details.answer &&
                        "project_view_list_item_option_correct_answer"
                      }`}
                      key={i}
                    >
                      <p className="project_view_list_item_option_answer_col_text">
                        {option}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCorruptQuestion;
