import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios, { authHeaders } from "../../helpers/axios";
import { routes } from "../../constants";

export const createQuestion = createAsyncThunk(
  "createQuestion",
  async (data) => {
    try {
      const response = await Axios.post(
        `/question/list`,
        data.data,
        authHeaders()
      );
      if (response.status === 201) {
        toast.success(data.toast, { autoClose: 3000 });
        // data.navigate(`${routes.question}/page/1`, {
        //   state: { details: data.data },
        // });
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

export const createSelectedQuestions = createAsyncThunk(
  "createSelectedQuestions",
  async (data) => {
    try {
      const response = await Axios.post(`/question`, data.data, authHeaders());
      if (response.status === 201) {
        toast.success(data.toast, { autoClose: 3000 });
        data.navigate(`${routes.question}/page/1`);
      }
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

export const updateQuestions = createAsyncThunk(
  "updateQuestions",
  async (data) => {
    try {
      const response = await Axios.put(`/question`, data.data, authHeaders());
      if (response.status === 201 && !data.cb) {
        toast.success(data.toast, { autoClose: 3000 });
        data.navigate(`${routes.question}/page/1`);
        return response.data;
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

export const getQuestions = createAsyncThunk(
  "getQuestionsById",
  async (data) => {
    try {
      const response = await Axios.get(
        `/question?topic_id=${data.topic || ""}&level=${
          data.level || ""
        }&start=${data.start || 0}&limits=${data.limits || 10}&order=${
          data.order || ""
        }`,
        authHeaders()
      );
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

const questionSlice = createSlice({
  name: "question",
  initialState: {
    createQuestionList: [],
    addCreatedQuestion: "",
    updateQuestion: "",
    getQues: [],
    loader: false,
    error: "",
    totalCount: 0,
  },
  reducer: {},
  extraReducers: (builder) => {
    //CREATE QUESTION
    builder.addCase(createQuestion.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createQuestion.fulfilled, (state, action) => {
      state.loader = false;
      state.createQuestionList = action.payload;
    });
    builder.addCase(createQuestion.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //CREATE===> ADD CREATED QUESTIONS
    builder.addCase(createSelectedQuestions.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createSelectedQuestions.fulfilled, (state, action) => {
      state.loader = false;
      state.addCreatedQuestion = action.payload;
    });
    builder.addCase(createSelectedQuestions.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //GET QUESTION
    builder.addCase(getQuestions.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getQuestions.fulfilled, (state, action) => {
      state.loader = false;
      state.getQues = action.payload.rows;
      state.totalCount = action.payload.count;
    });
    builder.addCase(getQuestions.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //UPDATE QUESTION
    builder.addCase(updateQuestions.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateQuestions.fulfilled, (state, action) => {
      state.loader = false;
      state.updateQuestion = action.payload;
    });
    builder.addCase(updateQuestions.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default questionSlice.reducer;
