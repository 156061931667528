import React, { useState } from "react";
import Input from "../../component/Common/Input";
import Papa from "papaparse";
import { addIcon, infoIcon } from "../../icons";
import { CSVLink } from "react-csv";
import { staticCsvData } from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import * as XLSX from "xlsx";

// const staticCsvData = [
// const data = [
//   { name: "John Doe", age: 30, email: "john@example.com" },
//   { name: "Jane Smith", age: 25, email: "jane@example.com" },
// ];

const CsvQuestionAnswer = ({ csvData, setCsvData, setParsingComplete }) => {
  // const allowedExtensions = ["csv"];
  // const allowedExtensions = ["csv", "xlsx"];
  const allowedExtensions = ["xlsx"];
  // const [data, setData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState("");
  const [error, setError] = useState("");

  // const handleImportQuestions = (e) => {
  //   setError("");
  //   if (e.target.files.length) {
  //     console.log(e.target.files);
  //     setFileName(e.target.files[0].name);
  //     const inputFile = e.target.files[0];
  //     console.log("inputFile", inputFile);
  //     const fileExtension = inputFile?.type.split("/")[1];
  //     console.log("fileExtension", fileExtension);
  //     if (!allowedExtensions.includes(fileExtension)) {
  //       setError("Please select a valid csv file");
  //       return;
  //     }
  //     // If input type is correct set the state
  //     setFile(inputFile);
  //   }
  // };
  // const handleParse = () => {
  //   // If user clicks the parse button without
  //   // a file we show a error
  //   console.log("file", file);
  //   if (!file || error)
  //     return setError("Please select a valid csv file before parse");
  //   // Initialize a reader which allows user
  //   // to read any file or blob.
  //   const reader = new FileReader();
  //   // Event listener on reader when the file
  //   // loads, we parse it and set the data.
  //   reader.onload = async ({ target }) => {
  //     const csv = Papa.parse(target.result, { header: true });
  //     const parsedData = csv?.data;
  //     console.log("parsedData", parsedData);
  //     const formatQues = parsedData
  //       ?.map((item) => ({
  //         // id: item.id,
  //         question: item.question,
  //         answer: item.answer,
  //         options: [
  //           item.optionA,
  //           item.optionB,
  //           item.optionC,
  //           item.optionD,
  //         ].filter(Boolean),
  //       }))
  //       .filter((item) => item.question !== "" && item.answer !== undefined);
  //     console.log("formatQues", formatQues);
  //     setCsvData(formatQues);
  //     setFileName("");
  //     setParsingComplete(true);
  //     // console.log("parsedData", formatQues);
  //   };
  //   reader.readAsText(file);
  // };

  // let word =
  //   "Which of the following term originates from the Greek language that means “having multiple forms,” defines OOP’s ability to redefine, a class’s characteristics?";

  // console.log("====curly quotes====>", word);

  // const handleFileSelection = (inputFile) => {
  //   setError("");
  //   if (!inputFile) {
  //     setError("Please select a valid csv file");
  //     return;
  //   }

  //   // const fileExtension = inputFile.type.split("/")[1];
  //   // if (!allowedExtensions.includes(fileExtension)) {
  //   //   setError("Please select a valid csv file");
  //   //   return;
  //   // }

  //   const fileExtension = inputFile.name.split(".").pop();
  //   if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
  //     setError("Please select a valid CSV or XLSX file");
  //     return;
  //   }

  //   setFileName(inputFile.name);

  //   const reader = new FileReader();
  //   reader.onload = async ({ target }) => {
  //     if (fileExtension.toLowerCase() === "csv") {
  //       const csv = Papa.parse(target.result, { header: true });
  //       const parsedData = csv?.data;
  //       console.log("parsedDatacsv", parsedData);
  //       const formatQues = parsedData
  //         ?.map((item) => ({
  //           question: item.question && item.question.replace(/\n/g, "").trim(),
  //           answer: item.answer && item.answer.replace(/\n/g, "").trim(),
  //           options: [
  //             item.optionA && item.optionA.replace(/\n/g, "").trim(),
  //             item.optionB && item.optionB.replace(/\n/g, "").trim(),
  //             item.optionC && item.optionC.replace(/\n/g, "").trim(),
  //             item.optionD && item.optionD.replace(/\n/g, "").trim(),
  //           ].filter(Boolean),
  //         }))
  //         .filter((item) => item.question !== "" && item.answer !== undefined);
  //       setCsvData(formatQues);
  //       console.log("formatQuescsv", formatQues);
  //       setFileName("");
  //       setParsingComplete(true);
  //     } else if (fileExtension.toLowerCase() === "xlsx") {
  //       const workbook = XLSX.read(target.result, { type: "binary" });
  //       console.log("workbook", workbook);
  //       const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  //       console.log("worksheet", worksheet);
  //       const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  //       console.log("parsedDataxlsx", parsedData);

  //       // const formatQues = parsedData
  //       //   ?.slice(1) // Exclude the first row with headers
  //       //   ?.map((row) => {
  //       //     const obj = {
  //       //       question: (row[0] || "")
  //       //         .replace(/^\n+|\n+$/g, "")
  //       //         .replace(/\n+/g, "")
  //       //         .replace(/\\/g, "")
  //       //         .trim(),
  //       //       answer: (typeof row[1] === "string" ? row[1] : "")
  //       //         .trim()
  //       //         .replace(/^\n+|\n+$/g, "")
  //       //         .replace(/\n+/g, "")
  //       //         .replace(/\\/g, "")
  //       //         .trim(),
  //       //       options: row
  //       //         .slice(2, 6)
  //       //         .filter((option) => option !== "") // Filter out empty options
  //       //         .map((option) =>
  //       //           (typeof option === "string" ? option : "").trim()
  //       //         ), // Trim options
  //       //     };

  //       //     return obj;
  //       //   });

  //       // // Remove empty options arrays
  //       // const filteredFormatQues = formatQues?.filter(
  //       //   (item) =>
  //       //     item.question !== "" &&
  //       //     item.answer !== "" &&
  //       //     item.options.length > 0
  //       // );

  //       const formatQues = parsedData
  //         ?.slice(1) // Exclude the first row with headers
  //         ?.map((row) => {
  //           const obj = {
  //             question: (row[0] || "")
  //               .replace(/^\n+|\n+$/g, "")
  //               .replace(/\n+/g, "")
  //               .replace(/\\/g, "")
  //               .replace(/\s+/g, " ")
  //               .trim(),
  //             // answer: row[1] !== undefined ? row[1] : "", // Don't convert to string
  //             answer:
  //               row[1] !== undefined
  //                 ? typeof row[1] === "string"
  //                   ? row[1].replace(/\s+/g, " ").replace(/\.$/, "").trim()
  //                   : row[1]
  //                 : "",
  //             options: row
  //               .slice(2, 6)
  //               .filter((option) => option !== "")
  //               .map((option) => {
  //                 if (typeof option === "string") {
  //                   return option
  //                     .replace(/\\n/g, "")
  //                     .replace(/\.$/, "")
  //                     .replace(/\s+/g, " ")
  //                     .trim(); // Remove "\n" line breaks and leading/trailing spaces from string options
  //                 } else {
  //                   return option; // Leave non-string options as is
  //                 }
  //               }),
  //           }; // Filter out empty options

  //           return obj;
  //         });

  //       // Remove items with empty questions and no answer or options
  //       const filteredFormatQues = formatQues?.filter(
  //         (item) =>
  //           item.question !== "" &&
  //           (item.answer !== "" || item.options.length > 0)
  //       );

  //       setCsvData(filteredFormatQues);
  //       // setCsvData(formatQues);
  //       console.log("formatQuesxlsx", formatQues);
  //       setFileName("");
  //       setParsingComplete(true);
  //     }
  //   };

  //   if (fileExtension.toLowerCase() === "csv") {
  //     reader.readAsText(inputFile);
  //   } else if (fileExtension.toLowerCase() === "xlsx") {
  //     reader.readAsBinaryString(inputFile);
  //   }
  //   // reader.readAsText(inputFile);
  // };

  const handleFileSelection = (inputFile) => {
    setError("");
    if (!inputFile) {
      setError("Please select a valid XLSX file");
      return;
    }

    const fileExtension = inputFile.name.split(".").pop();
    if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
      setError("Please select a valid XLSX file");
      return;
    }

    setFileName(inputFile.name);

    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const workbook = XLSX.read(target.result, { type: "binary" });
      // console.log("workbook", workbook);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      // console.log("worksheet", worksheet);
      const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      // console.log("parsedDataxlsx", parsedData);

      // const formatQues = parsedData
      //   ?.slice(1) // Exclude the first row with headers
      //   ?.map((row) => {
      //     const obj = {
      //       question: (row[0] || "")
      //         .replace(/^\n+|\n+$/g, "")
      //         .replace(/\n+/g, "")
      //         .replace(/\\/g, "")
      //         .replace(/\s+/g, " ")
      //         .trim(),
      //       answer:
      //         row[1] !== undefined
      //           ? typeof row[1] === "string"
      //             ? row[1].replace(/\s+/g, " ").replace(/\.$/, "").trim()
      //             : row[1]
      //           : "",
      //       options: row
      //         .slice(2, 6)
      //         .filter((option) => option !== "")
      //         .map((option) => {
      //           if (typeof option === "string") {
      //             return option
      //               .replace(/\\n/g, "")
      //               .replace(/\.$/, "")
      //               .replace(/\s+/g, " ")
      //               .trim();
      //           } else {
      //             return option;
      //           }
      //         }),
      //     };

      //     return obj;
      //   });

      const formatQues = parsedData
        ?.slice(1) // Exclude the first row with headers
        ?.map((row) => {
          const obj = {
            question: (row[0] || "")
              .replace(/^\n+|\n+$/g, "")
              .replace(/\n+/g, "")
              .replace(/\\/g, "")
              .replace(/\s+/g, " ")
              .trim(),

            options: row
              .slice(1, 5)
              .filter((option) => option !== "")
              .map((option) => {
                if (typeof option === "string") {
                  return option
                    .replace(/\\n/g, "")
                    .replace(/\.$/, "")
                    .replace(/\s+/g, " ")
                    .trim();
                } else {
                  return option;
                }
              }),
            answer:
              row[5] !== undefined
                ? typeof row[5] === "string"
                  ? row[5].replace(/\s+/g, " ").replace(/\.$/, "").trim()
                  : row[5]
                : "",
          };

          return obj;
        });

      const filteredFormatQues = formatQues?.filter(
        (item) =>
          item.question !== "" &&
          (item.answer !== "" || item.options.length > 0)
      );

      setCsvData(filteredFormatQues);
      // console.log("formatQuesxlsx", formatQues);
      setFileName("");
      setParsingComplete(true);
    };

    reader.readAsBinaryString(inputFile);
  };

  const handleImportQuestions = (e) => {
    const inputFile = e.target.files[0];
    handleFileSelection(inputFile);
  };

  //DOWNLOAD SAMPLE FILE
  const downloadXLSXFile = () => {
    const ws = XLSX.utils.json_to_sheet(staticCsvData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "static_data.xlsx");
  };

  return (
    <div>
      <h2 className="featured_project_heading" htmlFor="csvInput">
        {/* Upload CSV File */}
        Upload xlsx File
      </h2>
      <div className="choose-file-input-text-box">
        <div className="choose-file-input-download-btn">
          <Input
            //   className="project_edit_detail_column_content amenieties_file_select_content csv_file_select"
            //   labelClassName="select_amenieties_file_btn"
            inputClassName=""
            errorClassName="err_text"
            type="file"
            label="Choose File"
            id="csvInput"
            name="file"
            //new line added
            accept=".xlsx"
            // onChange={handleImportQuestions}
            onChange={handleImportQuestions}
            labelOtherProps={addIcon}
          />
          <div className="choose-file-download-btn">
            {/* <CSVLink data={staticCsvData} filename={"static_data.xlsx"}>
            {/* Download Sample xlsx File */}
            {/* </CSVLink> */}
            <button onClick={downloadXLSXFile}>
              Download Sample xlsx File
            </button>
            <div className="choose-file-download-btn-info-btn">
              {infoIcon}
              <span className="choose-file-download-btn-info-tooltip">
                All Headers example: (Questions, optionA, optionB, optionC,
                optionD, answer) and spellings should be same.
              </span>
            </div>
          </div>
        </div>
        {fileName && <span className="choose-file-input-text">{fileName}</span>}
        {error && <span className="error_text csv_file_name">{error}</span>}
      </div>
      {/* <div className="flex question-answer-parse-btn">
        <button className="comman_btn parse_csv_btn ml-0" onClick={handleParse}>
          Parse
        </button>
        <div className="question-answer-parse-btn-info-btn">
          {infoIcon}
          <span className="question-answer-parse-btn-info-tooltip">
            Before clicking on Add button click on parse.
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default CsvQuestionAnswer;
