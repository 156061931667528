import React from "react";
import { useDispatch } from "react-redux";
import { getFrames, updateFrames } from "../../store/slice/frameSlice";
import { toast } from "react-toastify";

const DeleteSelectedFrames = ({
  selectedDeleteIds,
  setSelectedDeleteIds,
  setOpenSelectedImgModal,
  closeSelectedImgDeleteModal,
}) => {
  const dispatch = useDispatch();

  //DELETE SELECTED FRAME HANDLER
  const deleteSelectedFrameHandler = (deleteIds) => {
    const data = {
      delete_status: true,
      id: deleteIds,
    };
    dispatch(
      updateFrames({
        data,
        cb: (err, res) => {
          if (err) {
            // console.log("err", err);
          } else {
            setOpenSelectedImgModal(false);
            setSelectedDeleteIds([]);
            toast.error("Selected Frames deleted successfully", {
              autoClose: 3000,
            });
            dispatch(getFrames({}));
          }
        },
      })
    );
  };

  return (
    <div className="modal_container">
      <div className="modal_heading_2">
        <h1 className="modal_main_heading_with_desc">Are you sure?</h1>
        <p>you want to delete selected Avatars!</p>
      </div>
      <div className="modal_btn_row">
        <button
          onClick={() => closeSelectedImgDeleteModal()}
          className="modal_btn modal_cancel_btn"
        >
          Cancel
        </button>
        <button
          className="modal_btn modal_delete_btn"
          onClick={() => deleteSelectedFrameHandler(selectedDeleteIds)}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteSelectedFrames;
