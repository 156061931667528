import React, { useEffect, useRef } from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import { backBtnArrow, eyeIcon } from "../../icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getQuizListByUserId } from "../../store/slice/quizSlice";
import Loader from "../../component/Common/Loader";
import {
  tableHeadItemsForUserList,
  tableHeadItemsForUserQuizData,
} from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";
import { Form } from "react-bootstrap";
import { useForm } from "../../hooks/useForm";
import { getTopicList } from "../../store/slice/topicSlice";

const ViewUserQuizDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const ref = useRef(false);

  const userId = location?.state?.quizDetailUserId;

  const isLoading = useSelector((state) => state?.quiz?.loader);
  const quizDetails = useSelector((state) => state?.quiz?.quizListByUserId);
  const topicList = useSelector((state) => state.topic?.topicList);

  const initialFValues = {
    level: "",
    topic: "",
  };

  //USE FORM HOOK
  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  useEffect(() => {
    if (ref.current) {
      dispatch(
        getQuizListByUserId({
          user_id: userId,
          level: values.level === "" ? "" : values.level,
          topic: values.topic === "" ? "" : Number(values.topic),
          limits: 50,
        })
      );
    }
  }, [values.level, values.topic]);

  useEffect(() => {
    dispatch(
      getQuizListByUserId({
        user_id: userId,
        level: values.level === "" ? "" : values.level,
        topic: values.topic === "" ? "" : Number(values.topic),
        limits: 50,
      })
    );
    dispatch(
      getTopicList({
        limits: 2000,
      })
    );
    ref.current = true;
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="project_edit_main_content">
          <CommonHeading heading="QUIZ" />
          <>
            <div className="creat_edit_project_btn_row_flex">
              <div>
                <button
                  className="go_back_btn table_back_btn"
                  onClick={() => navigate(-1)}
                >
                  {backBtnArrow} Back
                </button>
              </div>
              <div className="common_all_filter_row">
                <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      id="level"
                      name="level"
                      className="select_form"
                      value={values?.level}
                      onChange={(e) => handleInputChange(e, "level")}
                    >
                      <option value="" disabled>
                        Filter by Level
                      </option>
                      <option value="">All</option>
                      <option value="EASY">Easy</option>
                      <option value="MEDIUM">Medium</option>
                      <option value="HARD">Hard</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      id="topic"
                      name="topic"
                      className="select_form"
                      value={values?.topic}
                      onChange={(e) => handleInputChange(e, "topic")}
                    >
                      <option value="" disabled>
                        Filter by Topic
                      </option>
                      <option value="">All</option>
                      {topicList &&
                        topicList?.length > 0 &&
                        topicList?.map((topic, i) => {
                          return (
                            <option key={i} value={topic.id}>
                              {topic?.name}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </div>
                </div>
              </div>
            </div>

            <div className="custom_data_table_content">
              {quizDetails?.length === 0 || !quizDetails ? (
                <p className="no_data_found">No data Found</p>
              ) : (
                <table className="custom_data_table">
                  <thead className="custom_data_table_head">
                    <tr>
                      {tableHeadItemsForUserQuizData.map((ques, i) => {
                        return (
                          <th
                            className="custom_data_table_heading text-center"
                            key={i}
                          >
                            {ques.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="custom_data_table_body">
                    {quizDetails &&
                      quizDetails?.length > 0 &&
                      quizDetails?.map((quiz, i) => {
                        return (
                          <tr className="custom_data_table_row" key={quiz.id}>
                            <td className="custom_data_table_item table_item">
                              {quiz?.topic?.name}
                            </td>
                            <td className="custom_data_table_item table_item">
                              <span
                                className={`question_table_level_text ${
                                  quiz.level === "EASY"
                                    ? "credited"
                                    : quiz.level === "HARD"
                                    ? "debited"
                                    : quiz.level === "MEDIUM"
                                    ? "resolved"
                                    : "-"
                                }`}
                              >
                                {quiz.level ? quiz.level : "-"}
                              </span>
                            </td>
                            <td className="custom_data_table_item table_item">
                              {quiz.start_time ? (
                                <span className="question_table_level_text start-time">
                                  {moment
                                    .utc(quiz.start_time, ["hh:mm:ss"])
                                    .format("hh:mm A")}
                                </span>
                              ) : (
                                "-"
                              )}
                            </td>

                            <td className="custom_data_table_item table_item">
                              {quiz.end_time ? (
                                <span className="question_table_level_text end-time">
                                  {moment
                                    .utc(quiz.end_time, ["hh:mm:ss"])
                                    .format("hh:mm A")}
                                </span>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td className="custom_data_table_item table_item">
                              {quiz.total_answers && quiz.total_answers}
                            </td>
                            <td className="custom_data_table_item table_item">
                              <span className="question_table_level_text_correct_answer credited">
                                {quiz.correct_answers && quiz.correct_answers}
                              </span>
                            </td>
                            <td className="custom_data_table_item table_item">
                              {quiz.created_at &&
                                moment
                                  .utc(quiz.created_at, "YYYYMMDD HH:mm:ss")
                                  .clone()
                                  .tz(defaultTimeZone)
                                  .format("DD/MM/YYYY, hh:mm A")}
                            </td>
                            <td className="custom_data_table_item table_item">
                              {quiz.updated_at &&
                                moment
                                  .utc(quiz.updated_at, "YYYYMMDD HH:mm:ss")
                                  .clone()
                                  .tz(defaultTimeZone)
                                  .format("DD/MM/YYYY, hh:mm A")}
                            </td>
                            <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                              <div className="custom_data_table_view_edit_btn_item_row">
                                <Link
                                  className="custom_data_table_view_edit_item_btn "
                                  aria-label="view"
                                  to={`/view-user/details/ques/${quiz.id}`}
                                  state={{ quizId: quiz.id }}
                                >
                                  {eyeIcon}
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default ViewUserQuizDetail;
