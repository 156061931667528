import React, { useEffect, useState } from "react";
import { getFrames } from "../../store/slice/frameSlice";
import { useDispatch, useSelector } from "react-redux";
import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import FrameList from "./FrameList";
import { Modal } from "react-bootstrap";
import DeleteFrame from "./DeleteFrame";
import AddFrameModal from "./AddFrameModal";

const Frame = () => {
  const dispatch = useDispatch();

  const frameList = useSelector((state) => state?.frame?.frameImgList);
  const isLoading = useSelector((state) => state?.frame?.loader);

  const [frameId, setFrameId] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [openAddImgModal, setOpenAddImgModal] = useState(false);
  const [imgErrors, setImgErrors] = useState("");

  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    dispatch(getFrames({}));
  }, []);

  //DELETE ICON HANDLER
  const deleteIconHandler = (id, type) => {
    setFrameId(id);
    setOpenDeleteModal(true);
  };

  //CLOSE DELETE HANDLER
  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setFrameId([]);
  };

  //SHOW ADD FRAME MODAL
  const showAddFrameModal = () => {
    setOpenAddImgModal(true);
  };

  //CLOSE ADD FRAME MODAL
  const closeFrameImgModal = () => {
    setOpenAddImgModal(false);
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="FRAME" />
        {isLoading || uploading ? (
          <Loader />
        ) : (
          <FrameList
            frameList={frameList}
            deleteIconHandler={deleteIconHandler}
            showAddFrameModal={showAddFrameModal}
          />
        )}
      </div>

      <Modal
        className="modal_main_component modal_background_content modal_add_img_content"
        show={openAddImgModal}
        onHide={closeFrameImgModal}
        centered
      >
        <AddFrameModal
          closeFrameImgModal={closeFrameImgModal}
          imgErrors={imgErrors}
          setImgErrors={setImgErrors}
          setOpenAddImgModal={setOpenAddImgModal}
          setUploading={setUploading}
        />
      </Modal>

      <Modal
        className="modal_main_component modal_background_content"
        show={openDeleteModal}
        onHide={closeDeleteModal}
        centered
      >
        <DeleteFrame
          frameId={frameId}
          setFrameId={setFrameId}
          setOpenDeleteModal={setOpenDeleteModal}
          closeDeleteModal={closeDeleteModal}
        />
      </Modal>
    </>
  );
};

export default Frame;
