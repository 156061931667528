import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getFrames, updateFrames } from "../../store/slice/frameSlice";

const DeleteFrame = ({
  frameId,
  setFrameId,
  setOpenDeleteModal,
  closeDeleteModal,
}) => {
  const dispatch = useDispatch();

  //DELETE FRAME HANDLER
  const deleteFrameHandler = (frameId) => {
    const data = {
      delete_status: true,
      id: frameId,
    };

    // console.log("data", data);
    dispatch(
      updateFrames({
        data,
        cb: (err, res) => {
          if (err) {
            // console.log("error", err);
          } else {
            setOpenDeleteModal(false);
            setFrameId([]);
            toast.error("Frame deleted successfully", { autoClose: 3000 });
            dispatch(getFrames({}));
          }
        },
      })
    );
  };

  return (
    <div className="modal_container">
      <div className="modal_heading_2">
        <h1 className="modal_main_heading_with_desc">Are you sure?</h1>
        <p>you want to delete this Avatar!</p>
      </div>
      <div className="modal_btn_row">
        <button
          onClick={() => closeDeleteModal()}
          className="modal_btn modal_cancel_btn"
        >
          Cancel
        </button>
        <button
          className="modal_btn modal_delete_btn"
          onClick={() => deleteFrameHandler(frameId)}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteFrame;
