import React, { useEffect, useRef, useState } from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import {
  backBtnArrow,
  minusWithOutCircleIcon,
  plusWithOutCircleIcon,
} from "../../icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTopicList } from "../../store/slice/topicSlice";
import { useForm } from "../../hooks/useForm";
import { Form, Modal } from "react-bootstrap";
import Button from "../../component/Common/Button";
import { createSelectedQuestions } from "../../store/slice/questionSlice";
import DeleteQuestionInputFields from "./DeleteQuestionInputFields";
import Input from "../../component/Common/Input";
// import Input from "../../component/Common/Input";

const AddQuestion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef(false);
  const [inputFields, setInputFields] = useState([
    {
      question: "",
      options: ["", "", "", ""],
      answer: "",
    },
  ]);
  const [validationError, setValidationError] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [passQuestionIndexToRemove, setPassQuestionIndexToRemove] =
    useState("");

  //API call of get topic list
  useEffect(() => {
    dispatch(getTopicList({ start: 0, limits: 100000000 }));
    ref.current = true;
  }, []);

  const topic = useSelector((state) => state?.topic?.topicList);
  const topicOptions = topic?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const initialFValues = {
    topicId: "",
    levelValue: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("topicId" in fieldValues) {
      temp.topicId = fieldValues.topicId ? "" : "Please Select Topic";
    }
    if ("levelValue" in fieldValues) {
      temp.levelValue = fieldValues.levelValue ? "" : "Please Select Level";
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  //USE FORM HOOK
  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  //HANDLE ON-CHANGE OF QUESTION AND OPTION
  const handleInputChangeQuesOpt = (e, questionIndex, optionIndex) => {
    const { name, value } = e.target;
    const list = [...inputFields];
    if (name === "question") {
      list[questionIndex].question = value;
    } else if (name === "answer") list[questionIndex].answer = value;
    else {
      list[questionIndex].options[optionIndex] = value;
    }

    setInputFields(list);
    setValidationError("");
  };

  //ADD Question Handler
  const addQuestionHandler = () => {
    const list = [...inputFields];
    setInputFields([
      ...list,
      { question: "", options: ["", "", "", ""], answer: "" },
    ]);
  };

  //REMOVE Question Handler
  const removeQuestionHandler = (questionIndex) => {
    const list = [...inputFields];
    const questionToRemove = list[questionIndex];
    // list.splice(questionIndex, 1);
    // setInputFields(list);
    if (
      questionToRemove.question ||
      questionToRemove.options.some((option) => option !== "") ||
      questionToRemove.answer
    ) {
      // If any field is not empty, open the confirmation modal
      setShowDeleteModal(true);
      setPassQuestionIndexToRemove(questionIndex);
    } else {
      // Otherwise, remove the question directly
      list.splice(questionIndex, 1);
      setInputFields(list);
    }
  };

  //CLOSE DELETE MODAL
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  //Add CSV Question Handler
  const addQuestionsMainHandler = () => {
    let hasError = false;
    let answerMatchingError = false;

    const removedSpacesInQuestionsFiled = inputFields.map((field) => ({
      question: field.question.trim(),
      options: field.options.map((option) => option.trim()),
      answer: field.answer.trim(),
    }));

    inputFields.forEach((field, questionIndex) => {
      const nonEmptyOptions = field.options.filter(
        (option) => option !== ""
      ).length;

      if (!field.question || nonEmptyOptions < 2 || !field.answer) {
        hasError = true;
      }
      if (field.answer && !field.options.includes(field.answer.trim())) {
        hasError = true;
        answerMatchingError = true;
      }
    });

    if (validate() && !hasError) {
      const data = {
        topic_id: values.topicId,
        level: values.levelValue,
        questions: removedSpacesInQuestionsFiled,
      };
      // console.log("data", data);
      dispatch(
        createSelectedQuestions({
          data,
          navigate,
          toast: "Questions Added successfully",
        })
      );
    } else {
      if (answerMatchingError) {
        setValidationError(
          "Answer should match from any of the given options."
        );
      } else {
        setValidationError("Please fill in all questions.");
      }
    }
  };

  return (
    <>
      <div className="project_edit_main_content question_list_checkbox_content">
        <CommonHeading heading="ADD QUESTIONS" />
        <div className="project_edit_main_link">
          <div className="comman_center_btn_container">
            <button className="go_back_btn" onClick={() => navigate(-1)}>
              {backBtnArrow} Back
            </button>
          </div>
        </div>

        <div className="create_from_row">
          <div className="common_filter_input_box first_filter_container">
            <div className="common_select_box">
              <h4 className="project_text_area_label required">Topic Id</h4>
              <Form.Select
                onChange={(e) => handleInputChange(e, "topicId")}
                id="topicId"
                name="topicId"
                className="select_form"
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  Select Topic
                </option>
                {topicOptions?.map((topic, i) => {
                  return (
                    <option key={i} value={topic.value}>
                      {topic.label}
                    </option>
                  );
                })}
              </Form.Select>
              {errors.topicId && (
                <span className="err_text">{errors.topicId}</span>
              )}
            </div>
          </div>

          <div className="common_filter_input_box first_filter_container">
            <div className="common_select_box">
              <h4 className="project_text_area_label required">Level</h4>
              <Form.Select
                onChange={(e) => handleInputChange(e, "levelValue")}
                id="levelValue"
                name="levelValue"
                className="select_form"
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  Select Level
                </option>
                <option value="EASY">Easy</option>
                <option value="MEDIUM">Medium</option>
                <option value="HARD">Hard</option>
              </Form.Select>
              {errors.levelValue && (
                <span className="err_text">{errors.levelValue}</span>
              )}
            </div>
          </div>
        </div>

        <div className="create_from_row_question mt-2">
          <h4 className="project_text_area_label required">Add Questions</h4>

          {inputFields.map((field, questionIndex) => (
            <div key={questionIndex}>
              <div className="answer_dropdown_with_heading_container_another">
                <input
                  className="project_edit_feature_input_correct_answer_another"
                  type="text"
                  placeholder="Enter Question..."
                  name="question"
                  value={field.question}
                  onChange={(e) => handleInputChangeQuesOpt(e, questionIndex)}
                />

                <div className="plus_minus_btn_container">
                  {inputFields.length - 1 === questionIndex && (
                    <button
                      className="plus_question_btn"
                      onClick={addQuestionHandler}
                    >
                      {plusWithOutCircleIcon}
                    </button>
                  )}
                  {inputFields.length !== 1 && (
                    <button
                      className="minus_question_btn"
                      onClick={() => removeQuestionHandler(questionIndex)}
                    >
                      {minusWithOutCircleIcon}
                    </button>
                  )}
                </div>
              </div>
              <br />
              <div className="option_container">
                {field.options.map((option, optionIndex) => (
                  <label key={optionIndex}>
                    <input
                      className="project_edit_feature_input_options"
                      placeholder={`Enter option ${optionIndex + 1}`}
                      type="text"
                      name={optionIndex}
                      value={option}
                      onChange={(e) =>
                        handleInputChangeQuesOpt(e, questionIndex, optionIndex)
                      }
                    />
                  </label>
                ))}
              </div>
              <br />
              <div className="answer_dropdown_with_heading_container ">
                <h4 className="answer_heading_question required">
                  Correct Answer
                </h4>
                <input
                  className="project_edit_feature_input_correct_answer"
                  type="text"
                  name="answer"
                  placeholder="Enter Correct Answer..."
                  value={field.answer}
                  onChange={(e) => handleInputChangeQuesOpt(e, questionIndex)}
                />
              </div>
              <hr className="dashed-line" />
              <br />
            </div>
          ))}
          {validationError && (
            <span className="err_text">{validationError}</span>
          )}
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn m-0"
          onClick={addQuestionsMainHandler}
          text="Add"
          // disabled={!parsingComplete}
        />
      </div>
      <Modal
        className="modal_main_component modal_background_content modal_max_w_500"
        dialogClassName="custom-dialog"
        show={showDeleteModal}
        onHide={closeDeleteModal}
        centered
      >
        <DeleteQuestionInputFields
          closeDeleteModal={closeDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          inputFields={inputFields}
          setInputFields={setInputFields}
          passQuestionIndexToRemove={passQuestionIndexToRemove}
        />
      </Modal>
    </>
  );
};

export default AddQuestion;
