import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  mobileAppStatus,
  updateMobileAppStatus,
} from "../../store/slice/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../../component/Common/Input";
import Button from "../../component/Common/Button";
import TextArea from "../../component/Common/TextArea";
import { useForm } from "../../hooks/useForm";
import CommonHeading from "../../component/Common/CommonHeading";
import { backBtnArrow } from "../../icons";

const MobileConfiguration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const appUpdateData = useSelector((state) => state?.user?.appStatus);
  const appUpdateData = location?.state?.appUpdateData;

  useEffect(() => {
    dispatch(mobileAppStatus());
  }, []);

  const initialFValues = useCallback(() => {
    // if (!appUpdateData) return;
    return getInitialLeadState(appUpdateData);
  }, [appUpdateData]);

  const getInitialLeadState = (appUpdateData) => {
    return {
      title: appUpdateData ? appUpdateData?.title : "",
      minimum_version: appUpdateData ? appUpdateData?.minimum_version : "",
      current_version: appUpdateData ? appUpdateData?.current_version : "",
      current_version_name: appUpdateData
        ? appUpdateData?.current_version_name
        : "",

      description: appUpdateData ? appUpdateData?.description : "",
      ios_url: appUpdateData ? appUpdateData?.ios_url : "",
      android_url: appUpdateData ? appUpdateData?.android_url : "",
      notify: appUpdateData
        ? appUpdateData?.notify === "0"
          ? "not_notify"
          : appUpdateData?.notify === "2"
          ? "force_update"
          : "notify"
        : "notify",
    };
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("title_en" in fieldValues) {
      temp.title_en = fieldValues.title_en ? "" : "Title is Required!";
    }
    if ("title_th" in fieldValues) {
      temp.title_th = fieldValues.title_th ? "" : "Title is Required!";
    }
    if ("minimum_version" in fieldValues) {
      temp.minimum_version = fieldValues.minimum_version
        ? ""
        : "Minimum version is Required!";
    }
    if ("current_version" in fieldValues) {
      temp.current_version = fieldValues.current_version
        ? ""
        : "Current version is Required!";
    }
    if ("current_version_name" in fieldValues) {
      temp.current_version_name = fieldValues.current_version_name
        ? ""
        : "Current version name is Required!";
    }
    if ("ios_url" in fieldValues) {
      temp.ios_url = fieldValues.ios_url ? "" : "iOS URL is Required!";
    }
    if ("android_url" in fieldValues) {
      temp.android_url = fieldValues.android_url
        ? ""
        : "Android URL is Required!";
    }
    if ("description_en" in fieldValues) {
      temp.description_en = fieldValues.description_en
        ? ""
        : "Description is Required!";
    }
    if ("description_th" in fieldValues) {
      temp.description_th = fieldValues.description_th
        ? ""
        : "Description is Required!";
    }

    setErrors({ ...temp });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate,
    appUpdateData
  );

  //UPDATE MOBILE APP STATUS
  const appStatusConfigHandler = () => {
    if (validate()) {
      const data = {
        title: values.title,
        minimum_version: values.minimum_version,
        current_version: values.current_version,
        current_version_name: values.current_version_name,
        description: values.description,
        ios_url: values.ios_url,
        android_url: values.android_url,
        notify:
          values.notify === "not_notify"
            ? "0"
            : values.notify === "notify"
            ? "1"
            : "2",
      };
      if (!appUpdateData?.id) {
        dispatch(updateMobileAppStatus({ data: data, navigate }));
      } else {
        dispatch(
          updateMobileAppStatus({ data: data, id: appUpdateData?.id, navigate })
        );
      }
    }
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="Mobile App Version Control" />

        <div className="project_edit_main_link">
          <div className="comman_center_btn_container">
            <button className="go_back_btn" onClick={() => navigate(-1)}>
              {backBtnArrow} Back
            </button>
          </div>
        </div>

        <div className="create_from_row">
          <Input
            className="project_edit_feature_input"
            labelClassName="required"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Title"
            label="Title"
            id="title"
            name="title"
            value={values?.title}
            onChange={handleInputChange}
            error={errors?.title || ""}
          />
          <Input
            className="project_edit_feature_input"
            labelClassName="required"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Minimum Version"
            label="Minimum Version"
            id="minimum_version"
            name="minimum_version"
            value={values?.minimum_version}
            onChange={handleInputChange}
            error={errors?.minimum_version || ""}
          />
          <Input
            className="project_edit_feature_input"
            labelClassName="required"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Current Version"
            label="Current Version"
            id="current_version"
            name="current_version"
            value={values?.current_version}
            onChange={handleInputChange}
            error={errors?.current_version || ""}
          />
          <Input
            className="project_edit_feature_input"
            labelClassName="required"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Current Version Name"
            label="Current Version Name"
            id="current_version_name"
            name="current_version_name"
            value={values?.current_version_name}
            onChange={handleInputChange}
            error={errors?.current_version_name || ""}
          />

          <TextArea
            className="project_edit_feature_input col_span_2"
            labelClassName="required"
            errorClassName="err_text"
            type="text"
            placeholder="Enter iOS URL"
            label="iOS URL"
            id="ios_url"
            name="ios_url"
            rows={1}
            value={values?.ios_url}
            onChange={handleInputChange}
            error={errors?.ios_url || ""}
          />
          <TextArea
            className="project_edit_feature_input col_span_2"
            labelClassName="required"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Android URL"
            label="Android URL"
            id="android_url"
            name="android_url"
            rows={1}
            value={values?.android_url}
            onChange={handleInputChange}
            error={errors?.android_url || ""}
          />
          <TextArea
            className="project_edit_feature_input col_span_2"
            labelClassName="required"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Description"
            label="Description"
            id="description"
            name="description"
            rows={5}
            value={values?.description}
            onChange={handleInputChange}
            error={errors?.description || ""}
          />

          {/* <div className="create_from_row"> */}
          <div className="create_from_input_content mobile_config_radio col_span_2">
            <label className="create_from_label required fw-semibold">
              Update
            </label>
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="Not Notify"
              id="not_notify"
              name="notify"
              value="not_notify"
              onChange={(e) => {
                handleInputChange(e);
              }}
              checked={values?.notify === "not_notify"}
            />
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="Notify"
              id="notify"
              name="notify"
              value="notify"
              onChange={(e) => {
                handleInputChange(e);
              }}
              checked={values?.notify === "notify"}
            />
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="Force Update"
              id="force_update"
              name="notify"
              value="force_update"
              onChange={(e) => {
                handleInputChange(e);
              }}
              checked={values?.notify === "force_update"}
            />
            {/* <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="Project Wise Customers"
              id="CUSTOMER"
              name="user_type"
              value="CUSTOMER"
              onChange={(e) => {
                handleInputChange(e);
              }}
              checked={values.user_type === "CUSTOMER"}
            /> */}
          </div>
          {/* </div> */}
          {/* <Input
            className="create_from_radio_checkbox_content"
            labelClassName="create_from_radio_label"
            inputClassName="create_from_radio_checkbox"
            type="checkbox"
            id="notify"
            name="notify"
            label="Notify"
            onChange={(e) => onchangeCheckbox(e)}
            checked={values.notify === true}
          /> */}
        </div>
        <Button
          // className="create_from_bottom_btn col_span_2 justify-content-start"
          // buttonClassName="comman_btn ms-0"
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn m-0"
          onClick={appStatusConfigHandler}
          text={"Save"}
        />
      </div>
    </>
  );
};

export default MobileConfiguration;
