import React, { useEffect, useState } from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import { useDispatch, useSelector } from "react-redux";
import { getAvatarIcons } from "../../store/slice/avatarSlice";
import Loader from "../../component/Common/Loader";
import AvatarImgList from "./AvatarImgList";
import { Modal } from "react-bootstrap";
import DeleteAvatar from "./DeleteAvatar";
import AddAvatarModal from "./AddAvatarModal";

const Avatar = () => {
  const dispatch = useDispatch();

  const [avatarId, setAvatarId] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [openAddImgModal, setOpenAddImgModal] = useState(false);
  const [imgErrors, setImgErrors] = useState(false);

  const [uploading, setUploading] = useState(false);

  const avatarList = useSelector((state) => state?.avatar?.avatarImgList);
  const isLoading = useSelector((state) => state?.avatar?.loader);

  useEffect(() => {
    dispatch(getAvatarIcons({}));
  }, []);

  //DELETE ICON OF AVATAR IMAGES
  const deleteIconHandler = (id) => {
    setAvatarId(id);
    setOpenDeleteModal(true);
  };

  //CLOSE DELETE MODAL
  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setAvatarId([]);
  };

  // //OPEN ADD AVATAR MODAL
  const showImgModal = () => {
    setOpenAddImgModal(true);
    setImgErrors("");
  };

  //CLOSE IMG MODAL
  const closeAvatarImgModal = () => {
    setOpenAddImgModal(false);
    setImgErrors("");
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="AVATAR" />
        {isLoading || uploading ? (
          <Loader />
        ) : (
          <>
            <AvatarImgList
              showImgModal={showImgModal}
              avatarList={avatarList}
              deleteIconHandler={deleteIconHandler}
            />
          </>
        )}
      </div>

      <Modal
        className="modal_main_component modal_background_content modal_add_img_content"
        show={openAddImgModal}
        onHide={closeAvatarImgModal}
        centered
      >
        <AddAvatarModal
          closeAvatarImgModal={closeAvatarImgModal}
          imgErrors={imgErrors}
          setImgErrors={setImgErrors}
          setOpenAddImgModal={setOpenAddImgModal}
          setUploading={setUploading}
        />
      </Modal>

      <Modal
        className="modal_main_component modal_background_content"
        show={openDeleteModal}
        onHide={closeDeleteModal}
        centered
      >
        <DeleteAvatar
          avatarId={avatarId}
          setAvatarId={setAvatarId}
          setOpenDeleteModal={setOpenDeleteModal}
          closeDeleteModal={closeDeleteModal}
        />
      </Modal>
    </>
  );
};

export default Avatar;
