import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";

export const fileUpload = createAsyncThunk(
  "fileUpload",
  async (formData, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/file/upload`,
        formData,
        authHeaders()
      );
      return response.data.key;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// export const fileUploadVisitor = createAsyncThunk(
//   "fileUploadVisitor",
//   async (formData, thunkAPI) => {
//     try {
//       const response = await Axios.post(
//         `/onecity/file/upload/visitor-invite`,
//         formData
//       );
//       console.log("uploadconfig", response);
//       return response.data.key;
//     } catch (error) {
//       console.log("error", error);
//     }
//   }
// );

const fileUploadSlice = createSlice({
  name: "fileUpload",
  initialState: {
    fileUpload: "",
    fileUploadVisitor: "",
    error: null,
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Upload files
    builder.addCase(fileUpload.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(fileUpload.fulfilled, (state, action) => {
      state.loader = false;
      state.fileUpload = action.payload;
    });
    builder.addCase(fileUpload.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Upload files for visitor
    // builder.addCase(fileUploadVisitor.pending, (state) => {
    //   state.loader = true;
    // });
    // builder.addCase(fileUploadVisitor.fulfilled, (state, action) => {
    //   state.loader = false;
    //   state.fileUploadVisitor = action.payload;
    // });
    // builder.addCase(fileUploadVisitor.rejected, (state, action) => {
    //   state.loader = false;
    //   state.error = action.payload;
    // });
  },
});

export default fileUploadSlice.reducer;
