import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "../../css/component.css";
import { routes } from "../../constants";
import { logoutIcon, sidebarToggleIcon } from "../../icons";
import { notify } from "../../helpers/commonFunction";
import { logout } from "../../store/slice/userSlice";

const Navbar = () => {
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
    notify("Logout successfully!", "success");
  };

  return (
    <header className="top_header_section">
      <label htmlFor="sidebar_toggel" className="sidebar_toggel_btn">
        {sidebarToggleIcon}
      </label>
      <ul className="top_navbar_nav">
        <li className="top_navbar_item">
          <button
            className="top_navbar_logout_btn"
            to={routes.login}
            onClick={logoutHandler}
          >
            {logoutIcon}
            <span>Logout</span>
          </button>
        </li>
      </ul>
    </header>
  );
};

export default Navbar;
