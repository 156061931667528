import React from "react";
import { closeIcon, coinIcon } from "../../icons";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";

const ViewCoinHistory = ({ viewModalData, closeViewModal }) => {
  return (
    <div className="modal_container">
      <div className="modal_heading">
        <h1 className="modal_main_heading">Coin History Details</h1>
        <button className="modal_close_btn" onClick={closeViewModal}>
          {closeIcon}
        </button>
      </div>

      <div className="modal_detail_container">
        <div className="modal_detail_container_grid only_detail_modal">
          <div className="modal_detail_content">
            <h2 className="modal_detail_content_heading">Coins</h2>
            <p>
              {viewModalData.coins ? (
                <>
                  {viewModalData.coins} {coinIcon}
                </>
              ) : (
                "-"
              )}
            </p>
          </div>

          <div className="modal_detail_content">
            <h2 className="modal_detail_content_heading">Coin Status</h2>
            <p
              className={`question_table_level_text ${
                viewModalData.coin_status === "CREDITED"
                  ? "credited"
                  : "debited"
              }`}
            >
              {viewModalData.coin_status ? viewModalData.coin_status : "-"}
            </p>
          </div>

          <div className="modal_detail_content">
            <h2 className="modal_detail_content_heading">Lifeline</h2>
            <p>
              {viewModalData.lifeline === true
                ? "✅"
                : viewModalData.lifeline === false
                ? "❌"
                : "-"}
            </p>
          </div>

          <div className="modal_detail_content">
            <h2 className="modal_detail_content_heading">Quiz Id</h2>
            <p>{viewModalData.quiz_id ? viewModalData.quiz_id : "-"}</p>
          </div>

          <div className="modal_detail_content">
            <h2 className="modal_detail_content_heading">Created At</h2>
            <p>
              {viewModalData.created_at
                ? moment
                    .utc(viewModalData.created_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A")
                : "-"}
            </p>
          </div>

          <div className="modal_detail_content">
            <h2 className="modal_detail_content_heading">Updated At</h2>
            <p>
              {viewModalData.updated_at
                ? moment
                    .utc(viewModalData.updated_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A")
                : "-"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCoinHistory;
