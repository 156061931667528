import React, { useState } from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import { addIcon, backBtnArrow, editIcon } from "../../icons";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../../component/Common/Input";
import { Form } from "react-bootstrap";
import Button from "../../component/Common/Button";
import { useForm } from "../../hooks/useForm";
import { imagePreviewProps } from "../../helpers/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import { fileUpload } from "../../store/slice/fileUploadSlice";
import Loader from "../../component/Common/Loader";
import { updateAvatarIcons } from "../../store/slice/avatarSlice";

const EditAvatar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const editAvatar = location?.state?.avatarDetails;

  const isLoading = useSelector((state) => state?.avatar?.loader);

  console.log("isLoading ", isLoading);

  const [uploading, setUploading] = useState(false);
  console.log("editAvatar", editAvatar);

  let initialImagePreview;
  if (editAvatar?.image) {
    initialImagePreview = `${process.env.REACT_APP_FILE_BASE_URL}/${editAvatar?.image}`;
  }

  //ALL STATES VALUES
  const initialFValues = {
    image: editAvatar ? editAvatar?.image : "",
    image_preview: editAvatar ? initialImagePreview : "",
    premium: editAvatar ? editAvatar?.premium : "",
  };

  //VALIDATION OF CREATE REWARD FORM
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("image" in fieldValues) {
      temp.image = "";
      if (!fieldValues.image) {
        temp.image = "Icon required for reward!";
      }
      if (fieldValues.image.size > 5000000) {
        temp.image = "Icon should be less than 5Mb!";
        setValues({
          ...values,
          image: "",
          image_preview: "",
        });
      }
      if (
        fieldValues.image.name &&
        !fieldValues.image.name?.match(/.(jpg|jpeg|png|gif)$/i)
      ) {
        temp.image = "Invalid Icon!";
        setValues({
          ...values,
          image: "",
          image_preview: "",
        });
      }
    }

    if ("day" in fieldValues) {
      temp.day = fieldValues.day ? "" : "Please select day!";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  //USE FORM HOOK
  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  //IMAGE REMOVE HANDLER
  const imageRemoveHandler = () => {
    setValues({ ...values, image: "", image_preview: "" });
  };

  //EDIT AVATAR PREMIUM / NON-PREMIUM
  const editAvatarPremiumHandler = async () => {
    if (validate()) {
      setUploading(true);
      const formData = new FormData();
      let imageUrl;
      if (values.image && typeof values.image !== "string") {
        formData.append("file", values.image);
        imageUrl = await dispatch(fileUpload(formData));
        formData.delete("file");
      } else {
        imageUrl = values.image;
      }

      setUploading(false);
      const data = {
        premium: values.premium,
        image: typeof values.image !== "string" ? imageUrl.payload : imageUrl,
        id: editAvatar?.id,
      };

      console.log("data", data);
      dispatch(
        updateAvatarIcons({
          data,
          navigate,
          toast: "Avatar updated successfully",
        })
      );
    }
  };

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading="UPDATE AVATAR" />
      {isLoading || uploading ? (
        <Loader />
      ) : (
        <>
          <div className="project_edit_main_link">
            <div className="comman_center_btn_container">
              <button className="go_back_btn" onClick={() => navigate(-1)}>
                {backBtnArrow} Back
              </button>
            </div>
          </div>

          <div className="create_from_row">
            <div>
              <label className="project_text_area_label img_label required">
                Update Avatar
              </label>
              <div className="project_edit_feature_input_file">
                <Input
                  className="create_from_input_content"
                  labelClassName="create_from_label"
                  errorClassName="err_text"
                  type="file"
                  id={`image`}
                  labelOtherProps={
                    <span>{!values.image ? addIcon : editIcon}</span>
                  }
                  name="image"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleInputChange}
                  onClick={(e) => (e.target.value = null)}
                  imageProps={imagePreviewProps(
                    values.image,
                    values.image_preview,
                    imageRemoveHandler
                  )}
                />
              </div>
              {errors.image && <span className="err_text">{errors.image}</span>}
            </div>

            <div className="common_filter_input_box first_filter_container">
              <div className="common_select_box">
                <h4 className="project_text_area_label required">
                  Select Premium
                </h4>
                <Form.Select
                  onChange={(e) => handleInputChange(e, "premium")}
                  id=" premium"
                  name="premium"
                  className="select_form"
                  defaultValue={values.premium}
                >
                  <option disabled value={"Default"}>
                    Select
                  </option>
                  <option value="true">Premium</option>
                  <option value="false">Non-Premium</option>
                </Form.Select>
              </div>
            </div>
          </div>
          <Button
            className="project_submit_bottom_btn center_back_btn"
            buttonClassName="comman_btn save_and_submit_form_btn"
            onClick={editAvatarPremiumHandler}
            text="Update"
          />
        </>
      )}
    </div>
  );
};

export default EditAvatar;
