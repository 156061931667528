import React, { useEffect, useRef, useState } from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import { useNavigate } from "react-router-dom";
import { backBtnArrow } from "../../icons";
import { Form } from "react-bootstrap";
import { useForm } from "../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { getTopicList } from "../../store/slice/topicSlice";
import CsvQuestionAnswer from "./CsvQuestionAnswer";
import Button from "../../component/Common/Button";
import { createSelectedQuestions } from "../../store/slice/questionSlice";

// export const optionQuestions = [
//   {
//     id: 0,
//     Questions: "What is the puropse of a tester in software development?",
//     answer: "true",
//     optionsA: "true",
//     optionsB: "false",
//     optionsC: "",
//     optionD: "",
//     // optionsB: "To write code",
//     // optionsC: "To design user interface",
//   },
//   {
//     id: 1,
//     Questions: "What is the puropse of a tester in software development?",
//     answer: "To find bugs and issues",
//     optionsA: "Tofindbugsandissues",
//     optionsB: "To write code",
//     optionsC: "To design user interface",
//     optionD: "",
//   },
//   {
//     id: 2,
//     Questions: "What is the puropse of a tester in software development?",
//     answer: "To find bugs and issues",
//     optionsA: "Tofindbugsandissues",
//     optionsB: "To write code",
//     optionsC: "To design user interface",
//     optionD: "",
//   },
//   {
//     id: 3,
//     Questions: "What is the puropse of a tester in software development?",
//     answer: "To find bugs and issues",
//     optionsA: "Tofindbugsandissues",
//     optionsB: "To write code",
//     optionsC: "To design user interface",
//     optionD: "",
//   },
//   {
//     id: 4,
//     Questions: "What is the puropse of a tester in software development?",
//     answer: "To find bugs and issues",
//     optionsA: "Tofindbugsandissues",
//     optionsB: "To write code",
//     optionsC: "To design user interface",
//     optionD: "",
//   },
//   {
//     id: 5,
//     Questions: "What is the puropse of a tester in software development?",
//     answer: "To find bugs and issues",
//     optionsA: "Tofindbugsandissues",
//     optionsB: "To write code",
//     optionsC: "To design user interface",
//     optionD: "",
//   },
// ];

const CreateCSVQuestion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [csvData, setCsvData] = useState([]);
  const [parsingComplete, setParsingComplete] = useState(false);

  const ref = useRef(false);

  //API call of get topic list
  useEffect(() => {
    dispatch(getTopicList({ start: 0, limits: 100000000 }));
    ref.current = true;
  }, []);

  const topic = useSelector((state) => state?.topic?.topicList);
  const topicOptions = topic?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const initialFValues = {
    topicId: "",
    levelValue: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("topicId" in fieldValues) {
      temp.topicId = fieldValues.topicId ? "" : "Please Select Topic";
    }
    if ("levelValue" in fieldValues) {
      temp.levelValue = fieldValues.levelValue ? "" : "Please Select Level";
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  //USE FORM HOOK
  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  //Add CSV Question Handler
  const addCsvQuestionHandler = () => {
    if (validate()) {
      const data = {
        topic_id: values.topicId,
        level: values.levelValue,
        questions: csvData,
      };
      console.log("data", data);
      dispatch(
        createSelectedQuestions({
          data,
          navigate,
          toast: "Questions Imported successfully",
        })
      );
    }
  };

  // const formatQues = optionQuestions.map((item) => ({
  //   id: item.id,
  //   Questions: item.Questions,
  //   answer: item.answer,
  //   options: [
  //     item.optionsA,
  //     item.optionsB,
  //     item.optionsC,
  //     item.optionsD,
  //   ].filter(Boolean),
  // }));

  return (
    <div className="project_edit_main_content question_list_checkbox_content">
      <CommonHeading heading="IMPORT QUESTIONS" />
      <div className="project_edit_main_link">
        <div className="comman_center_btn_container">
          <button className="go_back_btn" onClick={() => navigate(-1)}>
            {backBtnArrow} Back
          </button>
        </div>
      </div>

      <div className="create_from_row">
        <div className="common_filter_input_box first_filter_container">
          <div className="common_select_box">
            <h4 className="project_text_area_label required">Topic Id</h4>
            <Form.Select
              onChange={(e) => handleInputChange(e, "topicId")}
              id="topicId"
              name="topicId"
              className="select_form"
              defaultValue={"DEFAULT"}
            >
              <option value="DEFAULT" disabled>
                Select Topic
              </option>
              {topicOptions?.map((topic, i) => {
                return (
                  <option key={i} value={topic.value}>
                    {topic.label}
                  </option>
                );
              })}
            </Form.Select>
            {errors.topicId && (
              <span className="err_text">{errors.topicId}</span>
            )}
          </div>
        </div>

        <div className="common_filter_input_box first_filter_container">
          <div className="common_select_box">
            <h4 className="project_text_area_label required">Level</h4>
            <Form.Select
              onChange={(e) => handleInputChange(e, "levelValue")}
              id="levelValue"
              name="levelValue"
              className="select_form"
              defaultValue={"DEFAULT"}
            >
              <option value="DEFAULT" disabled>
                Select Level
              </option>
              <option value="EASY">Easy</option>
              <option value="MEDIUM">Medium</option>
              <option value="HARD">Hard</option>
            </Form.Select>
            {errors.levelValue && (
              <span className="err_text">{errors.levelValue}</span>
            )}
          </div>
        </div>
        <CsvQuestionAnswer
          csvData={csvData}
          setCsvData={setCsvData}
          setParsingComplete={setParsingComplete}
        />
      </div>

      <Button
        className="project_submit_bottom_btn center_back_btn"
        buttonClassName="comman_btn m-0"
        onClick={addCsvQuestionHandler}
        text="Add"
        disabled={!parsingComplete}
      />
    </div>
  );
};

export default CreateCSVQuestion;
