import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slice/userSlice";
import topicReducer from "./slice/topicSlice";
import questionReducer from "./slice/questionSlice";
import quizReducer from "./slice/quizSlice";
import serviceReducer from "./slice/serviceSlice";
import dashboardReducer from "./slice/dashboardSlice";
import rewardReducer from "./slice/rewardSlice";
import fileUploadReducer from "./slice/fileUploadSlice";
import quizSettingReducer from "./slice/quizSettingSlice";
import coinReducer from "./slice/coinSlice";
import avatarReducer from "./slice/avatarSlice";
import corruptReducer from "./slice/corruptSlice";
import PaymentReducer from "./slice/PaymentSlice";
import frameReducer from "./slice/frameSlice";
import levelReducer from "./slice/levelSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    topic: topicReducer,
    question: questionReducer,
    quiz: quizReducer,
    service: serviceReducer,
    dashboard: dashboardReducer,
    reward: rewardReducer,
    level: levelReducer,
    fileUpload: fileUploadReducer,
    quizSetting: quizSettingReducer,
    coins: coinReducer,
    avatar: avatarReducer,
    frame: frameReducer,
    corrupt: corruptReducer,
    payment: PaymentReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
