import React from "react";
import { Link } from "react-router-dom";
import "../../css/component.css";
import { useLocation } from "react-router-dom";
import { routes } from "../../constants";
import sidebarLogo from "../../images/TriviaImages/BlueQuizLogo.png";
import {
  avtarIcon,
  coinsHistory,
  complaintIcon,
  corruptedQuesIcon,
  dailyRewardIcon,
  dashboardIcon,
  frameIcon,
  gameIcon,
  levelRewardIcon,
  mobileUpdateIcon,
  paymentIcon,
  projectIcon,
  question,
  questionMark,
  quizIcon,
  quizNewLogo,
  quizSettingIcon,
  topicIcon,
  userIcon,
} from "../../icons";
import sidebarSmallIcon from "../../images/TriviaImages/sidebarSmall.png";

const Sidebar = () => {
  const { pathname } = useLocation();
  return (
    <div className="sidebar_container">
      <Link
        to={routes.dashboard}
        className={`sidebar_logo ${
          pathname.match(/^.*dashboard.*$/) && "active"
        } `}
      >
        <div>
          <img
            src={sidebarLogo}
            alt="trivia_centre-logo"
            className="full_sidebar_logo"
          />
          <img
            src={sidebarSmallIcon}
            alt="onecity_centre-logo"
            className="small_sidebar_logo"
          />
        </div>
      </Link>
      <ul className="sidebar_nav">
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*dashboard.*$/) && "active"
            } `}
            to={routes.dashboard}
          >
            {dashboardIcon}
            <span>Dashboard</span>
          </Link>
        </li>

        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*avatar.*$/) && "active"
            } `}
            to={routes.avatar}
          >
            {avtarIcon}
            <span>Avatar</span>
          </Link>
        </li>

        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*frame.*$/) && "active"
            } `}
            to={routes.frame}
          >
            {frameIcon}
            <span>Frames</span>
          </Link>
        </li>

        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              (pathname.match(/^.*topic.*$/) ||
                pathname.match(/^.*topic.*$/)) &&
              "active"
            } `}
            to={`${routes.topic}/page/1`}
          >
            {topicIcon}
            <span>Topic</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              (pathname.match(/^.*question.*$/) ||
                pathname.match(/^.*question.*$/)) &&
              "active"
            } `}
            to={`${routes.question}/page/1`}
          >
            {/* {questionMark} */}
            {question}

            <span>Question</span>
          </Link>
        </li>

        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              (pathname.match(/^.*corrupted.*$/) ||
                pathname.match(/^.*corrupted.*$/)) &&
              "active"
            } `}
            to={routes.corrupted}
          >
            {corruptedQuesIcon}
            <span>Corrupted Question</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              (pathname.match(/^.*user.*$/) || pathname.match(/^.*user.*$/)) &&
              "active"
            } `}
            to={`${routes.user}/page/1`}
          >
            {userIcon}
            <span>User</span>
          </Link>
        </li>

        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              (pathname.match(/^.*quiz.*$/) || pathname.match(/^.*quiz.*$/)) &&
              "active"
            } `}
            to={`${routes.quiz}/page/1`}
          >
            {gameIcon}
            <span>Games</span>
          </Link>
        </li>

        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              (pathname.match(/^.*coins.*$/) ||
                pathname.match(/^.*coins.*$/)) &&
              "active"
            } `}
            to={`${routes.coins}/page/1`}
          >
            {coinsHistory}
            <span>Coins History</span>
          </Link>
        </li>

        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              (pathname.match(/^.*reward.*$/) ||
                pathname.match(/^.*reward.*$/)) &&
              "active"
            } `}
            to={`${routes.reward}/page/1`}
          >
            {dailyRewardIcon}
            <span>Daily Reward</span>
          </Link>
        </li>

        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              (pathname.match(/^.*level.*$/) ||
                pathname.match(/^.*level.*$/)) &&
              "active"
            } `}
            to={`${routes.level}/page/1`}
          >
            {levelRewardIcon}
            <span>Level Reward</span>
          </Link>
        </li>

        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              (pathname.match(/^.*setting.*$/) ||
                pathname.match(/^.*setting.*$/)) &&
              "active"
            } `}
            to={`${routes.setting}/page/1`}
          >
            {quizSettingIcon}
            <span>Quiz Setting</span>
          </Link>
        </li>

        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              (pathname.match(/^.*service.*$/) ||
                pathname.match(/^.*service.*$/)) &&
              "active"
            } `}
            to={`${routes.service}/page/1`}
          >
            {complaintIcon}
            <span>Service</span>
          </Link>
        </li>

        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              (pathname.match(/^.*payments.*$/) ||
                pathname.match(/^.*payments.*$/)) &&
              "active"
            } `}
            to={`${routes.payments}/page/1`}
          >
            {paymentIcon}
            <span>Payment History</span>
          </Link>
        </li>

        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              (pathname.match(/^.*mobile.*$/) ||
                pathname.match(/^.*mobile.*$/)) &&
              "active"
            } `}
            to={routes.mobile}
          >
            {mobileUpdateIcon}
            <span>Mobile Configuration</span>
          </Link>
        </li>

        {/* <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              (pathname.match(/^.*practice.*$/) ||
                pathname.match(/^.*practice.*$/)) &&
              "active"
            } `}
            to={routes.practice}
          >
            {gameIcon}
            <span>Practice</span>
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default Sidebar;
