import React from "react";

export default function Input(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    id,
    type,
    placeholder,
    className,
    labelClassName,
    inputClassName,
    errorClassName,
    maxLength,
    labelOtherProps,
    labelHeading,
    accept,
    onClick,
    imageProps,
    checked,
    multiple,
    disabled,
    autoFocus,
    eyeIcon,
    eyeIconHandler,
    autoComplete,
    Icon,
    hasInfoIcon,
    tooltipText,
  } = props;
  return (
    <div className={className}>
      <label
        htmlFor={id}
        className={`${labelClassName} ${checked && "checked"}`}
      >
        {labelOtherProps && labelOtherProps}
        {label}
        {hasInfoIcon && (
          <div className="create_from_label_info_icon tooltip">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
            <span className="tooltiptext">{tooltipText}</span>
          </div>
        )}
      </label>
      <div className={`${(eyeIcon || Icon) && "oc_eyeicon_input"}`}>
        <input
          className={inputClassName}
          type={type}
          id={id}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onClick={onClick}
          maxLength={maxLength}
          accept={accept}
          checked={checked}
          multiple={multiple}
          disabled={disabled}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
        />
        <span className="zu_password_eye_btn" onClick={eyeIconHandler}>
          {eyeIcon && eyeIcon}
        </span>
      </div>
      {labelHeading && labelHeading}
      {imageProps && imageProps}
      {error ? <span className={errorClassName}>{error}</span> : null}
    </div>
  );
}
