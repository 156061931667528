import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios, { authHeaders } from "../../helpers/axios";
import { routes } from "../../constants";

export const getPaymentHistoryList = createAsyncThunk(
  "getPaymentHistoryList",
  async (data) => {
    try {
      const response = await Axios.get(
        `/user/get/payment-history?name=${data.user || ""}&start=${
          data.start || 0
        }&limits=${data.limits || 10}`,
        authHeaders()
      );
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    paymentList: [],
    loader: false,
    error: "",
    totalCount: 0,
  },
  reducer: {},
  extraReducers: (builder) => {
    //GET USER LIST
    builder.addCase(getPaymentHistoryList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getPaymentHistoryList.fulfilled, (state, action) => {
      state.loader = false;
      state.paymentList = action.payload;
      state.totalCount = action.payload;
    });
    builder.addCase(getPaymentHistoryList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default paymentSlice.reducer;
