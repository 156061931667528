import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getQuizQuesOfUserByQuizId } from "../../store/slice/quizSlice";
import Loader from "../../component/Common/Loader";
import CommonHeading from "../../component/Common/CommonHeading";
import {
  backBtnArrow,
  blueMinusIcon,
  bluePlusIcon,
  roundAddIcon,
  roundMinusIcon,
} from "../../icons";
import moment from "moment";

const ViewUserQuizDetailQuestion = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quizId = location?.state?.quizId;

  const [iconToggle, setIconToggle] = useState(false);

  const questions = useSelector((state) => state.quiz?.quizQuesOfUserByQuizId);
  const isLoading = useSelector((state) => state.quiz?.loader);

  useEffect(() => {
    dispatch(getQuizQuesOfUserByQuizId({ id: quizId }));
  }, []);

  const iconToggleHandler = (questionId) => {
    setIconToggle((prevState) => ({
      ...prevState,
      [questionId]: !prevState[questionId],
    }));
  };

  return (
    <div className="project_edit_main_content">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CommonHeading heading="QUIZ" />
          <div className="project_edit_main_link">
            <div className="comman_center_btn_container">
              <button className="go_back_btn" onClick={() => navigate(-1)}>
                {backBtnArrow} Back
              </button>
            </div>
          </div>

          <div className="create_from_row mb-20">
            {questions?.user && (
              <div>
                <div className="project_view_list_item">User</div>
                <p>{questions.user.name}</p>
              </div>
            )}
            {questions?.user && (
              <div>
                <div className="project_view_list_item">Topic</div>
                <p>{questions.topic.name}</p>
              </div>
            )}

            {questions?.start_time && (
              <div>
                <div className="project_view_list_item">Start Time</div>
                <p className="question_table_level_text start-time">
                  {moment(questions.start_time, ["hh:mm:ss"]).format("hh:mm A")}
                </p>
              </div>
            )}
            {questions?.end_time && (
              <div>
                <div className="project_view_list_item">End Time</div>
                <p className="question_table_level_text end-time">
                  {moment(questions.end_time, ["hh:mm:ss"]).format("hh:mm A")}
                </p>
              </div>
            )}
          </div>

          {/* <div className="create_from_row_questions">
            {questions?.quiz_history?.map((ques, i) => {
              const questionId = ques.question_id;
              const isIconToggled = iconToggle[questionId] || false;
              return (
                <div key={i}>
                  <div className="project_view_list_item">
                    <div className="question_with_icon">
                      <h2>{ques.question}</h2>
                      <p onClick={() => iconToggleHandler(questionId)}>
                        {isIconToggled ? (
                          <span>{roundMinusIcon}</span>
                        ) : (
                          <span>{roundAddIcon}</span>
                        )}
                      </p>
                    </div>

                    {isIconToggled && (
                      <>
                        <h6>Options:</h6>
                        {ques.options.map((option, i) => {
                          return (
                            <div key={i}>
                              <p>
                                {i + 1}. {option}
                              </p>
                            </div>
                          );
                        })}
                        <h6
                          className={
                            ques.answer === ques.correct_answer
                              ? "correct_answer"
                              : "in_correct_answer"
                          }
                        >
                          Your Answer :
                          {ques.answer === ques.correct_answer ? (
                            <span className="correct_answer">
                              {ques.answer}
                            </span>
                          ) : (
                            <span className="in_correct_answer">
                              {ques.answer}
                            </span>
                          )}
                        </h6>

                        <h6>
                          Correct Answer : <span>{ques.correct_answer}</span>
                        </h6>
                      </>
                    )}
                    <hr className="hr_line_divider span_2" />
                  </div>
                </div>
              );
            })}
          </div> */}

          <div className="create_from_row_questions">
            {questions?.quiz_history?.map((ques, i) => {
              const questionId = ques.question_id;
              const isIconToggled = iconToggle[questionId] || false;
              return (
                <div className="project_view_list_item" key={i}>
                  <div className="project_view_list_item_header">
                    <h2>{ques.question}</h2>
                    <button
                      type="button"
                      className="project_view_list_item_header_btn"
                      onClick={() => iconToggleHandler(questionId)}
                    >
                      {isIconToggled ? (
                        <span>{blueMinusIcon}</span>
                      ) : (
                        <span> {bluePlusIcon}</span>
                      )}
                    </button>
                  </div>
                  {isIconToggled && (
                    <div className="project_view_list_item_body">
                      <div className="project_view_list_item_option_answer_row">
                        {ques.options.map((option, i) => {
                          return (
                            <div
                              className={`project_view_list_item_option_answer_col ${
                                option === ques.correct_answer &&
                                "project_view_list_item_option_correct_answer"
                              }`}
                              key={i}
                            >
                              <p className="project_view_list_item_option_answer_col_text">
                                {option}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                      <h6 className="project_view_list_item_your_answer">
                        Your Answer:{" "}
                        {ques.answer === ques.correct_answer ? (
                          <span className="correct_answer">{ques.answer}</span>
                        ) : (
                          <span className="in_correct_answer">
                            {ques.answer}
                          </span>
                        )}
                      </h6>
                      {/* <h6>
                        Correct Answer : <span>{ques.correct_answer}</span>
                      </h6> */}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ViewUserQuizDetailQuestion;
