import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceList } from "../../store/slice/serviceSlice";
import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import { backBtnArrow, reFresh } from "../../icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import ServiceList from "./ServiceList";
import ViewService from "./ViewService";
import UpdateServiceStatus from "./UpdateServiceStatus";
import moment from "moment";
import Pagination from "../../component/Pagination/Pagination";
import DeleteService from "./DeleteService";

const Service = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef(false);
  const params = useParams();
  const { page_number } = params;

  const value = location?.state?.value;
  const dateValue = location?.state?.date;

  const [reqId, setReqId] = useState("");
  const [viewReq, setViewReq] = useState(false);
  const [prevReqId, setPrevReq] = useState("");
  const [viewSerUpdateModal, setViewSerUpdateModal] = useState(false);
  const [serviceUpdateData, setServiceUpdateData] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");
  const [filterDate, setFilterDate] = useState();
  const [pageN, setPageN] = useState(page_number - 1);

  const isLoading = useSelector((state) => state?.service?.loader);
  const services = useSelector((state) => state?.service?.serviceList);
  const totalCount = useSelector((state) => state?.service?.totalCount);

  const initialFValues = {
    status: value?.status ? value?.status : "",
  };

  //USE FORM HOOK
  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  console.log(
    "filterDate",
    filterDate,
    moment(filterDate).format("YYYY-MM-DD")
  );

  useEffect(() => {
    if (ref.current) {
      dispatch(
        getServiceList({
          // status: values.status === "" ? "" : values.status,
          // request_date: !filterDate
          //   ? ""
          //   : moment(filterDate).format("YYYY-MM-DD"),
          start: (page_number - 1) * 10,
          limits: 10,
        })
      );
    }
  }, [pageN]);

  useEffect(() => {
    if (page_number !== 1) {
      navigate(`/service/page/1`);
    }
    if (ref.current) {
      dispatch(
        getServiceList({
          status: values.status === "" ? "" : values.status,
          request_date: !filterDate
            ? ""
            : moment(filterDate).format("YYYY-MM-DD"),
          start: (page_number - 1) * 10,
          limits: 10,
        })
      );
    }
  }, [values.status, filterDate]);

  useEffect(() => {
    dispatch(
      getServiceList({
        // status: values.status === "" ? "" : values.status,
        // request_date: !filterDate
        //   ? ""
        //   : moment(filterDate).format("YYYY-MM-DD"),
        start: (page_number - 1) * 10,
        limits: 10,
      })
    );
    ref.current = true;
  }, []);

  // VIEW ICON MODAL HANDLER
  const showViewModalHandler = (id) => {
    setReqId(id);
    setViewReq(true);
  };

  //CLOSE VIEW MODEL HANDLER
  const closeModal = () => {
    setViewReq(false);
    setPrevReq(reqId);
  };

  // EDIT ICON MODAL
  const showEditModalHandler = (item) => {
    setServiceUpdateData(item);
    setViewSerUpdateModal(true);
  };

  //CLOSE EDIT MODAL
  const closeUpdateReqModal = () => {
    setViewSerUpdateModal(false);
  };

  //DELETE ICON HANDLER
  const showDeleteModalHandler = (id) => {
    setDeleteItemId(id);
    setOpenDeleteModal(true);
  };

  //CLOSE DELETE MODAL
  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setDeleteItemId("");
  };

  //on-Page change
  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="project_edit_main_content">
          <CommonHeading heading="SERVICE" />
          <>
            <div className="creat_edit_project_btn_row_flex">
              <div></div>

              <div className="common_all_filter_row">
                <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      onChange={(e) => handleInputChange(e, "status")}
                      id="status"
                      name="status"
                      className="select_form"
                      value={values.status}
                    >
                      <option value="" disabled>
                        Filter By Status...
                      </option>
                      <option value="">All</option>
                      <option value="Pending">Pending</option>
                      <option value="Resolved">Resolved</option>
                      <option value="Closed">Closed</option>
                      <option value="Reopen">Reopen</option>
                    </Form.Select>
                  </div>
                </div>

                <div className="common_filter_input_box">
                  <div className="project_date_range_input_container">
                    <div className="custom_date_picker">
                      <DatePicker
                        isClearable={filterDate}
                        onChange={(date) => {
                          setFilterDate(date);
                        }}
                        selected={filterDate}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Search By Date..."
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="custom_data_table_content">
              <ServiceList
                services={services}
                values={values}
                filterDate={filterDate}
                page_number={page_number}
                showViewModalHandler={showViewModalHandler}
                showEditModalHandler={showEditModalHandler}
                showDeleteModalHandler={showDeleteModalHandler}
              />
            </div>
          </>
          {totalCount > 0 && (
            <Pagination totalRecords={totalCount} onPageChange={onPageChange} />
          )}
        </div>
      )}
      <Modal
        className="modal_main_component modal_background_content"
        dialogClassName="custom-dialog"
        show={viewReq}
        onHide={closeModal}
        centered
      >
        <ViewService
          closeModal={closeModal}
          reqId={reqId}
          prevReqId={prevReqId}
        />
      </Modal>
      <Modal
        className="modal_main_component modal_background_content"
        show={viewSerUpdateModal}
        dialogClassName="small-dialog"
        onHide={closeUpdateReqModal}
        centered
      >
        <UpdateServiceStatus
          closeModal={closeUpdateReqModal}
          serviceUpdateData={serviceUpdateData}
          // pageNumber={page_number}
          // filterDate={filterDate}
          // values={values}
        />
      </Modal>
      <Modal
        className="modal_main_component modal_background_content modal_max_w_500"
        dialogClassName="custom-dialog"
        show={openDeleteModal}
        onHide={closeDeleteModal}
        centered
      >
        <DeleteService
          closeModal={closeDeleteModal}
          deleteItemId={deleteItemId}
          setDeleteItemId={setDeleteItemId}
          setOpenDeleteModal={setOpenDeleteModal}
          totalCount={totalCount.toString()}
          pageNumber={page_number}
        />
      </Modal>
    </>
  );
};

export default Service;
