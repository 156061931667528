import React, { useEffect } from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import { backBtnArrow, coinIcon } from "../../icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";
import { routes } from "../../constants";
// import { getQuizListByUserId } from "../../store/slice/quizSlice";

const ViewUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = location?.state?.userQuizDetails;

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading="VIEW USER" />

      <div className="project_edit_main_link">
        <div className="comman_center_btn_container">
          <button className="go_back_btn" onClick={() => navigate(-1)}>
            {backBtnArrow} Back
          </button>

          <div className="project_multiple_tab">
            <Link
              className="comman_btn extra_btn_row"
              to={`/view-user/details/${user?.id}`}
              state={{ quizDetailUserId: user?.id }}
            >
              Quiz
            </Link>
            <Link
              className="comman_btn extra_btn_row"
              to={`/view-user/coin/details/${user?.id}`}
              state={{ userId: user?.id }}
            >
              Coins
            </Link>
          </div>
        </div>
      </div>

      <div className="create_from_row">
        <div className="project_view_list_item">
          <h2>Name</h2>
          <p>{user?.name ? user?.name : "-"}</p>
        </div>

        <div className="project_view_list_item">
          <h2>Email</h2>
          <p>{user?.email ? user?.email : "-"}</p>
        </div>
        <div className="project_view_list_item">
          <h2>Provider Type</h2>
          <p>{user?.provider_type ? user?.provider_type : "-"}</p>
        </div>
        <div className="project_view_list_item">
          <h2>Provider User Id</h2>
          <p>{user?.provider_user_id ? user?.provider_user_id : "-"}</p>
        </div>

        <div className="project_view_list_item">
          <h2>Created At</h2>
          <p>
            {user?.created_at
              ? moment
                  .utc(user?.created_at, "YYYYMMDD HH:mm:ss")
                  .clone()
                  .tz(defaultTimeZone)
                  .format("DD/MM/YYYY, hh:mm A")
              : "-"}
          </p>
        </div>
        <div className="project_view_list_item">
          <h2>Updated At</h2>
          <p>
            {user?.updated_at
              ? moment
                  .utc(user?.updated_at, "YYYYMMDD HH:mm:ss")
                  .clone()
                  .tz(defaultTimeZone)
                  .format("DD/MM/YYYY, hh:mm A")
              : "-"}
          </p>
        </div>
        <div className="project_view_list_item">
          <h2>Role</h2>
          <p>{user?.role ? user?.role?.name : "-"}</p>
        </div>
        <div className="project_view_list_item">
          <h2>Total Streak</h2>
          <p>{user?.streak ? user?.streak : "-"}</p>
        </div>

        <div className="project_view_list_item">
          <h2>Total Coins</h2>
          <p>
            {user?.coins ? (
              <>
                {user?.coins} {coinIcon}
              </>
            ) : (
              "-"
            )}
          </p>
        </div>
        <div className="project_view_list_item">
          <h2>Premium</h2>
          <p
            className={`question_table_level_text ${
              user?.is_premium === true ? "credited" : "debited"
            }`}
          >
            {user?.is_premium === true
              ? "Yes"
              : user?.is_premium === false
              ? "No"
              : "-"}
          </p>
        </div>
        <div className="project_view_list_item">
          <h2>Total Score</h2>
          <p>{user?.score ? user?.score : "-"}</p>
        </div>
        <div className="project_view_list_item">
          <h2>Level</h2>
          <p>{user?.level ? user?.level : "-"}</p>
        </div>
      </div>
    </div>
  );
};

export default ViewUser;
