import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  backBtnArrow,
  blueMinusIcon,
  bluePlusIcon,
  checkIcon,
  closeIcon,
  crossIcon,
  editIcon,
  editSmallIcon,
  plusColouredIcon,
  saveIcon,
} from "../../icons";
import { Form } from "react-bootstrap";
import Button from "../../component/Common/Button";
import { useDispatch, useSelector } from "react-redux";
import { getTopicList } from "../../store/slice/topicSlice";
import { useForm } from "../../hooks/useForm";
import {
  createQuestion,
  createSelectedQuestions,
} from "../../store/slice/questionSlice";
import Loader from "../../component/Common/Loader";
import { routes } from "../../constants";
import CommonHeading from "../../component/Common/CommonHeading";
import Input from "../../component/Common/Input";
import noTriviaQuestionFound from "../../images/noTriviaQuestionFound.png";

const CreateQuestion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const ref = useRef(false);

  const [showError, setShowError] = useState(false);
  const [showQuesList, setShowQuesList] = useState(false);
  const [iconToggle, setIconToggle] = useState(false);
  const [questionArray, setQuestionArray] = useState([]);
  const [cancelArray, setCancelArray] = useState([]);
  const loadingWhileCallingCreateApi = useSelector(
    (state) => state?.question?.loader
  );
  const createdQues = useSelector(
    (state) => state?.question?.createQuestionList?.data
  );

  const loadingWhileAddingQues = useSelector(
    (state) => state?.question?.loader
  );

  //API call of get topic list
  useEffect(() => {
    dispatch(getTopicList({ start: 0, limits: 100000000 }));
    ref.current = true;
  }, []);

  const topic = useSelector((state) => state?.topic?.topicList);
  const topicOptions = topic?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const initialFValues = {
    topicId: "",
    levelValue: "",
    select_all: true,
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("topicId" in fieldValues) {
      temp.topicId = fieldValues.topicId ? "" : "Please Select Topic";
    }
    if ("levelValue" in fieldValues) {
      temp.levelValue = fieldValues.levelValue ? "" : "Please Select Level";
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  //USE FORM HOOK
  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  //CREATE or UPDATE Questions Handler
  const createQuestionHandler = () => {
    if (validate()) {
      const data = {
        topic_id: values.topicId,
        level: values.levelValue,
      };
      dispatch(
        createQuestion({
          data,
          toast: "Question created successfully",
        })
      );
      setShowQuesList(true);
    }
  };

  const [selectedQuestions, setSelectedQuestions] = useState(
    createdQues?.quiz?.questions?.map((item) => ({
      ...item,
      isChecked: true,
    }))
  );

  useEffect(() => {
    if (createdQues?.quiz?.questions) {
      setSelectedQuestions(
        createdQues?.quiz?.questions?.map((item) => ({
          ...item,
          isChecked: true,
          isEditing: false,
        }))
      );

      setQuestionArray(
        createdQues?.quiz?.questions?.map((item) => ({
          ...item,
          isChecked: true,
          isEditing: false,
        }))
      );

      setCancelArray(
        createdQues?.quiz?.questions?.map((item) => ({
          ...item,
          isChecked: true,
          isEditing: false,
        }))
      );
    }
  }, [createdQues]);

  //ONCHANGE HANDLER OF CHECKBOX WITH QUESTION
  const onChangeSelectedQuesHandler = (e, index, item) => {
    const list = [...selectedQuestions];
    if (typeof index === "string") {
      setValues({ ...values, select_all: e.target.checked });
      const newSelecQuestion = list.map((item) => {
        return {
          ...item,
          isChecked: e.target.checked,
        };
      });
      const newQuestionArray = newSelecQuestion.filter(
        (item) => item.isChecked === true
      );
      setSelectedQuestions(newSelecQuestion);
      setQuestionArray(newQuestionArray);
    } else {
      const updatedItem = { ...list[index], isChecked: e.target.checked };
      list[index] = updatedItem;
      setSelectedQuestions(list);
      setQuestionArray(list);

      if (e.target.checked) {
        questionArray.push(list[index]);
      } else {
        const questionRemove = questionArray.findIndex(
          (question) => question.id === item.id
        );
        if (questionRemove !== -1) {
          questionArray.splice(questionRemove, 1);
        }
      }
      const checkIsCheck =
        list && list.find((item) => item.isChecked === false);
      if (checkIsCheck) {
        setValues({ ...values, select_all: false });
      } else {
        setValues({ ...values, select_all: true });
      }
      setQuestionArray(questionArray);
    }
  };

  //ADD QUESTION HANDLER
  const addQuestionHandler = () => {
    if (questionArray?.length === 0) {
      setErrors({
        ...errors,
        question_with_checkbox: "Please select any question before adding!",
      });
      setShowError(true);
      return;
    } else {
      setShowError(false);
      const data = {
        topic_id: createdQues?.topic_id,
        level: createdQues?.level,
        questions: questionArray,
      };
      dispatch(
        createSelectedQuestions({
          data,
          navigate,
          toast: "Questions added successfully",
        })
      );
      setShowQuesList(false);
    }
  };

  // const plusMinusBtnToggle = (questionId) => {
  //   setIconToggle((prevState) => ({
  //     ...prevState,
  //     [questionId]: !prevState[questionId],
  //   }));
  // };

  //TOGGLE QUESTION EDIT MODE
  const toggleQuestionEditMode = (item) => {
    const questionId = item.id;
    const toggleIcon = selectedQuestions?.map((toggle) => {
      if (toggle.id === questionId) {
        return {
          ...toggle,
          isEditing: !toggle.isEditing,
        };
      }
      return toggle;
    });
    setSelectedQuestions(toggleIcon);
    setQuestionArray(toggleIcon);
  };

  //QUESTION INPUT ONCHANGE
  const handleQuestionInputChange = (e, index) => {
    const updateQuestion = [...selectedQuestions];
    updateQuestion[index].question = e.target.value;
    setSelectedQuestions(updateQuestion);
    setQuestionArray(updateQuestion);
  };

  //OPTION INPUT ONCHANGE
  const handleOptionInputChange = (e, questionIndex, optionIndex) => {
    const { value } = e.target;
    const list = [...selectedQuestions];
    const updatedQuestion = { ...list[questionIndex] };
    //NEW LINE
    if (
      updatedQuestion.options.indexOf(updatedQuestion.answer) === optionIndex
    ) {
      updatedQuestion.answer = value;
    }
    ///
    const updatedOptions = [...updatedQuestion.options];
    updatedOptions[optionIndex] = value;
    updatedQuestion.options = updatedOptions;
    list[questionIndex] = updatedQuestion;
    setSelectedQuestions(list);
    setQuestionArray(list);
  };

  //OBJECT CORRECT ANSWER ONCHANGE
  // const handleObjectCorrectAnswerChange = (event, questionIndex) => {
  //   console.log(questionIndex);
  //   const updateAnswer = [...selectedQuestions];
  //   updateAnswer[questionIndex].answer = event.target.value;
  //   // console.log("updateAnswer", updateAnswer);
  //   setSelectedQuestions(updateAnswer);
  //   setQuestionArray(updateAnswer);
  // };

  //OBJECT CORRECT ANSWER DROPDOWN ONCHANGE
  const handleDropdownAnswerChange = (event, questionIndex) => {
    const updateAns = [...selectedQuestions];
    updateAns[questionIndex].answer = event.target.value;
    setSelectedQuestions(updateAns);
    setQuestionArray(updateAns);
  };

  //CANCEL EDITING QUESTION AND ANSWER
  const cancelEditingQuesOption = (item, id) => {
    const list = [...cancelArray];
    const cancelQuestionIndex = list.findIndex(
      (question) => question.id === id
    );

    if (cancelQuestionIndex !== -1) {
      const originalObj = list[cancelQuestionIndex];

      setSelectedQuestions((prevState) => {
        const updatedSelectedObj = [...prevState];
        updatedSelectedObj[cancelQuestionIndex] = originalObj;
        return updatedSelectedObj;
      });

      setQuestionArray((prevState) => {
        const updateSelectObj = [...prevState];
        updateSelectObj[cancelQuestionIndex] = originalObj;
        return updateSelectObj;
      });
    }
  };

  return (
    <>
      <div className="project_edit_main_content question_list_checkbox_content">
        <CommonHeading
          heading={showQuesList ? "QUESTIONS LIST" : "CREATE QUESTIONS"}
        />

        {loadingWhileCallingCreateApi ? (
          <>
            <Loader message={true} />
          </>
        ) : (
          <>
            <div className="project_edit_main_link">
              <div className="comman_center_btn_container">
                {(!showQuesList || !createdQues) && (
                  <button
                    className="go_back_btn"
                    onClick={() => {
                      navigate(-1);
                      setShowQuesList(false);
                    }}
                  >
                    {backBtnArrow} Back
                  </button>
                )}
              </div>
            </div>

            {!showQuesList && (
              <>
                <div className="create_from_row">
                  <div className="common_filter_input_box first_filter_container">
                    <div className="common_select_box">
                      <h4 className="project_text_area_label required">
                        Topic Id
                      </h4>
                      <Form.Select
                        onChange={(e) => handleInputChange(e, "topicId")}
                        id="topicId"
                        name="topicId"
                        className="select_form"
                        defaultValue={"DEFAULT"}
                      >
                        <option value="DEFAULT" disabled>
                          Select Topic
                        </option>
                        {topicOptions?.map((topic, i) => {
                          return (
                            <option key={i} value={topic.value}>
                              {topic.label}
                            </option>
                          );
                        })}
                      </Form.Select>
                      {errors.topicId && (
                        <span className="err_text">{errors.topicId}</span>
                      )}
                    </div>
                  </div>

                  <div className="common_filter_input_box first_filter_container">
                    <div className="common_select_box">
                      <h4 className="project_text_area_label required">
                        Level
                      </h4>
                      <Form.Select
                        onChange={(e) => handleInputChange(e, "levelValue")}
                        id="levelValue"
                        name="levelValue"
                        className="select_form"
                        defaultValue={"DEFAULT"}
                      >
                        <option value="DEFAULT" disabled>
                          Select Level
                        </option>
                        <option value="EASY">Easy</option>
                        <option value="MEDIUM">Medium</option>
                        <option value="HARD">Hard</option>
                      </Form.Select>
                      {errors.levelValue && (
                        <span className="err_text">{errors.levelValue}</span>
                      )}
                    </div>
                  </div>
                </div>

                <Button
                  className="project_submit_bottom_btn center_back_btn"
                  buttonClassName="comman_btn"
                  onClick={createQuestionHandler}
                  text="Create"
                />
              </>
            )}
            {/* selectedQuestions?.length === 0 || !selectedQuestions ? (
            <p className="no_data_found">No data Found</p> */}
            {loadingWhileAddingQues ? (
              <Loader />
            ) : (
              <>
                {showQuesList ? (
                  <>
                    {createdQues ? (
                      <>
                        <div className="create_from_row">
                          {createdQues?.quiz?.topic && (
                            <div>
                              <div className="project_view_list_item">
                                Topic
                              </div>
                              <p>{createdQues?.quiz?.topic}</p>
                            </div>
                          )}
                          {createdQues?.level && (
                            <div>
                              <div className="project_view_list_item">
                                Level
                              </div>
                              <p
                                className={`question_table_level_text ${
                                  createdQues?.level === "EASY"
                                    ? "credited"
                                    : createdQues?.level === "HARD"
                                    ? "debited"
                                    : createdQues?.level === "MEDIUM"
                                    ? "resolved"
                                    : "-"
                                }`}
                              >
                                {createdQues?.level}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="create_from_row_checkbox_question create-question-edit-input-box-section">
                          <div className="create_question_list_checkbox">
                            <input
                              type="checkbox"
                              id={`question_with_checkbox`}
                              name={"question_with_checkbox"}
                              value="question_with_checkbox"
                              onChange={(e) =>
                                onChangeSelectedQuesHandler(e, "all", "all")
                              }
                              checked={values.select_all}
                              className="create_question_list_checkbox_input"
                            />
                            <label
                              htmlFor={`question_with_checkbox`}
                              className="create_question_list_checkbox_label"
                            >
                              Select all
                            </label>
                          </div>
                          {selectedQuestions
                            ? selectedQuestions?.map((item, i) => {
                                return (
                                  <>
                                    <div
                                      key={i}
                                      className={
                                        item.isEditing
                                          ? "create-question-edit-input-box"
                                          : "create_question_list_checkbox"
                                      }
                                    >
                                      <div className="create-question-edit-input-tag">
                                        <input
                                          type="checkbox"
                                          id={`question_with_checkbox${i}`}
                                          name={"question_with_checkbox" + i}
                                          value="question_with_checkbox"
                                          onChange={(e) =>
                                            onChangeSelectedQuesHandler(
                                              e,
                                              i,
                                              item
                                            )
                                          }
                                          // checked={selectedQuestions?.includes(item)}
                                          checked={item.isChecked}
                                          className="create_question_list_checkbox_input"
                                        />
                                        {item.isEditing ? (
                                          <div className="project_edit_feature_input">
                                            <input
                                              type="text"
                                              value={item.question}
                                              onChange={(e) =>
                                                handleQuestionInputChange(e, i)
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <label
                                            htmlFor={`question_with_checkbox${i}`}
                                            className="create_question_list_checkbox_label"
                                          >
                                            {item.question}
                                          </label>
                                        )}

                                        {/* )} */}
                                      </div>
                                      {/* 
                                      <button
                                        onClick={() =>
                                          toggleQuestionEditMode(item)
                                        }
                                        className="create-question-edit-save-btn"
                                      >
                                        {item.isEditing ? (
                                          <span>{saveIcon} Save</span>
                                        ) : (
                                          <span>{editSmallIcon} Edit</span>
                                        )}
                                      </button> */}
                                      {/* {item.isEditing && (
                                        <button
                                          onClick={() =>
                                            cancelEditingQuesOption(
                                              item,
                                              item.id
                                            )
                                          }
                                        >
                                          {closeIcon}
                                        </button>
                                      )} */}
                                      {item.isEditing ? (
                                        <>
                                          <button
                                            onClick={() =>
                                              toggleQuestionEditMode(item)
                                            }
                                            className="create-question-edit-input-check-btn"
                                            // className="create-question-edit-save-btn"
                                          >
                                            {/* {saveIcon} Save */}
                                            {checkIcon}
                                          </button>
                                          <button
                                            onClick={() => {
                                              toggleQuestionEditMode(item);
                                              cancelEditingQuesOption(
                                                item,
                                                item.id
                                              );
                                            }}
                                            className="create-question-edit-input-close-btn"
                                          >
                                            {crossIcon}
                                          </button>
                                        </>
                                      ) : (
                                        // <button
                                        //   onClick={() =>
                                        //     toggleQuestionEditMode(item)
                                        //   }
                                        //   className="create-question-edit-save-btn"
                                        // >
                                        //   {editSmallIcon} Edit
                                        // </button>
                                        <button
                                          onClick={() =>
                                            toggleQuestionEditMode(item)
                                          }
                                          className="create-question-edit-save-btn"
                                        >
                                          {editSmallIcon}
                                        </button>
                                      )}
                                    </div>

                                    <div className="project_view_list_item_body mb-3 mt-3">
                                      <div className="project_view_list_item_option_answer_row">
                                        {item.options.map((option, j) => {
                                          return (
                                            <div
                                              className={`project_view_list_item_option_answer_col project_edit_feature_input ${
                                                option === item.answer &&
                                                "project_view_list_item_option_correct_answer"
                                              }
                                               
                                              `}
                                              key={j}
                                            >
                                              {item.isEditing ? (
                                                <input
                                                  type="text"
                                                  value={option}
                                                  onChange={(e) =>
                                                    handleOptionInputChange(
                                                      e,
                                                      i,
                                                      j
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <p className="project_view_list_item_option_answer_col_text">
                                                  {option}
                                                </p>
                                              )}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>

                                    {item.isEditing ? (
                                      <>
                                        {/* <div className="project_edit_feature_input mb-3 create-question-edit-input-answer-box">
                                          <label>Answer: </label>
                                          <input
                                            type="text"
                                            value={item.answer}
                                            onChange={(e) =>
                                              handleObjectCorrectAnswerChange(
                                                e,
                                                i
                                              )
                                            }
                                          />
                                        </div> */}
                                      </>
                                    ) : (
                                      <div className="answer_dropdown_with_heading_container">
                                        <label className="answer_dropdown_heading">
                                          Select Answer:
                                        </label>
                                        <Form.Select
                                          value={item.answer}
                                          className="select_answer_dropdown"
                                          onChange={(e) =>
                                            handleDropdownAnswerChange(e, i)
                                          }
                                        >
                                          <option value="" disabled>
                                            Select Options...
                                          </option>
                                          {item.options.map((option, k) => {
                                            return (
                                              <option value={option} key={k}>
                                                {option}
                                              </option>
                                            );
                                          })}
                                        </Form.Select>
                                      </div>
                                    )}
                                  </>
                                );
                              })
                            : ""}
                          {showError && (
                            <span className="err_text">
                              {errors.question_with_checkbox}
                            </span>
                          )}
                        </div>
                        <Button
                          className="project_submit_bottom_btn center_back_btn"
                          buttonClassName="comman_btn m-0"
                          onClick={addQuestionHandler}
                          text="Add"
                        />
                      </>
                    ) : (
                      <div className="no_question_found_container">
                        <img
                          src={noTriviaQuestionFound}
                          alt="no-question-found"
                        />
                        <p className="no_data_found_question">
                          Sorry 🙃....
                          <br /> No Data found as per your Search 📚
                          <br />
                          Please try once again 🙂!
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CreateQuestion;
