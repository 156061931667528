import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getCorruptQues,
  updateCorruptQues,
} from "../../store/slice/corruptSlice";
import { toast } from "react-toastify";

const DeleteCorruptQuestion = ({
  closeDeleteModal,
  deleteId,
  setOpenDeleteModal,
  setDeleteId,
  // totalCount={totalCount?.toString()}
  pageNumber,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //DELETE QUESTION HANDLER
  const deleteQuestionHandler = () => {
    const data = {
      id: deleteId,
      delete_status: true,
    };

    dispatch(
      updateCorruptQues({
        data,
        cb: (err, res) => {
          if (err) {
            // console.log("err", err);
          } else {
            closeDeleteModal();
            toast.success("Question deleted successfully", {
              autoClose: 3000,
            });
            dispatch(getCorruptQues({}));
          }
        },
      })
    );
  };
  return (
    <div className="modal_container">
      <div className="modal_heading_2">
        <h1 className="modal_main_heading_with_desc">Are you sure?</h1>
        <p>
          {deleteId && deleteId.length > 1
            ? "You want to delete all selected questions!"
            : "You want to delete this question!"}
        </p>
      </div>
      <div className="modal_btn_row">
        <button
          onClick={() => closeDeleteModal()}
          className="modal_btn modal_cancel_btn"
        >
          Cancel
        </button>
        <button
          className="modal_btn modal_delete_btn"
          onClick={() => deleteQuestionHandler(deleteId)}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteCorruptQuestion;
