import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios, { authHeaders } from "../../helpers/axios";
import { routes } from "../../constants";

export const getCorruptQues = createAsyncThunk(
  "getCorruptQues",
  async (data) => {
    try {
      const response = await Axios.get(
        `/question/answer/not-in-option?topic_id=${data.topic || ""}&level=${
          data.level || ""
        }`,
        authHeaders()
      );
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

export const updateCorruptQues = createAsyncThunk(
  "updateCorruptQues",
  async (data) => {
    try {
      const response = await Axios.put(`/question`, data.data, authHeaders());
      if (response.status === 201 && !data.cb) {
        toast.success(data.toast, { autoClose: 3000 });
        data.navigate(`${routes.corrupted}/page/1`);
        return response.data;
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

export const updateCorruptQuesForEdit = createAsyncThunk(
  "updateCorruptQuesForEdit",
  async (data) => {
    try {
      const response = await Axios.put(
        `/question/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 201 && !data.cb) {
        toast.success(data.toast, { autoClose: 3000 });
        data.navigate(`${routes.corrupted}/page/1`);
        return response.data;
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

const corruptSlice = createSlice({
  name: "corrupt",
  initialState: {
    corruptQuesList: [],
    updateCorruptQues: "",
    editIndividualQues: "",
    loader: false,
    error: "",
    // totalCount: 0,
  },
  reducer: {},
  extraReducers: (builder) => {
    //GET QUESTION
    builder.addCase(getCorruptQues.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getCorruptQues.fulfilled, (state, action) => {
      state.loader = false;
      state.corruptQuesList = action.payload;
      //   state.totalCount = action.payload;
    });
    builder.addCase(getCorruptQues.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //UPDATE QUESTION LIST
    builder.addCase(updateCorruptQues.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateCorruptQues.fulfilled, (state, action) => {
      state.loader = false;
      state.updateCorruptQues = action.payload;
    });
    builder.addCase(updateCorruptQues.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //EDIT INDIVIDUAL QUESTION
    builder.addCase(updateCorruptQuesForEdit.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateCorruptQuesForEdit.fulfilled, (state, action) => {
      state.loader = false;
      state.editIndividualQues = action.payload;
    });
    builder.addCase(updateCorruptQuesForEdit.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default corruptSlice.reducer;
