import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios, { authHeaders } from "../../helpers/axios";

export const getQuizListByUserId = createAsyncThunk(
  "getQuizListByUserId",
  async (data) => {
    try {
      const response = await Axios.get(
        `/quiz?user_id=${data.user_id}&level=${data.level || ""}&topic_id=${
          data.topic || ""
        }&limits=${data.limits || 10}`,
        authHeaders()
      );
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const leaderBoardData = createAsyncThunk("leaderBoardData", async () => {
  try {
    const response = await Axios.get(
      `/user/get/leader?by_date=&by_month=&by_week=`,
      authHeaders()
    );
    // console.log(response.data.data);
    return response.data.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
});

export const getQuizQuesOfUserByQuizId = createAsyncThunk(
  "getQuizQuestionsOfUserByQuizId",
  async (data) => {
    try {
      const response = await Axios.get(`/quiz/${data.id}`, authHeaders());
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

export const getQuizList = createAsyncThunk("getQuizList", async (data) => {
  try {
    const response = await Axios.get(
      `/quiz?start=${data.start || 0}&limits=${data.limits || 10}&level=${
        data.level || ""
      }&name=${data.user}&topic_id=${data.topic}`,
      authHeaders()
    );
    return response.data.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
});

const quizSlice = createSlice({
  name: "quiz",
  initialState: {
    quizList: [],
    leaderBoard: [],
    quizListByUserId: [],
    quizQuesOfUserByQuizId: {},
    loader: false,
    error: "",
    totalCount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    //GET QUIZ LIST
    builder.addCase(getQuizList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getQuizList.fulfilled, (state, action) => {
      state.loader = false;
      state.quizList = action.payload.rows;
      state.totalCount = action.payload.count;
    });
    builder.addCase(getQuizList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //GET LEADER BOARD DATA
    builder.addCase(leaderBoardData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(leaderBoardData.fulfilled, (state, action) => {
      state.loader = false;
      state.leaderBoard = action.payload.rows;
    });
    builder.addCase(leaderBoardData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //GET QUIZ LIST BY USER ID
    builder.addCase(getQuizListByUserId.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getQuizListByUserId.fulfilled, (state, action) => {
      state.loader = false;
      state.quizListByUserId = action.payload.rows;
    });
    builder.addCase(getQuizListByUserId.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //GET QUIZ QUESTION OF USER BY QUIZ ID
    builder.addCase(getQuizQuesOfUserByQuizId.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getQuizQuesOfUserByQuizId.fulfilled, (state, action) => {
      state.loader = false;
      state.quizQuesOfUserByQuizId = action.payload;
    });
    builder.addCase(getQuizQuesOfUserByQuizId.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default quizSlice.reducer;
