import React from "react";
import {
  getAvatarIcons,
  updateAvatarIcons,
} from "../../store/slice/avatarSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const DeleteAvatar = ({
  avatarId,
  setAvatarId,
  closeDeleteModal,
  setOpenDeleteModal,
}) => {
  const dispatch = useDispatch();

  //DELETE AVATAR HANDLER
  const deleteAvatarHandler = (avatarId) => {
    const data = {
      delete_status: true,
      id: avatarId,
    };

    dispatch(
      updateAvatarIcons({
        data,
        cb: (err, res) => {
          if (err) {
            console.log("error", err);
          } else {
            setOpenDeleteModal(false);
            setAvatarId([]);
            toast.error("Avatar deleted successfully", { autoClose: 3000 });
            dispatch(getAvatarIcons({}));
          }
        },
      })
    );
  };

  return (
    <div className="modal_container">
      <div className="modal_heading_2">
        <h1 className="modal_main_heading_with_desc">Are you sure?</h1>
        <p>you want to delete this Avatar!</p>
      </div>
      <div className="modal_btn_row">
        <button
          onClick={() => closeDeleteModal()}
          className="modal_btn modal_cancel_btn"
        >
          Cancel
        </button>
        <button
          className="modal_btn modal_delete_btn"
          onClick={() => deleteAvatarHandler(avatarId)}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteAvatar;
