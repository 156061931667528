import React, { useState } from "react";
import { coinIcon, deleteIcon, eyeIcon } from "../../icons";
import { tableHeadItemsForUserList } from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";
import { Link } from "react-router-dom";

const UserList = ({ list, deleteIconHandler }) => {
  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  return (
    <>
      {list?.length === 0 || !list ? (
        <p className="no_data_found">No data Found</p>
      ) : (
        <table className="custom_data_table">
          <thead className="custom_data_table_head">
            <tr>
              {tableHeadItemsForUserList.map((ques, i) => {
                return (
                  <th className="custom_data_table_heading text-center" key={i}>
                    {ques.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="custom_data_table_body">
            {list?.map((item, i) => {
              return (
                <tr className="custom_data_table_row" key={item.id}>
                  <td className="custom_data_table_item table_item">
                    <img
                      alt="userProfile"
                      className={`custom_data_table_img cursor_pointer_image_view`}
                      src={
                        item.profile_pic !== null
                          ? process.env.REACT_APP_FILE_BASE_URL +
                            "/" +
                            item.profile_pic
                          : item.avtar !== null
                          ? process.env.REACT_APP_FILE_BASE_URL +
                            "/" +
                            item.avtar.image
                          : process.env.REACT_APP_DEFAULT_IMG
                      }
                      onClick={() => {
                        setImgZoom(true);
                        setImgSrc(
                          item.profile_pic !== null
                            ? process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                item.profile_pic
                            : item.avtar !== null
                            ? process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              item.avtar.image
                            : process.env.REACT_APP_DEFAULT_IMG
                        );
                      }}
                    />
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.name ? item.name : "-"}
                  </td>
                  <td className="custom_data_table_item table_item">
                    <span
                      className={`question_table_level_text ${
                        item.is_premium === true ? "credited" : "debited"
                      }`}
                    >
                      {item.is_premium === true
                        ? "Yes"
                        : item.is_premium === false
                        ? "No"
                        : "-"}
                    </span>
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.coins ? (
                      <>
                        {item.coins} {coinIcon}
                      </>
                    ) : (
                      "-"
                    )}
                  </td>

                  <td className="custom_data_table_item table_item">
                    {item.role ? item.role.name : "-"}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.score ? (
                      <span className="question_table_level_text resolved">
                        {item.score}
                      </span>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.created_at
                      ? moment
                          .utc(item.created_at, "YYYYMMDD HH:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("DD/MM/YYYY, hh:mm A")
                      : "-"}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.updated_at
                      ? moment
                          .utc(item.updated_at, "YYYYMMDD HH:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("DD/MM/YYYY, hh:mm A")
                      : "-"}
                  </td>
                  <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                    <div className="custom_data_table_view_edit_btn_item_row">
                      <Link
                        className="custom_data_table_view_edit_item_btn "
                        aria-label="view"
                        to={`/view-user/${item.id}`}
                        state={{ userQuizDetails: item }}
                        // onClick={() => userQuizDetailsHandler(item.id)}
                      >
                        {eyeIcon}
                      </Link>
                      <button
                        aria-label="delete"
                        className="custom_data_table_view_edit_item_btn delete_btn"
                        onClick={() => deleteIconHandler(item.id)}
                      >
                        {deleteIcon}
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <div
        onClick={() => setImgZoom(false)}
        className={`oc-table-img-modal-bg ${
          imgZoom && "oc-table-img-modal-open"
        }`}
      ></div>
      <div
        className={`oc-table-img-modal ${imgZoom && "oc-table-img-modal-open"}`}
      >
        <img src={imgSrc} alt="profile" />
      </div>
    </>
  );
};

export default UserList;
