import React from "react";
import { useDispatch } from "react-redux";
import { getRewardList, updateReward } from "../../store/slice/rewardSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const DeleteReward = ({
  closeDeleteModal,
  deleteModalData,
  setOpenDeleteModal,
  setDeleteModalData,
  totalCount,
  pageNumber,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteRewardHandler = (deleteModalData) => {
    const data = {
      delete_status: true,
    };
    dispatch(
      updateReward({
        data,
        id: deleteModalData,
        cb: (err, res) => {
          if (err) {
            console.log("error", err);
          } else {
            setOpenDeleteModal(false);
            setDeleteModalData("");
            toast.error("Daily Reward deleted successfully", {
              autoClose: 3000,
            });
            if (
              totalCount &&
              totalCount[totalCount.toString().length - 1] === 1 &&
              +pageNumber !== 1
            ) {
              navigate(`/reward/page/${pageNumber - 1}`);
            } else {
              dispatch(
                getRewardList({ start: (pageNumber - 1) * 10, limits: 10 })
              );
            }
          }
        },
      })
    );
  };
  return (
    <div className="modal_container">
      <div className="modal_heading_2">
        <h1 className="modal_main_heading_with_desc">Are you sure?</h1>
        <p>you want to delete this Daily reward!</p>
      </div>
      <div className="modal_btn_row">
        <button
          onClick={() => closeDeleteModal()}
          className="modal_btn modal_cancel_btn"
        >
          Cancel
        </button>
        <button
          className="modal_btn modal_delete_btn"
          onClick={() => deleteRewardHandler(deleteModalData)}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteReward;
