import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonHeading from "../../component/Common/CommonHeading";
import { getLevelList } from "../../store/slice/levelSlice";
import Loader from "../../component/Common/Loader";
import { Link, useParams } from "react-router-dom";
import { routes } from "../../constants";
import LevelRewardList from "./LevelRewardList";
import { Modal } from "react-bootstrap";
import DeleteLevelReward from "./DeleteLevelReward";
import Pagination from "../../component/Pagination/Pagination";

const LevelReward = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const ref = useRef(false);
  const { page_number } = params;

  const [pageN, setPageN] = useState(page_number - 1);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState("");

  const { loader, totalCount, levelList } = useSelector(
    (state) => state?.level
  );

  let starts = (page_number - 1) * 10;

  console.log("pageN", pageN, "page_number", page_number, "starts", starts);

  useEffect(() => {
    if (ref.current) {
      dispatch(getLevelList({ start: (page_number - 1) * 10, limits: 10 }));
    }
  }, [pageN]);

  useEffect(() => {
    dispatch(getLevelList({ start: (page_number - 1) * 10, limits: 10 }));
    ref.current = true;
  }, []);

  //VIEW ICON HANDLER
  // const viewIconHandler = () => {};

  //EDIT ICON HANDLER
  // const editIconHandler = () => {};

  // DELETE ICON HANDLER
  const deleteIconHandler = (id) => {
    setDeleteModalData(id);
    setOpenDeleteModal(true);
  };

  // CLOSE DELETE MODAL
  const closeDeleteModal = () => {
    setDeleteModalData("");
    setOpenDeleteModal(false);
  };

  //PAGE-CHANGE HANDLER
  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="LEVEL REWARD" />
        {loader ? (
          <Loader />
        ) : (
          <>
            <div className="creat_edit_project_btn_row_flex">
              <div>
                <Link
                  to={routes.createLevelReward}
                  className="comman_btn ml-auto"
                >
                  Create
                </Link>
              </div>
            </div>

            <div className="custom_data_table_content">
              <LevelRewardList
                list={levelList}
                // viewIconHandler={viewIconHandler}
                // editIconHandler={editIconHandler}
                deleteIconHandler={deleteIconHandler}
              />
            </div>
            {totalCount > 0 && (
              <Pagination
                totalRecords={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </>
        )}
      </div>

      <Modal
        className="modal_main_component modal_background_content modal_max_w_500"
        dialogClassName="custom-dialog"
        show={openDeleteModal}
        onHide={closeDeleteModal}
        centered
      >
        <DeleteLevelReward
          closeDeleteModal={closeDeleteModal}
          deleteModalData={deleteModalData}
          setOpenDeleteModal={setOpenDeleteModal}
          setDeleteModalData={setDeleteModalData}
          totalCount={totalCount.toString()}
          pageNumber={page_number}
        />
      </Modal>
    </>
  );
};

export default LevelReward;
