import React, { useEffect, useState } from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import { useDispatch, useSelector } from "react-redux";
import { addIcon, backBtnArrow, editIcon } from "../../icons";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Input from "../../component/Common/Input";
import Button from "../../component/Common/Button";
import { useForm } from "../../hooks/useForm";
import { addTopic, updateTopic } from "../../store/slice/topicSlice";
import Loader from "../../component/Common/Loader";
import { imagePreviewProps } from "../../helpers/commonFunction";
import { fileUpload } from "../../store/slice/fileUploadSlice";

const CreateTopic = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [uploading, setUploading] = useState(false);
  const loading = useSelector((state) => state.topic.loader);
  const editComponent = location?.state?.topic;

  let initialImagePreview;
  if (editComponent?.topic_image) {
    initialImagePreview = `${process.env.REACT_APP_FILE_BASE_URL}/${editComponent?.topic_image}`;
  }

  // INITIAL VALUES
  const initialFValues = {
    name: editComponent ? editComponent.name : "",
    // topic_image: editComponent ? editComponent.topic_image : null,
    topic_image:
      editComponent && editComponent.topic_image
        ? editComponent.topic_image
        : "",
    // topic_image_preview: editComponent ? initialImagePreview : "",
    topic_image_preview: editComponent
      ? editComponent.topic_image
        ? initialImagePreview
        : // ? `${process.env.REACT_APP_FILE_BASE_URL}/${editComponent.topic_image}`
          process.env.REACT_APP_DEFAULT_IMG
      : "",
  };

  // useEffect(() => {
  //   if (!editComponent.topic_image) {
  //     setErrors({
  //       ...errors,
  //       topic_image: "Image required !",
  //     });
  //   }
  // }, []);

  //ERROR HANDLING VALIDATION
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("topic_image" in fieldValues) {
      temp.topic_image = "";
      if (!fieldValues.topic_image) {
        temp.topic_image = "Image required !";
      }

      if (fieldValues.topic_image.size > 5000000) {
        temp.topic_image = "Image size should be less than 5Mb !";
        setValues({
          ...values,
          topic_image: "",
          topic_image_preview: "",
        });
      }
      if (
        fieldValues.topic_image.name &&
        !fieldValues.topic_image.name?.match(/.(jpg|jpeg|png|gif)$/i)
      ) {
        temp.topic_image = "Please select a valid Image !";
        setValues({
          ...values,
          topic_image: "",
          topic_image_preview: "",
        });
      }
    }

    if ("name" in fieldValues) {
      temp.name = fieldValues.name.trim() ? "" : "Topic name is required !";
    }

    setErrors({ ...temp });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  //USE FORM HOOK
  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  //IMAGE REMOVE HANDLER
  const imageRemoveHandler = () => {
    setValues({ ...values, topic_image: "", topic_image_preview: "" });
  };

  //CREATE or UPDATE TOPIC NAME HANDLER
  const createUpdateTopicNameHandler = async () => {
    if (validate()) {
      setUploading(true);
      const formData = new FormData();
      let imageUrl;
      if (values.topic_image && typeof values.topic_image !== "string") {
        formData.append("file", values.topic_image);
        imageUrl = await dispatch(fileUpload(formData));
        formData.delete("file");
      } else {
        imageUrl = values.topic_image;
      }

      setUploading(false);
      const data = {
        name: values.name,
        topic_image:
          typeof values.topic_image !== "string" ? imageUrl.payload : imageUrl,
      };

      editComponent
        ? dispatch(
            updateTopic({
              data,
              id: editComponent.id,
              navigate,
              toast: "Topic updated successfully",
            })
          )
        : dispatch(
            addTopic({ data, navigate, toast: "Topic created successfully" })
          );
    }
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading
          heading={editComponent ? "EDIT TOPIC" : "CREATE TOPIC"}
        />
        {loading || uploading ? (
          <Loader />
        ) : (
          <>
            <div className="project_edit_main_link">
              <div className="comman_center_btn_container">
                <button className="go_back_btn" onClick={() => navigate(-1)}>
                  {backBtnArrow} Back
                </button>
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>
              <div className="create_from_row">
                <div>
                  <label className="project_text_area_label img_label required">
                    Topic Image
                  </label>
                  <div className="project_edit_feature_input_file">
                    <Input
                      className="create_from_input_content"
                      labelClassName="create_from_label "
                      errorClassName="err_text"
                      type="file"
                      id={`topic_image`}
                      labelOtherProps={
                        <span>{!values.topic_image ? addIcon : editIcon}</span>
                      }
                      name="topic_image"
                      accept=".jpg, .jpeg, .png"
                      onChange={handleInputChange}
                      onClick={(e) => (e.target.value = null)}
                      imageProps={imagePreviewProps(
                        values.topic_image,
                        values.topic_image_preview,
                        imageRemoveHandler
                      )}
                    />
                  </div>
                  {errors?.topic_image && (
                    <span className="err_text">{errors.topic_image}</span>
                  )}
                </div>

                <Input
                  className="project_edit_feature_input"
                  errorClassName="err_text"
                  labelClassName="required"
                  type="text"
                  placeholder="Enter Topic Name"
                  label="Topic Name"
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors?.name || ""}
                />
              </div>
            </div>
            <Button
              className="project_submit_bottom_btn center_back_btn"
              buttonClassName="comman_btn"
              onClick={createUpdateTopicNameHandler}
              text={editComponent ? "Update" : "Add"}
            />
          </>
        )}
      </div>
    </>
  );
};

export default CreateTopic;
