import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

export const addTopic = createAsyncThunk("addTopic", async (data) => {
  try {
    const response = await Axios.post(`/topic`, data.data, authHeaders());
    if (response.status === 201) {
      toast.success(data.toast, {
        autoClose: 3000,
      });
      data.navigate(`${routes.topic}/page/1`);
    }
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
  }
});

export const updateTopic = createAsyncThunk("updateTopic", async (data) => {
  try {
    const response = await Axios.put(
      `/topic/${data.id}`,
      data.data,
      authHeaders()
    );
    if (response.status === 201 && !data.cb) {
      toast.warning(data.toast, {
        autoClose: 3000,
      });
      data.navigate(`${routes.topic}/page/1`);
      return response.data;
    }
    if (data.cb) {
      return data.cb(null, response.data);
    }
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
  }
});

export const getTopicList = createAsyncThunk("getTopicList", async (data) => {
  try {
    const response = await Axios.get(
      `/topic?start=${data.start || 0}&limits=${data.limits || 10}&dropdown=${
        data.dropdown || ""
      }`,
      authHeaders()
    );
    return response.data.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
});

export const getTopicListById = createAsyncThunk(
  "getTopicListById",
  async (data) => {
    try {
      const response = await Axios.get(
        `/topic/${data.id}?level=${data.name || ""}}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

const topicSlice = createSlice({
  name: "topic",
  initialState: {
    createTopic: {},
    editTopic: {},
    topicList: [],
    topicListById: {},
    topicCount: 0,
    loader: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    //CREATE TOPIC
    builder.addCase(addTopic.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(addTopic.fulfilled, (state, action) => {
      state.loader = false;
      state.createTopic = action.payload;
    });
    builder.addCase(addTopic.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //UPDATE TOPIC LIST
    builder.addCase(updateTopic.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateTopic.fulfilled, (state, action) => {
      state.loader = false;
      state.editTopic = action.payload;
    });
    builder.addCase(updateTopic.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //GET TOPIC LIST
    builder.addCase(getTopicList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getTopicList.fulfilled, (state, action) => {
      state.loader = false;
      state.topicList = action.payload.rows;
      state.topicCount = action.payload.count;
    });
    builder.addCase(getTopicList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //GET TOPIC LIST BY ID
    builder.addCase(getTopicListById.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getTopicListById.fulfilled, (state, action) => {
      state.loader = false;
      state.topicListById = action.payload.rows;
    });
    builder.addCase(getTopicListById.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default topicSlice.reducer;
