import React from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import { backBtnArrow, closeIcon } from "../../icons";
// import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";

const ViewQuestion = (props) => {
  const { viewModalData, closeViewModal } = props;
  // const navigate = useNavigate();
  // const location = useLocation();
  // const details = location?.state?.questionDetails;
  const details = viewModalData;
  // const filters = location?.state?.value;
  // const { page_number, value } = location?.state;

  return (
    <div className="modal_container">
      <div className="modal_heading">
        <h1 className="modal_main_heading">VIEW QUESTION</h1>
        <button className="modal_close_btn" onClick={() => closeViewModal()}>
          {closeIcon}
        </button>
      </div>
      <div className="project_edit_main_content px-0">
        {/* <CommonHeading heading="VIEW QUESTION" /> */}
        {/* <div className="project_edit_main_link">
          <div className="comman_center_btn_container">
            <button
            className="go_back_btn"
            onClick={() =>
              navigate(`/question/page/${page_number}`, {
                state: {
                  value,
                  value_page: page_number,
                },
              })
            }
          >
            {backBtnArrow} Back
          </button>
            <button
              className="modal_close_btn"
              onClick={() => closeViewModal()}
            >
              {closeIcon}
            </button>
          </div>
        </div> */}

        <div className="create_from_row">
          {details?.topic?.name && (
            <div>
              <div className="project_view_list_item">Topic</div>
              <p>{details.topic.name}</p>
            </div>
          )}

          {details?.level && (
            <div>
              <div className="project_view_list_item">Level</div>
              <p
                className={`question_table_level_text
                ${
                  details.level === "EASY"
                    ? "credited"
                    : details.level === "HARD"
                    ? "debited"
                    : details.level === "MEDIUM"
                    ? "resolved"
                    : "-"
                }`}
              >
                {details.level}
              </p>
            </div>
          )}
          {details?.created_at && (
            <div>
              <div className="project_view_list_item">Created At</div>
              <p>
                {moment
                  .utc(details.created_at, "YYYYMMDD, hh:mm:ss")
                  .clone()
                  .tz(defaultTimeZone)
                  .format("DD/MM/YYYY, hh:mm A")}
              </p>
            </div>
          )}
          {details?.updated_at && (
            <div>
              <div className="project_view_list_item">Updated At</div>
              <p>
                {moment
                  .utc(details.updated_at, "YYYYMMDD, hh:mm:ss")
                  .clone()
                  .tz(defaultTimeZone)
                  .format("DD/MM/YYYY, hh:mm A")}
              </p>
            </div>
          )}
        </div>

        {/* <h2 className="common_heading second_heading_in_page">Options</h2> */}

        <div className="question_options_space">
          <div className="project_view_list_item">
            <div className="project_view_list_item_question">Question❓</div>
            <p>{details?.question}</p>
          </div>

          <div className="project_view_list_item">
            <div className="project_view_list_item_body">
              <div className="project_view_list_item_option_answer_row">
                {details?.options?.map((option, i) => {
                  return (
                    <div
                      className={`project_view_list_item_option_answer_col ${
                        option === details.answer &&
                        "project_view_list_item_option_correct_answer"
                      }`}
                      key={i}
                    >
                      <p className="project_view_list_item_option_answer_col_text">
                        {option}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* <h2 className="common_heading second_heading_in_page">Your Answer</h2> */}
        {/* <div className="project_licence_main_div">
        <div className="customer_properties_column coupon_column">
          <div className="customer_properties_other_details licence_inside_div">
            <div className="customer_properties_other_details_container">
              <span className="customer_properties_other_details_licence_label coupon_label">
                {details?.answer}
              </span>
            </div>
          </div>
        </div>
      </div> */}
      </div>
    </div>
  );
};

export default ViewQuestion;
