import React, { useEffect, useRef, useState } from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import { getCoinsHistoryList } from "../../store/slice/coinSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../component/Common/Loader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../constants";
import { tableHeadItemsForCoinHistory } from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";
import { coinIcon, eyeIcon } from "../../icons";
import Pagination from "../../component/Pagination/Pagination";
import { Form, Modal } from "react-bootstrap";
import ViewCoinHistory from "./ViewCoinHistory";
import { useForm } from "../../hooks/useForm";
import { getUserListForDropDown } from "../../store/slice/userSlice";

const CoinHistory = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { page_number } = params;
  const ref = useRef(false);
  const navigate = useNavigate();

  const [pageN, setPageN] = useState(page_number - 1);
  const [viewModalData, setViewModalData] = useState("");
  const [openViewModal, setOpenViewModal] = useState(false);
  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  const coinsList = useSelector((state) => state?.coins?.coinList?.rows);
  const totalCount = useSelector((state) => state?.coins?.coinList?.count);
  const isLoading = useSelector((state) => state?.coins?.loader);
  const dropDownUserList = useSelector((state) => state.user?.dropDownUserList);

  const nameOption = dropDownUserList.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const initialFValues = {
    coin_status: "",
    name: "",
  };

  //USE FORM HOOK
  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  //USE-EFFECT FOR FILTER
  useEffect(() => {
    if (ref.current) {
      if (page_number !== 1) {
        navigate("/coins/page/1");
      }
      dispatch(
        getCoinsHistoryList({
          coin_status: values.coin_status === "" ? "" : values.coin_status,
          name: values.name === "" ? "" : values.name,
          start: (page_number - 1) * 50,
          limits: 50,
        })
      );
    }
  }, [values.coin_status, values.name]);

  //USE-EFFECT FOR PAGINATION
  useEffect(() => {
    if (ref.current) {
      dispatch(
        getCoinsHistoryList({
          coin_status: values.coin_status === "" ? "" : values.coin_status,
          name: values.name === "" ? "" : values.name,
          start: (page_number - 1) * 50,
          limits: 50,
        })
      );
    }
  }, [pageN]);

  //USE-EFFECT FOR INITIAL RENDERING
  useEffect(() => {
    dispatch(
      getCoinsHistoryList({
        coin_status: values.coin_status === "" ? "" : values.coin_status,
        name: values.name === "" ? "" : values.name,
        start: (page_number - 1) * 50,
        limits: 50,
      })
    );
    dispatch(getUserListForDropDown({ dropDown: true, limits: 2000 }));
    ref.current = true;
  }, []);

  //ON-CHANGE PAGINATION
  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  //VIEW ICON HANDLER
  const viewIconHandler = (item) => {
    setOpenViewModal(true);
    setViewModalData(item);
  };

  //CLOSE VIEW MODAL
  const closeViewModal = () => {
    setOpenViewModal(false);
    setViewModalData("");
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="COINS HISTORY" />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="creat_edit_project_btn_row_flex">
              <div>
                <Link to={routes.createCoins} className="comman_btn ml-auto">
                  Create
                </Link>
              </div>

              <div className="common_all_filter_row justify-content-end">
                <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      id="name"
                      name="name"
                      className="select_form filter_box"
                      value={values.name}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Filter By Name...
                      </option>
                      <option value="">All</option>
                      {nameOption.map((item, i) => (
                        <option value={item.label} key={i}>
                          {item.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
                <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      id="coin_status"
                      name="coin_status"
                      className="select_form"
                      value={values.coin_status}
                      onChange={(e) => handleInputChange(e, "coin_status")}
                    >
                      <option value="" disabled>
                        Filter by Coin Status...
                      </option>
                      <option value="">All</option>
                      <option value="CREDITED">Credited</option>
                      <option value="DEBITED">Debited</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
            </div>

            <div className="custom_data_table_content">
              {coinsList?.length === 0 || !coinsList ? (
                <p className="no_data_found">No data Found</p>
              ) : (
                <table className="custom_data_table">
                  <thead className="custom_data_table_head">
                    <tr>
                      {tableHeadItemsForCoinHistory.map((coin, i) => {
                        return (
                          <th
                            className="custom_data_table_heading text-center"
                            key={i}
                          >
                            {coin.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="custom_data_table_body">
                    {coinsList?.map((item, i) => {
                      return (
                        <tr className="custom_data_table_row" key={item.id}>
                          <td className="custom_data_table_item table_item">
                            <img
                              src={
                                item.user && item.user.profile_pic !== null
                                  ? process.env.REACT_APP_FILE_BASE_URL +
                                    "/" +
                                    item.user.profile_pic
                                  : item.user.avtar !== null
                                  ? process.env.REACT_APP_FILE_BASE_URL +
                                    "/" +
                                    item.user.avtar.image
                                  : process.env.REACT_APP_DEFAULT_IMG
                              }
                              alt="profile"
                              className={`custom_data_table_img`}
                              onClick={() => {
                                setImgZoom(true);
                                setImgSrc(
                                  item.user && item.user.profile_pic !== null
                                    ? process.env.REACT_APP_FILE_BASE_URL +
                                        "/" +
                                        item.user.profile_pic
                                    : item.user.avtar !== null
                                    ? process.env.REACT_APP_FILE_BASE_URL +
                                      "/" +
                                      item.user.avtar.image
                                    : process.env.REACT_APP_DEFAULT_IMG
                                );
                              }}
                            />
                          </td>
                          <td className="custom_data_table_item table_item">
                            {item.user ? item.user.name : "-"}
                          </td>
                          <td className="custom_data_table_item table_item">
                            {item.coins ? (
                              <>
                                {item.coins} {coinIcon}
                              </>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="custom_data_table_item table_item">
                            <span
                              className={`question_table_level_text ${
                                item.coin_status === "CREDITED"
                                  ? "credited"
                                  : "debited"
                              }`}
                            >
                              {item.coin_status ? item.coin_status : "-"}
                            </span>
                          </td>
                          <td className="custom_data_table_item table_item">
                            {item.quiz_id ? item.quiz_id : "-"}
                          </td>
                          <td className={`custom_data_table_item table_item`}>
                            {item.by_level === true ? (
                              <span className="question_table_level_text start-time">
                                Yes
                              </span>
                            ) : item.by_level === false ? (
                              <span className="question_table_level_text end-time">
                                No
                              </span>
                            ) : (
                              item.by_level
                            )}
                          </td>
                          <td className="custom_data_table_item table_item">
                            {item.lifeline === true
                              ? "✅"
                              : item.lifeline === false
                              ? "❌"
                              : "-"}
                          </td>
                          <td className="custom_data_table_item table_item">
                            <span
                              className={`question_table_level_text ${
                                item.watching_ad === true
                                  ? "credited"
                                  : "debited"
                              }
                            }`}
                            >
                              {item.watching_ad === true
                                ? "Yes"
                                : item.watching_ad === false
                                ? "No"
                                : "-"}
                            </span>
                          </td>

                          <td className="custom_data_table_item table_item">
                            {item.created_at
                              ? moment
                                  .utc(item.created_at, "YYYYMMDD HH:mm:ss")
                                  .clone()
                                  .tz(defaultTimeZone)
                                  .format("DD/MM/YYYY, hh:mm A")
                              : "-"}
                          </td>
                          {/* <td className="custom_data_table_item table_item">
                            {item.updated_at
                              ? moment
                                  .utc(item.updated_at, "YYYYMMDD HH:mm:ss")
                                  .clone()
                                  .tz(defaultTimeZone)
                                  .format("DD/MM/YYYY, hh:mm A")
                              : "-"}
                          </td> */}
                          <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                            <div className="custom_data_table_view_edit_btn_item_row">
                              <Link
                                className="custom_data_table_view_edit_item_btn "
                                aria-label="view"
                                onClick={() => viewIconHandler(item)}
                                state={{ questionDetails: item }}
                              >
                                {eyeIcon}
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>

            <Pagination
              totalRecords={totalCount}
              onPageChange={onPageChange}
              limits={50}
            />
          </>
        )}
      </div>
      <Modal
        className="modal_main_component modal_background_content"
        dialogClassName="custom-dialog"
        show={openViewModal}
        onHide={closeViewModal}
        centered
      >
        <ViewCoinHistory
          viewModalData={viewModalData}
          closeViewModal={closeViewModal}
        />
      </Modal>
      <div
        onClick={() => setImgZoom(false)}
        className={`oc-table-img-modal-bg ${
          imgZoom && "oc-table-img-modal-open"
        }`}
      ></div>
      <div
        className={`oc-table-img-modal ${imgZoom && "oc-table-img-modal-open"}`}
      >
        <img src={imgSrc} alt="profile" />
      </div>
    </>
  );
};

export default CoinHistory;
