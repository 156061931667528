import React, { useState } from "react";
import { tableHeadItemsForDailyRewards } from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";
import { Link } from "react-router-dom";
import { coinIcon, deleteIcon, editIcon, eyeIcon } from "../../icons";
import { routes } from "../../constants";

const RewardList = ({
  list,
  showViewModalHandler,
  showEditModalHandler,
  showDeleteModalHandler,
}) => {
  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  return (
    <>
      <>
        {list?.length === 0 || !list ? (
          <p className="no_data_found">No Data Found</p>
        ) : (
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                {tableHeadItemsForDailyRewards.map((item, i) => {
                  return (
                    <th
                      className="custom_data_table_heading text-center"
                      key={i}
                    >
                      {item.name}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {list?.map((reward, i) => {
                return (
                  <tr className="custom_data_table_row" key={i}>
                    <td className="custom_data_table_item table_item">
                      <img
                        src={
                          reward.image
                            ? process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              reward.image
                            : process.env.REACT_APP_DEFAULT_IMG
                        }
                        alt="dailyRewardImage"
                        className={`custom_data_table_img cursor_pointer_image_view`}
                        onClick={() => {
                          setImgZoom(true);
                          setImgSrc(
                            reward?.image
                              ? process.env.REACT_APP_FILE_BASE_URL +
                                  "/" +
                                  reward.image
                              : process.env.REACT_APP_DEFAULT_IMG
                          );
                        }}
                      />
                    </td>
                    <td className="custom_data_table_item table_item">
                      {reward.day ? reward.day : "-"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {reward.coins ? (
                        <>
                          {reward.coins} {coinIcon}
                        </>
                      ) : (
                        "-"
                      )}
                    </td>

                    <td className="custom_data_table_item table_item">
                      {reward.created_at
                        ? moment
                            .utc(reward.created_at, "YYYYMMDD HH:mm:ss")
                            .clone()
                            .tz(defaultTimeZone)
                            .format("DD/MM/YYYY, hh:mm A")
                        : "-"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {reward.updated_at
                        ? moment
                            .utc(reward.updated_at, "YYYYMMDD HH:mm:ss")
                            .clone()
                            .tz(defaultTimeZone)
                            .format("DD/MM/YYYY, hh:mm A")
                        : "-"}
                    </td>
                    <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                      <div className="custom_data_table_view_edit_btn_item_row">
                        <button
                          className="custom_data_table_view_edit_item_btn "
                          aria-label="view"
                          onClick={() => showViewModalHandler(reward)}
                        >
                          {eyeIcon}
                        </button>

                        <Link
                          aria-label="edit"
                          className="custom_data_table_view_edit_item_btn"
                          onClick={() => showEditModalHandler(reward)}
                          to={`${routes.reward}/edit/${reward.id}`}
                          state={{ reward: reward }}
                        >
                          {editIcon}
                        </Link>

                        <button
                          aria-label="delete"
                          className="custom_data_table_view_edit_item_btn  delete_btn"
                          onClick={() => showDeleteModalHandler(reward.id)}
                        >
                          {deleteIcon}
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </>

      <div
        onClick={() => setImgZoom(false)}
        className={`oc-table-img-modal-bg ${
          imgZoom && "oc-table-img-modal-open"
        }`}
      ></div>
      <div
        className={`oc-table-img-modal ${imgZoom && "oc-table-img-modal-open"}`}
      >
        <img src={imgSrc} alt="dailyRewardImage" />
      </div>
    </>
  );
};

export default RewardList;
