import React from "react";
import { eyeIcon, hideEyeIcon } from "../../../../icons";
import Button from "../../Button";
import Input from "../../Input";

const LoginPageForm = ({
  values,
  errors,
  setValues,
  handleInputChange,
  signInHandler,
  disabled,
}) => {
  const eyeIconHandler = () => {
    setValues({
      ...values,
      inputType: values.inputType === "password" ? "text" : "password",
    });
  };

  return (
    <>
      <Input
        className="login_input_row null"
        errorClassName="err_text"
        type="email"
        placeholder="Email"
        label="Email"
        id="email"
        name="email"
        value={values.email}
        onChange={handleInputChange}
        error={errors?.email || ""}
      />
      <Input
        className="login_input_row null"
        errorClassName="err_text"
        type={values.inputType}
        placeholder="Password"
        label="Password"
        id="password"
        name="password"
        value={values.password}
        onChange={handleInputChange}
        error={errors?.password || ""}
        eyeIcon={values.inputType === "password" ? eyeIcon : hideEyeIcon}
        eyeIconHandler={eyeIconHandler}
        autoComplete="on"
      />
      <Button
        type="submit"
        buttonClassName="login_btn"
        onClick={signInHandler}
        text="Sign In"
        disabled={disabled}
        other={
          disabled && (
            <span className="spinner-border spinner-border-sm me-2"></span>
          )
        }
      />
      <span className="login_btn_span">{/* Login with Email */}</span>
    </>
  );
};

export default LoginPageForm;
