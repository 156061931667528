import React from "react";
import { closeIcon, coinIcon } from "../../icons";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";

const ViewPaymentHistory = ({ closeViewModal, viewModalData }) => {
  return (
    <div className="modal_container">
      <div className="modal_heading">
        <h1 className="modal_main_heading">Payment History Details</h1>
        <button className="modal_close_btn" onClick={closeViewModal}>
          {closeIcon}
        </button>
      </div>

      <div className="modal_detail_container">
        <div className="modal_detail_container_grid only_detail_modal">
          <div className="modal_detail_content">
            <h2 className="modal_detail_content_heading">Name</h2>
            <p>
              {viewModalData.user && viewModalData.user.name
                ? viewModalData.user.name
                : "-"}
            </p>
          </div>

          <div className="modal_detail_content">
            <h2 className="modal_detail_content_heading">User Id</h2>
            <p>
              {viewModalData.user_id ? (
                <span className="question_table_level_text total_answers">
                  {viewModalData.user_id}
                </span>
              ) : (
                "-"
              )}
            </p>
          </div>

          <div className="modal_detail_content">
            <h2 className="modal_detail_content_heading">Amount</h2>
            <p>
              {viewModalData.currency && viewModalData.amount ? (
                <span className="question_table_level_text pending">
                  {viewModalData.currency + viewModalData.amount}
                </span>
              ) : (
                "-"
              )}
            </p>
          </div>

          <div className="modal_detail_content">
            <h2 className="modal_detail_content_heading">Receipt Number</h2>
            <p>
              {viewModalData.receipt_number
                ? viewModalData.receipt_number
                : "-"}
            </p>
          </div>

          <div className="modal_detail_content">
            <h2 className="modal_detail_content_heading">
              Payment Date & time
            </h2>
            <p>
              {viewModalData.created_at
                ? moment
                    .utc(viewModalData.created_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A")
                : "-"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPaymentHistory;
