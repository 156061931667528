import React, { useEffect, useState } from "react";
import { getQuestions } from "../../store/slice/questionSlice";
import { useDispatch, useSelector } from "react-redux";
import { tableHeadItemsForQuestions } from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import { deleteIcon, eyeIcon } from "../../icons";
import { defaultTimeZone } from "../../helpers/commonFunction";
import moment from "moment";
import CommonHeading from "../../component/Common/CommonHeading";

const Practice = () => {
  const dispatch = useDispatch();

  const questionsList = useSelector((state) => state.question?.getQues);
  const [newQuestionsList, setNewQuestionsList] = useState(questionsList);
  const [questionIds, setQuestionIds] = useState([]);
  const [selectAll, setSelectAll] = useState(true);

  useEffect(() => {
    dispatch(getQuestions({}));
  }, []);

  useEffect(() => {
    if (questionsList) {
      setNewQuestionsList(questionsList);
    }
  }, [questionsList]);

  // const onChangeSelectedQuesHandler = (e, index, item) => {
  //   const list = [...newQuestionsList];
  //   if (typeof index === "string") {
  //     let selectList = [];
  //     const newSelectedQues = list.map((item) => ({
  //       ...item,
  //       isChecked: e.target.checked,
  //     }));
  //     newSelectedQues &&
  //       newSelectedQues.forEach((item) => {
  //         if (item.isChecked === true) {
  //           selectList.push(item.id);
  //         }
  //       });
  //     setNewQuestionsList(newSelectedQues);
  //     setQuestionIds(selectList);
  //     console.log("selectList");
  //   } else {
  //     let selectList = [...questionIds];
  //     const updatedItem = { ...list[index], isChecked: e.target.checked };
  //     list[index] = updatedItem;
  //     setNewQuestionsList(list);
  //     if (e.target.checked) {
  //       selectList.push(list[index]?.id);
  //     } else {
  //       const questionRemove = selectList.findIndex(
  //         (question) => question === item.id
  //       );
  //       if (questionRemove !== -1) {
  //         selectList.splice(questionRemove, 1);
  //       }
  //     }
  //     setQuestionIds(selectList);
  //     console.log("selectList", selectList);
  //   }
  // };

  // console.log("newQuestionsList", newQuestionsList, "questionIds", questionIds);

  const onChangeSelectedQuesHandler = (e, index, item) => {
    const list = [...newQuestionsList];
    if (typeof index === "string") {
      let selectList = [];
      let selectedQues = list.map((item) => {
        return {
          ...item,
          isChecked: e.target.checked,
        };
      });
      selectedQues &&
        selectedQues?.forEach((item, i) => {
          if (item.isChecked === true) {
            selectList.push(item.id);
          }
        });
      setNewQuestionsList(selectedQues);
      setQuestionIds(selectList);
    } else {
      let selectList = [...questionIds];
      const updatedItem = { ...list[index], isChecked: e.target.checked };
      list[index] = updatedItem;
      setNewQuestionsList(list);
      if (e.target.checked) {
        selectList.push(list[index]?.id);
      } else {
        const questionRemove = selectList.findIndex((ques) => ques === item.id);
        if (questionRemove !== -1) {
          selectList.splice(questionRemove, 1);
        }
      }
      setQuestionIds(selectList);
    }
  };

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading="QUESTIONS" />
      <div className="custom_data_table_content">
        <table className="custom_data_table">
          <thead className="custom_data_table_head">
            <tr>
              {tableHeadItemsForQuestions.map((ques, i) => {
                return (
                  <th
                    className="custom_data_table_heading text-center question_table_heading_checkbox_content"
                    key={i}
                  >
                    {i === 0 ? (
                      <div className="create_question_list_checkbox question_checkbox_content">
                        <input
                          type="checkbox"
                          id={`question_with_checkbox`}
                          name={"question_with_checkbox"}
                          value="question_with_checkbox"
                          onChange={(e) =>
                            onChangeSelectedQuesHandler(e, "all", "all")
                          }
                          // checked={selectAll}
                          className="create_question_list_checkbox_input"
                        />
                        <label
                          htmlFor={`question_with_checkbox`}
                          className="create_question_list_checkbox_label"
                        >
                          {/* Select all */}
                        </label>
                      </div>
                    ) : (
                      ques.name
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="custom_data_table_body">
            {newQuestionsList?.map((item, i) => {
              return (
                <tr className="custom_data_table_row" key={item.id}>
                  <td className="custom_data_table_item">
                    <div className="create_question_list_checkbox question_checkbox_content">
                      <input
                        type="checkbox"
                        className="create_question_list_checkbox_input"
                        id={`question_with_checkbox${i}`}
                        name={"question_with_checkbox" + i}
                        value="question_with_checkbox"
                        onChange={(e) =>
                          onChangeSelectedQuesHandler(e, i, item)
                        }
                        checked={item.isChecked}
                      />
                      <label
                        htmlFor={`question_with_checkbox${i}`}
                        className="create_question_list_checkbox_label"
                      ></label>
                    </div>
                  </td>
                  <td className="custom_data_table_item table_item">
                    <span
                      className={`question_table_level_text ${
                        item.level === "EASY"
                          ? "credited"
                          : item.level === "HARD"
                          ? "debited"
                          : item.level === "MEDIUM"
                          ? "pending"
                          : "-"
                      }`}
                    >
                      {item.level ? item.level : "-"}
                    </span>
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.topic?.name ? item.topic.name : "-"}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.question}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.correct_answer_count ? (
                      <span className="question_table_level_text_correct_answer start-time">
                        {item.correct_answer_count}
                      </span>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.wrong_answer_count ? (
                      <span className="question_table_level_text_correct_answer end-time">
                        {item.wrong_answer_count}
                      </span>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.created_at &&
                      moment
                        .utc(item.created_at, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")}
                  </td>
                  <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                    <div className="custom_data_table_view_edit_btn_item_row">
                      <button
                        aria-label="delete"
                        className="custom_data_table_view_edit_item_btn"
                        // onClick={() => viewIconHandler(item)}
                      >
                        {eyeIcon}
                      </button>
                      <button
                        aria-label="delete"
                        className="custom_data_table_view_edit_item_btn delete_btn"
                        // onClick={() => deleteIconHandler([item.id])}
                      >
                        {deleteIcon}
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Practice;
