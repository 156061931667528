import React from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import { backBtnArrow, question } from "../../icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import Input from "../../component/Common/Input";
import Button from "../../component/Common/Button";
import { useDispatch } from "react-redux";
import { updateCorruptQuesForEdit } from "../../store/slice/corruptSlice";

const EditCorruptedQuestion = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const editQues = location?.state?.corrQues;

  //ALL STATES VALUE
  const initialFValues = {
    question: editQues ? editQues?.question : "",
    option1: editQues ? editQues?.options[0] : "",
    option2: editQues ? editQues?.options[1] : "",
    option3: editQues ? editQues?.options[2] : "",
    option4: editQues ? editQues?.options[3] : "",
    answer: editQues ? editQues?.answer : "",
  };

  //ERROR HANDLING VALIDATION
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("question" in fieldValues) {
      temp.question = fieldValues.question ? "" : "Please enter a question";
    }
    if ("option1" in fieldValues) {
      temp.option1 = fieldValues.option1 ? "" : "Please enter an option 1";
    }
    if ("option2" in fieldValues) {
      temp.option2 = fieldValues.option2 ? "" : "Please enter an option 2";
    }
    // if ("option3" in fieldValues) {
    //   temp.option3 = fieldValues.option3 ? "" : "Please enter an option 3";
    // }
    // if ("option4" in fieldValues) {
    //   temp.option4 = fieldValues.option4 ? "" : "Please enter an option 4";
    // }
    if ("answer" in fieldValues) {
      temp.answer = fieldValues.answer ? "" : "Please enter an answer";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const updateCorruptQuesHandler = () => {
    if (validate()) {
      const options = [
        values.option1,
        values.option2,
        values.option3,
        values.option4,
      ];
      // const nonEmptyOptions = options
      //   .map((option) => option.trim())
      //   .filter((option) => option !== "");
      const nonEmptyOptions = options
        .map((option) => {
          if (typeof option === "string") {
            return option.trim();
          } else {
            return option;
          }
        })
        .filter((option) => option !== "");
      const data = {
        question: values.question.trim(),
        options: nonEmptyOptions,
        answer: values.answer.trim(),
      };
      // console.log("data", data);

      dispatch(
        updateCorruptQuesForEdit({
          data,
          id: editQues.id,
          navigate,
          toast: "Corrupted question updated successfully",
        })
      );
    }
  };

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading="EDIT QUESTIONS" />

      <div className="project_edit_main_link">
        <div className="comman_center_btn_container">
          <button className="go_back_btn" onClick={() => navigate(-1)}>
            {backBtnArrow} Back
          </button>
        </div>
      </div>

      <div className="create_from_row_corrupted_question">
        <Input
          className="project_edit_feature_input col_span_2 bottom_space"
          labelClassName="required"
          errorClassName="err_text"
          type="text"
          placeholder="Edit Question"
          label="Edit Question"
          id="question"
          name="question"
          value={values.question}
          onChange={handleInputChange}
          error={errors?.question || ""}
        />

        <Input
          className="project_edit_feature_input bottom_space"
          // labelClassName="required"
          errorClassName="err_text"
          type="text"
          placeholder="Enter option 1..."
          // label="Option 1"
          id="option1"
          name="option1"
          value={values.option1}
          onChange={handleInputChange}
          error={errors?.option1 || ""}
        />
        <Input
          className="project_edit_feature_input bottom_space"
          // labelClassName="required"
          errorClassName="err_text"
          type="text"
          placeholder="Enter option 2..."
          // label="Option 2"
          id="option2"
          name="option2"
          value={values.option2}
          onChange={handleInputChange}
          error={errors?.option2 || ""}
        />
        <Input
          className="project_edit_feature_input"
          // labelClassName="required"
          errorClassName="err_text"
          type="text"
          placeholder="Enter option 3..."
          // label="Option 3"
          id="option3"
          name="option3"
          value={values.option3}
          onChange={handleInputChange}
          error={errors?.option3 || ""}
        />
        <Input
          className="project_edit_feature_input"
          // labelClassName="required"
          errorClassName="err_text"
          type="text"
          placeholder="Enter option 4..."
          // label="Option 4"
          id="option4"
          name="option4"
          value={values.option4}
          //   value={values.option4 ? values.option4 : ""}
          onChange={handleInputChange}
          error={errors?.option4 || ""}
        />

        <Input
          className="project_edit_feature_input col_span_2"
          labelClassName="required"
          errorClassName="err_text"
          type="text"
          placeholder="Enter correct answer..."
          label="Correct Answer"
          id="answer"
          name="answer"
          value={values.answer}
          onChange={handleInputChange}
          error={errors?.answer || ""}
        />
      </div>
      <Button
        className="project_submit_bottom_btn center_back_btn"
        buttonClassName="comman_btn"
        onClick={updateCorruptQuesHandler}
        text="Update"
      />
    </div>
  );
};

export default EditCorruptedQuestion;
