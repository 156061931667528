import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuizQuesOfUserByQuizId } from "../../store/slice/quizSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  backBtnArrow,
  blueMinusIcon,
  bluePlusIcon,
  roundAddIcon,
  roundMinusIcon,
} from "../../icons";
import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import moment from "moment";

const ViewQuiz = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const quizId = location?.state?.quizId;

  const [iconToggle, setIconToggle] = useState(false);
  const questionDetails = useSelector(
    (state) => state?.quiz?.quizQuesOfUserByQuizId
  );
  const isLoading = useSelector((state) => state?.quiz?.loader);
  const { page_number, value } = location?.state;

  useEffect(() => {
    dispatch(getQuizQuesOfUserByQuizId({ id: quizId }));
  }, []);

  const iconToggleHandler = (questionId) => {
    setIconToggle((prevState) => ({
      ...prevState,
      [questionId]: !prevState[questionId],
    }));
  };

  return (
    <div className="project_edit_main_content">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CommonHeading heading="GAMES" />
          <div className="project_edit_main_link">
            <div className="comman_center_btn_container">
              <button
                className="go_back_btn"
                onClick={() =>
                  navigate(`/quiz/page/${page_number}`, {
                    state: {
                      value: value,
                      value_page: page_number,
                    },
                  })
                }
              >
                {backBtnArrow} Back
              </button>
            </div>
          </div>

          {!questionDetails || questionDetails.length === 0 ? (
            <p className="no_data_found">No data Found</p>
          ) : (
            <>
              <div className="create_from_row mb-20">
                {questionDetails?.user && (
                  <div>
                    <div className="project_view_list_item">User</div>
                    <p>
                      {questionDetails.user.name
                        ? questionDetails.user.name
                        : "-"}
                    </p>
                  </div>
                )}
                {questionDetails?.user && (
                  <div>
                    <div className="project_view_list_item">Topic</div>
                    <p>
                      {questionDetails.topic.name
                        ? questionDetails.topic.name
                        : "-"}
                    </p>
                  </div>
                )}

                {questionDetails?.start_time && (
                  <div>
                    <div className="project_view_list_item">Start Time</div>

                    <p className="question_table_level_text start-time">
                      {moment(questionDetails.start_time, ["hh:mm:ss"]).format(
                        "hh:mm A"
                      )}
                    </p>
                  </div>
                )}
                {questionDetails?.end_time && (
                  <div>
                    <div className="project_view_list_item">End Time</div>
                    <p className="question_table_level_text end-time">
                      {moment(questionDetails.end_time, ["hh:mm:ss"]).format(
                        "hh:mm A"
                      )}
                    </p>
                  </div>
                )}
              </div>

              <div className="create_from_row_questions">
                {questionDetails?.quiz_history?.map((ques, i) => {
                  const questionId = ques.question_id;
                  const isIconToggled = iconToggle[questionId] || false;
                  return (
                    <div className="project_view_list_item" key={i}>
                      <div className="project_view_list_item_header">
                        <h2>{ques.question}</h2>
                        <button
                          type="button"
                          className="project_view_list_item_header_btn"
                          onClick={() => iconToggleHandler(questionId)}
                        >
                          {isIconToggled ? (
                            <span>{blueMinusIcon}</span>
                          ) : (
                            <span> {bluePlusIcon}</span>
                          )}
                        </button>
                      </div>
                      {isIconToggled && (
                        <div className="project_view_list_item_body">
                          <div className="project_view_list_item_option_answer_row">
                            {ques.options.map((option, i) => {
                              return (
                                <div
                                  className={`project_view_list_item_option_answer_col ${
                                    option === ques.correct_answer &&
                                    "project_view_list_item_option_correct_answer"
                                  }`}
                                  key={i}
                                >
                                  <p className="project_view_list_item_option_answer_col_text">
                                    {option}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                          <h6 className="project_view_list_item_your_answer">
                            Your Answer:{" "}
                            {ques.answer === ques.correct_answer ? (
                              <span className="correct_answer">
                                {ques.answer}
                              </span>
                            ) : (
                              <span className="in_correct_answer">
                                {ques.answer}
                              </span>
                            )}
                          </h6>
                          {/* <h6>
                        Correct Answer : <span>{ques.correct_answer}</span>
                      </h6> */}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ViewQuiz;
