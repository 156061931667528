// Email validation in login page
export const validateEmail = (temp, fieldValues, values, setErrors) => {
  // eslint-disable-next-line no-useless-escape
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var mailReg = new RegExp(mailformat);

  if ("email" in fieldValues) {
    if (!fieldValues.email) {
      temp.email = "Email is required !";
    } else {
      temp.email = fieldValues.email.match(mailReg)
        ? ""
        : "Please enter a valid email !";
    }
  }

  if ("password" in fieldValues) {
    temp.password = fieldValues.password ? "" : "Password is required !";
  }

  setErrors({
    ...temp,
  });

  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

// Phone validation in login page
// export const phoneValidation = (values, setValues, isValidPhoneNumber) => {
//   let isValid = false;
//   if (!values.phone_number) {
//     setValues({
//       ...values,
//       phone_number_error: "Phone number is required !",
//     });
//   } else if (!isValidPhoneNumber(values.phone_number)) {
//     setValues({
//       ...values,
//       phone_number_error: "Phone number is not valid !",
//     });
//   } else if (!values.password) {
//     setValues({
//       ...values,
//       password_error: "Password is required !",
//     });
//   } else {
//     isValid = true;
//   }
//   return isValid;
// };

// Login page phone number code //
// export function withoutMetadata(_parsedPhone) {
//   if (!_parsedPhone) return _parsedPhone;
//   const { metadata: _metadata, ...parsedPhone } = _parsedPhone;
//   return parsedPhone;
// }
