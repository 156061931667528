import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  getServiceList,
  updateServiceStatus,
} from "../../store/slice/serviceSlice";
import { useLocation } from "react-router-dom";

const UpdateServiceStatus = ({ closeModal, serviceUpdateData }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { value, page_number, date } = location.state;
  // const filterValue = location?.state?.value?.status;
  // const dateValue = location?.state?.date;
  // const pageValue = location?.state?.page_number;

  const [reqStatus, setReqStatus] = useState(serviceUpdateData.status);
  const [remarks, setRemarks] = useState(
    serviceUpdateData.remark ? serviceUpdateData.remark : ""
  );

  const statusList = [
    {
      display_name: "Reopen",
      value: "Reopen",
      id: "Reopen",
      disableValue:
        serviceUpdateData.status === "Pending" ||
        serviceUpdateData.status === "Closed" ||
        serviceUpdateData.status === "Resolved"
          ? true
          : false,
    },
    {
      display_name: "Pending",
      value: "Pending",
      id: "Pending",
      disableValue:
        serviceUpdateData.status === "Resolved" ||
        serviceUpdateData.status === "Closed"
          ? true
          : false,
    },
    {
      display_name: "Resolved",
      value: "Resolved",
      id: "resolved",
      disableValue: serviceUpdateData.status === "Closed" ? true : false,
    },
    {
      display_name: "Closed",
      value: "Closed",
      id: "closed",
      disableValue: false,
    },
  ];

  //STATUS ON-CHANGE
  const statusOnChange = (e) => {
    setReqStatus(e.target.value);
  };

  //REMARK ON_CHANGE
  const remarkOnChange = (e) => {
    setRemarks(e.target.value);
  };

  //UPDATE STATUS HANDLER
  const updateStatusHandler = () => {
    const data = {
      status: reqStatus,
    };

    if (remarks.trim() !== "") {
      data.remark = remarks;
    }

    dispatch(
      updateServiceStatus({
        data,
        id: serviceUpdateData.id,
        toast: "Service status updated successfully",
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            closeModal();
            setReqStatus("");
            setRemarks("");
            dispatch(
              getServiceList({
                status: value.status ? value.status : "",
              })
            );
          }
        },
      })
    );
  };

  return (
    <div className="modal_container">
      <>
        <div className="modal_heading">
          <h1 className="modal_main_heading">Update Request</h1>
        </div>
        <div className="modal_detail_container">
          <div className="modal_detail_container_grid">
            <div className="modal_detail_content span_2 margin_top_container">
              <h2 className="modal_detail_content_heading margin_bottom_container">
                Update to...
              </h2>

              <div className="project_common_form_input_check_box_row">
                {statusList.map((status, i) => {
                  return (
                    <div
                      className={`project_common_form_check_box ${
                        status.disableValue ? "disabled_input" : " "
                      }`}
                      key={i}
                    >
                      <input
                        type="radio"
                        id={status.id}
                        name="status"
                        checked={reqStatus === status.value}
                        value={status.value}
                        onChange={statusOnChange}
                        disabled={status.disableValue}
                      />
                      <label
                        htmlFor={status.id}
                        className={`project_common_form_check_box_label
                        ${status.disableValue ? "disabled_label" : ""}
                        `}
                      >
                        {status.display_name}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="project_edit_feature_input span_2">
              <h4 className="project_text_area_label">Remarks</h4>
              <Form.Control
                className="project_text_area"
                type="text"
                placeholder="Enter Remarks"
                as="textarea"
                rows={3}
                id="remarks"
                name="remarks"
                value={remarks}
                onChange={remarkOnChange}
              />
            </div>
          </div>
        </div>
      </>
      <div className="modal_btn_row">
        <button
          onClick={() => closeModal()}
          className="modal_btn modal_cancel_btn"
        >
          Cancel
        </button>
        <button
          className="modal_btn modal_save_btn"
          onClick={() => updateStatusHandler()}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default UpdateServiceStatus;
