import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notify } from "../../helpers/commonFunction";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

export const loginUser = createAsyncThunk(
  "loginUser",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(`/user/login`, data);
      if (response.status === 201) {
        notify("Welcome Back", "success");
        return response.data;
      }
    } catch (error) {
      console.log("error", error.response);
      notify(error.response.data.message);
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const getUserList = createAsyncThunk("getUserList", async (data) => {
  try {
    const response = await Axios.get(
      `/user?start=${data.start || 0}&limits=${data.limits || 10}&name=${
        data.name || ""
      }&add_coin=${data.add_coin || ""}&dropdown=${
        data.dropDown || ""
      }&premium=${data.premium || ""}`,
      authHeaders()
    );
    return response.data.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
});

export const getUserListForDropDown = createAsyncThunk(
  "getUserListForDropDown",
  async (data) => {
    try {
      const response = await Axios.get(
        `/user?start=${data.start || 0}&limits=${data.limits || 10}&name=${
          data.name || ""
        }&dropdown=${data.dropDown}`,
        authHeaders()
      );
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

export const updateUserList = createAsyncThunk(
  "updateUserList",
  async (data) => {
    try {
      const response = await Axios.put(
        `/user/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 201 && !data.cb) {
        toast.success(data.toast, { autoClose: 3000 });
        data.navigate(`${routes.user}/page/1`);
        return response.data;
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

export const mobileAppStatus = createAsyncThunk("mobileAppStatus", async () => {
  try {
    const response = await Axios.get(`/user/get/app-status`, authHeaders());
    return response.data.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
});

export const updateMobileAppStatus = createAsyncThunk(
  "updateMobileAppStatus",
  async (data) => {
    try {
      const response = await Axios.put(
        `/user/app-status/${data.id || ""}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || 201) {
        toast.success("Mobile Configuration Updated Successfully", {
          autoClose: 2000,
        });
        data.navigate(routes.mobile);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

const initialUser = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: initialUser,

    userList: [],
    dropDownUserList: [],
    appStatus: {},
    updateUser: "",
    loader: false,
    error: "",
    totalCount: 0,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("user");
    },
  },
  extraReducers: (builder) => {
    //LOGIN USER
    builder.addCase(loginUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loader = false;
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload.data));
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //GET USER LIST
    builder.addCase(getUserList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getUserList.fulfilled, (state, action) => {
      state.loader = false;
      state.userList = action.payload.rows;
      state.totalCount = action.payload.count;
    });
    builder.addCase(getUserList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //GET USER LIST FOR QUIZ
    builder.addCase(getUserListForDropDown.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getUserListForDropDown.fulfilled, (state, action) => {
      state.loader = false;
      state.dropDownUserList = action.payload.rows;
    });
    builder.addCase(getUserListForDropDown.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //GET DATA FOR APP UPDATE
    builder.addCase(mobileAppStatus.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(mobileAppStatus.fulfilled, (state, action) => {
      state.loader = false;
      state.appStatus = action.payload;
    });
    builder.addCase(mobileAppStatus.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //UPDATE USER LIST
    builder.addCase(updateUserList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateUserList.fulfilled, (state, action) => {
      state.loader = false;
      state.updateUser = action.payload;
    });
    builder.addCase(updateUserList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;
