import React from "react";
import { useDispatch } from "react-redux";
import { getQuestions, updateQuestions } from "../../store/slice/questionSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants";

const DeleteQuestion = ({
  values,
  closeDeleteModal,
  deleteId,
  setOpenDeleteModal,
  setDeleteId,
  totalCount,
  pageNumber,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //DELETE QUESTION HANDLER
  const deleteQuestionHandler = () => {
    const data = {
      id: deleteId,
      delete_status: true,
    };
    dispatch(
      updateQuestions({
        data,
        cb: (err, res) => {
          if (err) {
            // console.log("err", err);
          } else {
            closeDeleteModal();
            toast.success("Question deleted successfully", { autoClose: 3000 });
            if (
              totalCount &&
              totalCount[totalCount.toString().length - 1] === 1 &&
              +pageNumber !== 1
            ) {
              navigate(`/question/page/${pageNumber - 1}`);
            } else {
              dispatch(
                getQuestions({
                  level: values.level === "" ? "" : values.level,
                  start: (pageNumber - 1) * 50,
                  limits: 50,
                  topic: values.topic === "" ? "" : Number(values.topic),
                })
              );
            }
          }
        },
      })
    );
  };

  return (
    <div className="modal_container">
      <div className="modal_heading_2">
        <h1 className="modal_main_heading_with_desc">Are you sure?</h1>
        <p>
          {deleteId && deleteId.length > 1
            ? "You want to delete all selected questions!"
            : "You want to delete this question!"}
        </p>
      </div>
      <div className="modal_btn_row">
        <button
          onClick={() => closeDeleteModal()}
          className="modal_btn modal_cancel_btn"
        >
          Cancel
        </button>
        <button
          className="modal_btn modal_delete_btn"
          onClick={() => deleteQuestionHandler(deleteId)}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteQuestion;
