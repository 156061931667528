import React, { useState } from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import { addIcon, backBtnArrow, editIcon } from "../../icons";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../../component/Common/Input";
import { imagePreviewProps } from "../../helpers/commonFunction";
import { useForm } from "../../hooks/useForm";
import { dailyRewardDayOptions } from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import Button from "../../component/Common/Button";
import { useDispatch, useSelector } from "react-redux";
import { fileUpload } from "../../store/slice/fileUploadSlice";
import { createReward, updateReward } from "../../store/slice/rewardSlice";
import Loader from "../../component/Common/Loader";
import { Form } from "react-bootstrap";

const CreateReward = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const editReward = location?.state?.reward;
  const isLoading = useSelector((state) => state?.reward?.loader);
  const [uploading, setUploading] = useState(false);

  const rewardOptions = dailyRewardDayOptions?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  let initialImagePreview;
  if (editReward?.image) {
    initialImagePreview = `${process.env.REACT_APP_FILE_BASE_URL}/${editReward?.image}`;
  }

  //ALL STATES VALUES
  const initialFValues = {
    coins: editReward ? editReward?.coins : "",
    day: editReward ? editReward?.day : "",
    image: editReward ? editReward?.image : "",
    image_preview: editReward ? initialImagePreview : "",
  };

  //VALIDATION OF CREATE REWARD FORM
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("image" in fieldValues) {
      temp.image = "";
      if (!fieldValues.image) {
        temp.image = "Icon required for reward!";
      }
      if (fieldValues.image.size > 5000000) {
        temp.image = "Icon should be less than 5Mb!";
        setValues({
          ...values,
          image: "",
          image_preview: "",
        });
      }
      if (
        fieldValues.image.name &&
        !fieldValues.image.name?.match(/.(jpg|jpeg|png|gif)$/i)
      ) {
        temp.image = "Invalid Icon!";
        setValues({
          ...values,
          image: "",
          image_preview: "",
        });
      }
    }

    if ("coins" in fieldValues) {
      temp.coins = fieldValues.coins ? "" : "Please Enter no. of coins!";
    }

    if ("day" in fieldValues) {
      temp.day = fieldValues.day ? "" : "Please select day!";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  //USE FORM HOOK
  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  //IMAGE REMOVE HANDLER
  const imageRemoveHandler = () => {
    setValues({ ...values, image: "", image_preview: "" });
  };

  //CREATE-EDIT MAIN HANDLER
  const createEditMainHandler = async () => {
    if (validate()) {
      setUploading(true);
      const formData = new FormData();
      let imageUrl;
      if (values.image && typeof values.image !== "string") {
        formData.append("file", values.image);
        imageUrl = await dispatch(fileUpload(formData));
        formData.delete("file");
      } else {
        imageUrl = values.image;
      }

      setUploading(false);
      const data = {
        day: values.day,
        coins: values.coins,
        image: typeof values.image !== "string" ? imageUrl.payload : imageUrl,
      };

      editReward
        ? dispatch(
            updateReward({
              data,
              id: editReward.id,
              navigate,
              toast: "Daily reward updated successfully",
            })
          )
        : dispatch(
            createReward({
              data,
              navigate,
              toast: "Daily reward created successfully",
            })
          );
    }
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading
          heading={editReward ? "UPDATE REWARD" : "CREATE REWARD"}
        />
        {isLoading || uploading ? (
          <Loader />
        ) : (
          <>
            <div className="project_edit_main_link">
              <div className="comman_center_btn_container">
                <button className="go_back_btn" onClick={() => navigate(-1)}>
                  {backBtnArrow} Back
                </button>
              </div>
            </div>

            <div className="create_from_row">
              <div>
                <label className="project_text_area_label img_label required">
                  {editReward ? "Update" : "Add"} Daily Reward Image
                </label>
                <div className="project_edit_feature_input_file">
                  <Input
                    className="create_from_input_content"
                    labelClassName="create_from_label"
                    errorClassName="err_text"
                    type="file"
                    id={`image`}
                    labelOtherProps={
                      <span>{!values.image ? addIcon : editIcon}</span>
                    }
                    name="image"
                    accept=".jpg, .jpeg, .png"
                    onChange={handleInputChange}
                    onClick={(e) => (e.target.value = null)}
                    imageProps={imagePreviewProps(
                      values.image,
                      values.image_preview,
                      imageRemoveHandler
                    )}
                  />
                </div>
                {errors.image && (
                  <span className="err_text">{errors.image}</span>
                )}
              </div>

              <div className="project_edit_feature_input mt-0">
                <Input
                  className="project_edit_feature_input "
                  labelClassName="required"
                  errorClassName="err_text"
                  type="text"
                  placeholder="Enter Number of Coins"
                  label="Total Coins"
                  id="coins"
                  name="coins"
                  value={values.coins}
                  onChange={handleInputChange}
                  error={errors.coins || ""}
                />

                <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <h4 className="project_text_area_label required">
                      Topic Id
                    </h4>
                    <Form.Select
                      onChange={(e) => handleInputChange(e, "day")}
                      id="day"
                      name="day"
                      className="select_form"
                      defaultValue={values.day ? values.day : "Default"}
                    >
                      <option disabled value={"Default"}>
                        Select Day
                      </option>
                      {rewardOptions?.map((reward, i) => {
                        return (
                          <option key={i} value={reward.value}>
                            {reward.label}
                          </option>
                        );
                      })}
                    </Form.Select>
                    {errors.day && (
                      <span className="err_text">{errors.day}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <Button
              className="project_submit_bottom_btn center_back_btn"
              buttonClassName="comman_btn save_and_submit_form_btn"
              onClick={createEditMainHandler}
              text={editReward ? "Update" : "Add"}
            />
          </>
        )}
      </div>
    </>
  );
};

export default CreateReward;
