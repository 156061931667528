import React, { useEffect, useRef, useState } from "react";
import { getPaymentHistoryList } from "../../store/slice/PaymentSlice";
import { useDispatch, useSelector } from "react-redux";
import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import { Form, Modal } from "react-bootstrap";
import { tableHeadItemsForPaymentHistory } from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";
import { deleteIcon, eyeIcon } from "../../icons";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ViewPaymentHistory from "./ViewPaymentHistory";
import { getUserListForDropDown } from "../../store/slice/userSlice";
import Pagination from "../../component/Pagination/Pagination";

const PaymentHistory = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const ref = useRef(false);
  const navigate = useNavigate();
  const { page_number } = params;

  const list = useSelector((state) => state?.payment?.paymentList?.rows);
  const listCount = useSelector((state) => state?.payment?.totalCount?.count);
  const isLoading = useSelector((state) => state?.payment?.loader);
  const dropDownUserList = useSelector((state) => state.user?.dropDownUserList);

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  const [openViewModal, setOpenViewModal] = useState(false);
  const [viewModalData, setViewModalData] = useState("");

  const [filterData, setFilterData] = useState("");
  const [pageN, setPageN] = useState(page_number - 1);

  //USE-EFFECT FOR FILTER
  useEffect(() => {
    if (ref.current) {
      if (page_number !== 1) {
        navigate(`/payments/page/1`);
      }
      dispatch(
        getPaymentHistoryList({
          user: filterData.user === "" ? "" : filterData.user,
          start: (page_number - 1) * 50,
          limits: 50,
        })
      );
    }
  }, [filterData]);

  //USE-EFFECT FOR PAGINATION
  useEffect(() => {
    if (ref.current) {
      dispatch(
        getPaymentHistoryList({
          user: filterData.user === "" ? "" : filterData.user,
          start: (page_number - 1) * 50,
          limits: 50,
        })
      );
    }
  }, [pageN]);

  //USE-EFFECT FOR INITIAL RENDERING
  useEffect(() => {
    dispatch(
      getPaymentHistoryList({
        user: filterData.user === "" ? "" : filterData.user,
        start: (page_number - 1) * 50,
        limits: 50,
      })
    );
    dispatch(getUserListForDropDown({ dropDown: true, limits: 200000 }));
    ref.current = true;
  }, []);

  //VIEW ICON HANDLER
  const viewPaymentHistoryModalHandler = (item) => {
    setViewModalData(item);
    setOpenViewModal(true);
  };

  //CLOSE VIEW HANDLER
  const closeViewModal = () => {
    setOpenViewModal(false);
    setViewModalData("");
  };

  //On CHANGE OF FILTER
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });
  };

  //on-page change
  const onPageChange = (page_number) => {
    setPageN(page_number);
  };

  return (
    <>
      <>
        <div className="project_edit_main_content">
          <CommonHeading heading="PAYMENT HISTORY" />
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="creat_edit_project_btn_row_flex">
                <div></div>
                <div className="common_all_filter_row justify-content-end">
                  <div className="common_filter_input_box first_filter_container">
                    <div className="common_select_box">
                      <Form.Select
                        id="user"
                        name="user"
                        className="select_form filter_box"
                        value={filterData.user}
                        onChange={handleInputChange}
                      >
                        <option value="" disabled>
                          Filter by UserName...
                        </option>
                        <option value="">All</option>
                        {dropDownUserList &&
                          dropDownUserList?.length > 0 &&
                          dropDownUserList?.map((user, i) => {
                            return (
                              <option key={i} value={user?.name}>
                                {user?.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="custom_data_table_content">
                {list?.length === 0 || !list ? (
                  <p className="no_data_found">No data Found</p>
                ) : (
                  <table className="custom_data_table">
                    <thead className="custom_data_table_head">
                      <tr>
                        {tableHeadItemsForPaymentHistory.map((payment, i) => {
                          return (
                            <th
                              className="custom_data_table_heading text-center question_table_heading_checkbox_content"
                              key={i}
                            >
                              {payment.name}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className="custom_data_table_body">
                      {list?.map((item, i) => {
                        return (
                          <tr className="custom_data_table_row" key={i}>
                            <td className="custom_data_table_item table_item">
                              <img
                                src={
                                  item.user && item.user.profile_pic !== null
                                    ? process.env.REACT_APP_FILE_BASE_URL +
                                      "/" +
                                      item.user.profile_pic
                                    : item.user.avtar !== null
                                    ? process.env.REACT_APP_FILE_BASE_URL +
                                      "/" +
                                      item.user.avtar.image
                                    : process.env.REACT_APP_DEFAULT_IMG
                                }
                                alt="paymentHistoryImage"
                                className={`custom_data_table_img cursor_pointer_image_view`}
                                onClick={() => {
                                  setImgZoom(true);
                                  setImgSrc(
                                    item.user && item.user.profile_pic !== null
                                      ? process.env.REACT_APP_FILE_BASE_URL +
                                          "/" +
                                          item.user.profile_pic
                                      : item.user.avtar !== null
                                      ? process.env.REACT_APP_FILE_BASE_URL +
                                        "/" +
                                        item.user.avtar.image
                                      : process.env.REACT_APP_DEFAULT_IMG
                                  );
                                }}
                              />
                            </td>
                            <td className="custom_data_table_item table_item">
                              {item && item.user && item.user.name
                                ? item.user.name
                                : "-"}
                            </td>
                            <td className="custom_data_table_item table_item">
                              {item.user_id ? (
                                <span className="question_table_level_text total_answers">
                                  {item.user_id}
                                </span>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td className="custom_data_table_item table_item">
                              {item.receipt_number ? item.receipt_number : "-"}
                            </td>
                            <td className="custom_data_table_item table_item">
                              {item.currency && item.amount ? (
                                <span className="question_table_level_text pending">
                                  {item.currency + " " + item.amount}
                                </span>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td className="custom_data_table_item table_item">
                              {item.created_at
                                ? moment
                                    .utc(item.created_at, "YYYYMMDD HH:mm:ss")
                                    .clone()
                                    .tz(defaultTimeZone)
                                    .format("DD/MM/YYYY, hh:mm A")
                                : "-"}
                            </td>
                            <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                              <div className="custom_data_table_view_edit_btn_item_row">
                                <Link
                                  className="custom_data_table_view_edit_item_btn "
                                  aria-label="view"
                                  onClick={() =>
                                    viewPaymentHistoryModalHandler(item)
                                  }
                                >
                                  {eyeIcon}
                                </Link>
                                {/* <button
                                  aria-label="delete"
                                  className="custom_data_table_view_edit_item_btn delete_btn"
                                  // onClick={() => deleteIconHandler([item.id])}
                                >
                                  {deleteIcon}
                                </button> */}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </>
          )}
        </div>
        <Pagination
          limits={50}
          totalRecords={listCount}
          onPageChange={onPageChange}
        />
      </>
      <Modal
        className="modal_main_component modal_background_content"
        dialogClassName="custom-dialog"
        show={openViewModal}
        onHide={closeViewModal}
        centered
      >
        <ViewPaymentHistory
          closeViewModal={closeViewModal}
          viewModalData={viewModalData}
        />
      </Modal>
      <div
        className={`oc-table-img-modal-bg ${
          imgZoom && "oc-table-img-modal-open"
        }`}
        onClick={() => setImgZoom(false)}
      ></div>
      <div
        className={`oc-table-img-modal ${imgZoom && "oc-table-img-modal-open"}`}
      >
        <img src={imgSrc} alt="paymentHistoryImage" />
      </div>
    </>
  );
};

export default PaymentHistory;
